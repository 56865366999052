export const GET_ALL_COMPANIES_SAGA = "GET_ALL_COMPANIES_SAGA";
export const GET_ALL_MARKET_CAP = "GET_ALL_MARKET_CAP";
export const SET_ALL_COMPANIES = "SET_ALL_COMPANIES";
export const SET_ALL_MARKET_CAP = "SET_ALL_MARKET_CAP";
export const GET_EXECUTIVES_SAGA = "GET_EXECUTIVES_SAGA";
export const SET_EXECUTIVES = "SET_EXECUTIVES";

export const SET_COMPANY_INFO = "SET_COMPANY_INFO";

export const GET_MARKET_CAP_SAGA = "GET_MARKET_CAP_SAGA";
export const SET_MARKET_CAP = "SET_MARKET_CAP";

export const GET_PARAGRAPHS_8K502_SAGA = "GET_PARAGRAPHS_8K502_SAGA";
export const SET_PARAGRAPHS_8K502 = "SET_PARAGRAPHS_8K502";

export const GET_MONTHLY_RETURNS_SAGA = "GET_MONTHLY_RETURNS_SAGA";
export const SET_MONTHLY_RETURNS = "SET_MONTHLY_RETURNS";

export const GET_WEEKLY_RETURNS_SAGA = "GET_WEEKLY_RETURNS_SAGA";
export const SET_WEEKLY_RETURNS = "SET_WEEKLY_RETURNS";

export const GET_DAILY_PRICES_SAGA = "GET_DAILY_PRICES_SAGA";
export const SET_DAILY_PRICES = "SET_DAILY_PRICES";

export const GET_TREASURY_YIELDS_SAGA = "GET_TREASURY_YIELDS_SAGA";
export const SET_TREASURY_YIELDS = "SET_TREASURY_YIELDS";

export const GET_AWARDS_SAGA = "GET_AWARDS_SAGA";
export const SET_AWARDS = "SET_AWARDS";

export const GET_RISK_FACTORS_SAGA = "GET_RISK_FACTORS_SAGA";
export const SET_RISK_FACTORS = "SET_RISK_FACTORS";

export const GET_PLAN_BASED_AWARDS_SAGA = "GET_PLAN_BASED_AWARDS_SAGA";
export const SET_PLAN_BASED_AWARDS = "SET_PLAN_BASED_AWARDS";

export const GET_COMPENSATION_DECISIONS_SAGA =
  "GET_COMPENSATION_DECISIONS_SAGA";
export const SET_COMPENSATION_DECISIONS = "SET_COMPENSATION_DECISIONS";

export const GET_CHANGE_IN_CONTROL_SAGA = "GET_CHANGE_IN_CONTROL_SAGA";
export const SET_CHANGE_IN_CONTROL = "SET_CHANGE_IN_CONTROL";

export const GET_INDEX_MONTHLY_RETURNS_SAGA = "GET_INDEX_MONTHLY_RETURNS_SAGA";
export const SET_INDEX_MONTHLY_RETURNS = "SET_INDEX_MONTHLY_RETURNS";

export const GET_MONTHLY_MARKETCAPS_SAGA = "GET_MONTHLY_MARKETCAPS_SAGA";
export const SET_MONTHLY_MARKETCAPS = "SET_MONTHLY_MARKETCAPS";

export const GET_BOARD_PAY_SAGA = "GET_BOARD_PAY_SAGA";
export const SET_BOARD_PAY = "SET_BOARD_PAY";

export const GET_ALL_BOARD_PAY_SAGA = "GET_ALL_BOARD_PAY_SAGA";
export const SET_ALL_BOARD_PAY = "SET_ALL_BOARD_PAY";

export const GET_BOARD_PAY_PROFILES_SAGA = "GET_BOARD_PAY_PROFILES_SAGA";
export const SET_BOARD_PAY_PROFILES = "SET_BOARD_PAY_PROFILES";

export const GET_EXECUTIVE_PROFILES_SAGA = "GET_EXECUTIVE_PROFILES_SAGA";
export const SET_EXECUTIVE_PROFILES = "SET_EXECUTIVE_PROFILES";

export const GET_BOARD_PAY_STRUCTURE_SAGA = "GET_BOARD_PAY_STRUCTURE_SAGA";
export const SET_BOARD_PAY_STRUCTURE = "SET_BOARD_PAY_STRUCTURE";

export const GET_EXECUTIVE_PERKS_SAGA = "GET_EXECUTIVE_PERKS_SAGA";
export const SET_EXECUTIVE_PERKS = "SET_EXECUTIVE_PERKS";

export const GET_OPTIONS_ASSUMPTIONS_SAGA = "GET_OPTIONS_ASSUMPTIONS_SAGA";
export const SET_OPTIONS_ASSUMPTIONS = "SET_OPTIONS_ASSUMPTIONS";

export const GET_EQUITY_POOL_USAGE_SAGA = "GET_EQUITY_POOL_USAGE_SAGA";
export const SET_EQUITY_POOL_USAGE = "SET_EQUITY_POOL_USAGE";

export const GET_MEDIAN_BY_YEAR_SAGA = "GET_MEDIAN_BY_YEAR_SAGA";
export const SET_MEDIAN_BY_YEAR = "SET_MEDIAN_BY_YEAR";

export const DISCLOSURE_SEARCH = "DISCLOSURE_SEARCH";
export const SET_DISCLOSURE_RESULTS = "SET_DISCLOSURE_RESULTS";
export const CLEAR_DISCLOSURE_RESULTS = "CLEAR_DISCLOSURE_RESULTS";

export const COMP_AI_SEARCH = "COMP_AI_SEARCH";
export const SET_COMP_AI_RESULTS = "SET_COMP_AI_RESULTS";
export const CLEAR_COMP_AI_RESULTS = "CLEAR_COMP_AI_RESULTS";
export const COMP_AI_SEARCH_AVAILABILITY = "COMP_AI_SEARCH_AVAILABILITY";
export const SET_COMP_AI_AVAILABILITY_RESULTS =
  "SET_COMP_AI_AVAILABILITY_RESULTS";
export const COMP_AI_RESULT_RATING = "COMP_AI_RESULT_RATING";

export const RUN_HORSE_RACE = "RUN_HORSE_RACE";
export const SET_HORSE_RACE_RESULTS = "SET_HORSE_RACE_RESULTS";
export const RUN_OPTIMISTIC_HORSE_RACE = "RUN_OPTIMISTIC_HORSE_RACE";
export const SET_OPTIMISTIC_HORSE_RACE_RESULTS =
  "SET_OPTIMISTIC_HORSE_RACE_RESULTS";
export const CLEAR_HORSE_RACE_RESULTS = "CLEAR_HORSE_RACE_RESULTS";

export const SET_ALL_CSUITE = "GET_ALL_CSUITE";
