import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Table, Button, Tooltip } from "antd";
import { getChangeInControls } from "../redux/actions/CompanyAction";
import { emdash, toDollar } from "../utils/Constants/systemSetting";
import { exportToCSV } from "../utils/ExecutiveData/csvExport";

export default function ChangeInControls({ companyInfo, PDFprocessing }) {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.UserReducer);
  const { subscription } = useSelector((state) => state.CustomerReducer);
  const isTier2 = useMemo(() => {
    if (user?.tier2access || subscription?.plan?.metadata?.isTier2 === "true") {
      return true;
    }
    return false;
  }, [user, subscription]);

  function addSpaceBetweenCapitalizedWords(str) {
    return str.replace(/([a-z])([A-Z])/g, "$1 $2");
  }
  function toTitleCase(str) {
    return str.replace(
      /\w\S*/g,
      (text) => text.charAt(0).toUpperCase() + text.substring(1).toLowerCase()
    );
  }

  const { changeInControls } = useSelector((state) => state.CompanyReducer);

  useEffect(() => {
    const token = localStorage.getItem("ACCESS_TOKEN");
    const ticker = companyInfo?.Ticker;
    if (ticker) {
      dispatch(getChangeInControls([ticker], token));
    }
  }, [companyInfo, dispatch]);

  const NEOspecificCICcolumns = useMemo(() => {
    const executiveSpecificBenefits =
      changeInControls?.[companyInfo?.Ticker]?.ChangeInControlDetails
        ?.NEO_Specific_Benefits;

    if (!executiveSpecificBenefits) return [];

    const defaultColumns = [
      {
        title: "Executive Name",
        dataIndex: "name",
        key: "name",
        align: "center",
      },
      {
        title: "Position",
        dataIndex: "position",
        key: "position",
        align: "center",
      },
    ];

    const otherExecutivePerks = [];

    executiveSpecificBenefits.forEach((exec) => {
      if (!exec?.payments) return;
      Object.entries(exec.payments).forEach(([perkName, value]) => {
        if (!perkName) return;
        if (!otherExecutivePerks.includes(perkName) && perkName !== "Total") {
          otherExecutivePerks.push(perkName);
        }
      });
    });
    function addSpaceBetweenCapitalizedWords(str) {
      return str.replace(/([a-z])([A-Z])/g, "$1 $2");
    }

    const otherExecutivePerkColumns = otherExecutivePerks.map(
      (perkName, index) => ({
        title: addSpaceBetweenCapitalizedWords(perkName),
        dataIndex: perkName,
        key: perkName,
        align: "center",
        onHeaderCell: (item) => {
          return item + "*";
        },
        render: (item) => (item ? toDollar(item) : emdash),
      })
    );

    const allExecutivePerkColumns = [
      ...defaultColumns,
      ...otherExecutivePerkColumns,
      {
        title: "Total",
        dataIndex: "Total",
        key: "Total",
        align: "center",
        render: (item) => (item ? toDollar(item) : emdash),
      },
    ];

    return allExecutivePerkColumns;
  }, [companyInfo, changeInControls]);

  const NEOspecificCICdata = useMemo(() => {
    const executiveSpecificBenefits =
      changeInControls?.[companyInfo?.Ticker]?.ChangeInControlDetails
        ?.NEO_Specific_Benefits;

    if (!executiveSpecificBenefits) return [];
    return executiveSpecificBenefits.map((exec) => ({
      ...exec,
      ...exec.payments,
    }));
  }, [changeInControls, companyInfo]);

  const companyTriggers =
    changeInControls?.[companyInfo?.Ticker]?.ChangeInControlDetails?.Triggers;
  const companyBenefits =
    changeInControls?.[companyInfo?.Ticker]?.ChangeInControlDetails?.Benefits;
  const companyGovernance =
    changeInControls?.[companyInfo?.Ticker]?.ChangeInControlDetails?.Governance;

  const transformedData = useMemo(() => {
    if (!companyGovernance) return [];
    return Object.keys(companyGovernance)
      .filter((key) => key !== "restrictiveCovenants")
      .map((key, index) => ({
        key: index,
        category: key,
        value:
          companyGovernance[key] !== "None" &&
          companyGovernance[key] !== "N/A" ? (
            <Tooltip title={companyGovernance[key]}>
              <span style={{ fontSize: "15px" }}>✓</span>
            </Tooltip>
          ) : (
            ""
          ),
      }));
  }, [companyGovernance]);

  const columns = [
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
      render: (text) =>
        toTitleCase(
          addSpaceBetweenCapitalizedWords(
            text.replace(/([A-Z])/g, " $1").trim()
          )
        ),
    },
    {
      title: "Exists?",
      dataIndex: "value",
      key: "value",
    },
  ];

  const GovernanceTable = () => {
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Table
          style={{ width: "500px" }}
          columns={columns}
          dataSource={transformedData}
          pagination={false}
        />
      </div>
    );
  };

  return (
    <>
      {!isTier2 ? (
        <div
          className="comp-ai-section col-lg-9 executives "
          style={{ height: "100%", padding: 50 }}
        >
          <h3
            style={{
              textAlign: "center",
              fontWeight: "normal",
              fontSize: 22,
            }}
          >
            <p style={{ fontWeight: "bold" }}>
              This page is for C-Suite Comp&reg; Enterprise users only.
            </p>
            <br />
            <p>
              Contact your account manager to access Severance / Change in
              Control Data
            </p>
          </h3>
        </div>
      ) : (
        <>
          <div className="company-calculator-section col-lg-9 executives p-0">
            {!changeInControls?.[companyInfo?.Ticker]?.ChangeInControlDetails
              ?.AdditionalDetails ? (
              <h2 style={{ textAlign: "center", marginTop: 50 }}>
                Severance / Change in Control Data for {companyInfo?.Ticker}{" "}
                Coming Soon
              </h2>
            ) : (
              <div>
                <h2>
                  Executive-Specific Severance / Change in Control Benefits
                </h2>
                {/* {!PDFprocessing && (
                  <div style={{ width: "100%" }}>
                    <Button
                      style={{ marginBottom: 10 }}
                      onClick={() =>
                        exportToCSV(
                          formatDataForCSVexport(),
                          `${companyInfo?.Ticker} ${
                            changeInControls?.[companyInfo?.Ticker]
                              ?.ChangeInControlDetails || ""
                          } Severance / Change in Control ${
                            new Date().toString().split("GMT-")[0]
                          }.csv`
                        )
                      }
                    >
                      Export Severance / Change in Control as CSV
                    </Button>
                  </div>
                )} */}
                <Table
                  columns={NEOspecificCICcolumns}
                  dataSource={NEOspecificCICdata}
                  pagination={{ hideOnSinglePage: true, pageSize: 25 }}
                />
                {changeInControls?.[companyInfo?.Ticker]?.ChangeInControlDetails
                  ?.NEO_Specific_Benefits?.[0]?.source && (
                  <p style={{ marginTop: 10 }}>
                    <b>Citation: </b>

                    {
                      changeInControls?.[companyInfo?.Ticker]
                        ?.ChangeInControlDetails?.NEO_Specific_Benefits?.[0]
                        ?.source
                    }
                  </p>
                )}
                {changeInControls?.[companyInfo?.Ticker]?.ChangeInControlDetails
                  ?.AdditionalDetails && (
                  <p style={{ marginTop: 10 }}>
                    <b>Note: </b>

                    {
                      changeInControls?.[companyInfo?.Ticker]
                        ?.ChangeInControlDetails?.AdditionalDetails
                    }
                  </p>
                )}
              </div>
            )}
          </div>
          <div className="company-calculator-section col-lg-12 executives mt-5 p-0">
            <div>
              <h2>Company-Wide Severance / Change in Control Details</h2>
              {/* <p style={{ textAlign: "center" }}>
                Company-Wide Severance / Change in Control Details coming soon
              </p> */}
              <div style={{ margin: 20 }} />
              <h3>Change in Control Triggers</h3>
              <div style={{ marginLeft: 20 }}>
                <ul>
                  {companyTriggers?.CIC_Triggers ? (
                    companyTriggers?.CIC_Triggers.map((trigger) => (
                      <li>{trigger}</li>
                    ))
                  ) : (
                    <p>N/A</p>
                  )}
                </ul>
                {companyTriggers?.DoubleTrigger ? (
                  <div>
                    <b>Double Trigger:</b>{" "}
                    {companyTriggers?.DoubleTrigger ? "Yes" : "No"}
                  </div>
                ) : null}
              </div>
              <div style={{ margin: 20 }} />

              <h3>Change in Control Benefits</h3>
              <div style={{ marginLeft: 20 }}>
                <ul>
                  {companyBenefits ? (
                    Object.entries(companyBenefits).map(
                      ([benefitKey, benefitValue]) => (
                        <div>
                          <b>
                            {toTitleCase(
                              addSpaceBetweenCapitalizedWords(benefitKey)
                            )}
                            :{" "}
                          </b>
                          {benefitValue || "N/A"}
                        </div>
                      )
                    )
                  ) : (
                    <p>N/A</p>
                  )}
                </ul>
              </div>
              <div style={{ margin: 20 }} />

              <h3>Change in Control Governance</h3>
              <div style={{ marginLeft: 20 }}>
                <GovernanceTable />
                <div style={{ marginLeft: 50 }}>
                  <b>Restrictive Covenants:</b>
                  {companyGovernance?.restrictiveCovenants &&
                  companyGovernance.restrictiveCovenants.length ? (
                    <div>
                      <ul>
                        {companyGovernance?.restrictiveCovenants.map(
                          (trigger) => (
                            <li>{trigger}</li>
                          )
                        )}
                      </ul>
                    </div>
                  ) : (
                    " None"
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}
