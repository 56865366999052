import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useRef,
} from "react";
import { Table, Input, Select, Switch, Tooltip, Button } from "antd";
import {
  emdash,
  getDatapoint,
  RadioSelector,
  MONTHS,
  TSRperiodMessage,
  getMeasure,
  getMostRecentMeasure,
  formatMeasure,
  indexComparisonMessage,
  hasHoverText,
  MIN_YEAR,
} from "../../utils/Constants/systemSetting";
import MeasureSelector from "../../utils/ExecutiveData/MeasureSelector";
import {
  getExecPosition,
  getExecPositionGroup,
} from "../../utils/ExecutiveData/getExecPosition.mjs";
import { measureOptions } from "../../utils/ExecutiveData/executiveMeasure";
import PEOsAndNEOsTable from "./PvPTableComponents/PEOsAndNEOsTable";
import EditableTableCell from "./PvPTableComponents/EditableTableCell";
import mostCommonCoSelectedMeasures from "../../assets/mostCommonCoSelectedMeasures.json";
import removeSVG from "../../assets/close.svg";
import {
  DISCLOSURE_REGEX,
  levenshteinDistance,
} from "../../utils/Constants/systemSetting";
import { useSelector } from "react-redux";
import {
  ResponsiveContainer,
  Tooltip as RechartsToolTip,
  PieChart,
  Pie,
  Cell,
  Sector,
} from "recharts";
import { exportToCSV } from "../../utils/ExecutiveData/csvExport";
import PvPScatterplotAndTable from "./PvPTableComponents/PvPScatterplotAndTable";
import MetricSubGroups from "../../assets/json/MetricSubgroups.json";

const { Search } = Input;

const getCompensationAverage = (execs, year) => {
  if (!execs) return emdash;
  const execsWithCompInYear = execs.filter(
    (exec) => exec?.compensation?.[year]?.total
  );
  const compensationCount = execsWithCompInYear.length;
  if (compensationCount < 1) return null;
  if (compensationCount === 1)
    return execsWithCompInYear[0]?.compensation?.[year]?.total || null;
  const compensationAverage =
    execsWithCompInYear.reduce(
      (total, exec) => total + exec?.compensation?.[year]?.total,
      0
    ) / compensationCount;
  return compensationAverage;
};

export default function PvPTable({
  companyInfo,
  PvPTableYearSelected,
  setPvPTableYearSelected,
  companyExecutives,
  PvPTableTSRdata,
  calculating,
  weightingsAvalable,
  PvPTableMonthSelected,
  setPvPTableMonthSelected,
  PvPPeriodicitySelected,
  setPvPPeriodicitySelected,
  indexSelectorOptions,
  PvPIndexesSelected,
  setPvPIndexesSelected,
  CalculatorCohortSelectorComponent,
  customCohortCompanies,
  includeCompanyInPeerGroup,
  setIncludeCompanyInPeerGroup,
  PvPRangeSelected,
  setPvPRangeSelected,
  PDFprocessing = false,
}) {
  const { companies } = useSelector((state) => state.CompaniesReducer);

  const [weightingSelected, setWeightingSelected] = useState(
    weightingsAvalable[0]
  );
  const [companyNEOs, setCompanyNEOs] = useState([]);
  const [PEOkeys, setPEOkeys] = useState([]);
  const [showPEOsAndNEOsTable, setShowPEOsAndNEOsTable] = useState(false);
  const [proFormaTable, setProFormaTable] = useState(false);
  const [customMeasureName, setCustomMeasureName] = useState("");
  const [customMeasuresSelected, setCustomMeasuresSelected] = useState([]);
  const [updateCount, setUpdateCount] = useState(0);
  const [editableCellData, setEditableCellData] = useState([]);
  const [currentPvPTable, setCurrentPvPTable] = useState([]);
  const [manualData, setManualData] = useState({});
  const [peerGroupMeasureType, setPeerGroupMeasureType] = useState(
    "All Company Measures"
  ); //"All Company Measures" or  "PvP Table Measures"
  const [peerGroupMeasureGroup, setPeerGroupMeasureGroup] = useState(
    "Revised Measure Names"
  ); //"All Company Measures" or  "PvP Table Measures"
  const [measure, setMeasure] = useState(null);
  const [pagination, setPagination] = useState(""); // for resetting responsive pie chart container
  const [
    customCorrelationMeasureSelected,
    setCustomCorrelationMeasureSelected,
  ] = useState(null);

  useEffect(() => {
    if (!measure?.isCustomCorrelationMeasure) {
      setCustomCorrelationMeasureSelected(null);
    }
  }, [measure]);

  //Generate list of peer group measures (PvP measures or measures listed in Def14)
  const peerGroupMeasures = useMemo(() => {
    if (!measure?.isCustomPeerGroupMeasures) return [];
    if (!customCohortCompanies.includes(companyInfo.Ticker)) {
      customCohortCompanies.push(companyInfo.Ticker);
    }
    const peerGroupData = [];
    customCohortCompanies.forEach((ticker) => {
      if (companies?.[ticker]) {
        peerGroupData.push(companies[ticker]);
      }
    });
    //     "Original Measure Names",
    // "Revised Measure Names",
    // "Measure Subgroups",
    // "Measure Categories",

    const measureNamesAreSame = (name1, name2) => {
      name1 = name1.toLowerCase();
      name2 = name2.toLowerCase();
      if (
        levenshteinDistance(name1, name2) === 0 ||
        (name1.length > 5 &&
          name2.length > 5 &&
          levenshteinDistance(name1, name2) <= 1) ||
        (name1.length > 9 &&
          name2.length > 9 &&
          levenshteinDistance(name1, name2) <= 2)
      ) {
        // console.log(name1, "|", name2, "| marked as same");
        return true;
      }
      return false;
    };
    const formatMeasureName = (input) => {
      const reviseInput = (input) => {
        input = input.replace(/\s+|\n+|\t+/g, " ");
        input = input.replace(/[^a-zA-Z0-9 ]/g, "");
        return input.trim();
      };
      const revisedInput = reviseInput(input);
      if (peerGroupMeasureGroup === "Original Measure Names") {
        return revisedInput;
      }
      let metricGroup = MetricSubGroups.find(
        (item) =>
          reviseInput(item.Metric.toLowerCase()) === revisedInput.toLowerCase()
      );
      if (!metricGroup) {
        metricGroup = MetricSubGroups.find((item) =>
          measureNamesAreSame(
            reviseInput(item.Metric.toLowerCase()),
            revisedInput.toLowerCase()
          )
        );
      }
      if (!metricGroup) {
        return "Other";
      }
      if (peerGroupMeasureGroup === "Revised Measure Names") {
        input =
          metricGroup?.["New Metric Name"] ||
          metricGroup?.["Metric"] ||
          "Other";
        return input;
      }
      if (peerGroupMeasureGroup === "Measure Subgroups") {
        input = metricGroup?.["Subgroup"] || "Other";
        return input;
      }
      if (peerGroupMeasureGroup === "Measure Categories") {
        input = metricGroup?.["Broad Group"] || "Other";
        return input;
      }
    };

    const peerGroupMeasures = [];
    const measureNamesAppearInCompanies = {};
    peerGroupData.forEach((companyData) => {
      if (peerGroupMeasureType === "All Company Measures") {
        const companyMeasures = companyData?.Measures?.MeasureName;
        if (companyMeasures && Object.values(companyMeasures).length) {
          const companyMeasuresOverYears = Object.entries(companyMeasures);
          for (const [
            companyMeasureYear,
            measureArray,
          ] of companyMeasuresOverYears) {
            if (
              parseInt(companyMeasureYear) === parseInt(PvPTableYearSelected)
            ) {
              // peerGroupMeasures = peerGroupMeasures.concat(measureArray);
              measureArray.forEach((rawMeasure) => {
                const measure = formatMeasureName(rawMeasure);
                if (
                  measureNamesAppearInCompanies?.[measure]?.length &&
                  !measureNamesAppearInCompanies?.[measure].includes(
                    companyData.Ticker
                  )
                ) {
                  measureNamesAppearInCompanies[measure].push(
                    peerGroupMeasureGroup === "Original Measure Names"
                      ? companyData.Ticker
                      : `${companyData.Ticker}: ${rawMeasure}`
                  );
                } else {
                  peerGroupMeasures.push(measure);
                  measureNamesAppearInCompanies[measure] = [
                    peerGroupMeasureGroup === "Original Measure Names"
                      ? companyData.Ticker
                      : `${companyData.Ticker}: ${rawMeasure}`,
                  ];
                }
              });
            }
          }
        }
      } else if (peerGroupMeasureType === "PvP Table Measures") {
        const companyMeasures = companyData?.Measures?.CoSelectedMeasureName;
        if (companyMeasures && Object.values(companyMeasures).length) {
          const companyMeasuresOverYears = Object.entries(companyMeasures);
          for (const [
            companyMeasureYear,
            rawMeasure,
          ] of companyMeasuresOverYears) {
            if (
              parseInt(companyMeasureYear) === parseInt(PvPTableYearSelected)
            ) {
              // const companyMeasureNames = Object.values(companyMeasures);
              const measure = formatMeasureName(rawMeasure);
              if (
                measureNamesAppearInCompanies?.[measure]?.length &&
                !measureNamesAppearInCompanies?.[measure].includes(
                  companyData.Ticker
                )
              ) {
                measureNamesAppearInCompanies[measure].push(
                  peerGroupMeasureGroup === "Original Measure Names"
                    ? companyData.Ticker
                    : `${companyData.Ticker}: ${rawMeasure}`
                );
              } else {
                peerGroupMeasures.push(measure);
                measureNamesAppearInCompanies[measure] = [
                  peerGroupMeasureGroup === "Original Measure Names"
                    ? companyData.Ticker
                    : `${companyData.Ticker}: ${rawMeasure}`,
                ];
              }
            }
          }
        }
      }
    });

    const formattedPeerGroupMeasures = [];
    for (const peerGroupMeasure of peerGroupMeasures) {
      formattedPeerGroupMeasures.push({
        label: `${peerGroupMeasure}`,
        value: peerGroupMeasure,
        key: peerGroupMeasure + formattedPeerGroupMeasures.length,
        count: measureNamesAppearInCompanies?.[peerGroupMeasure].length,
        tickers: measureNamesAppearInCompanies?.[peerGroupMeasure],
        units: "",
      });
    }
    formattedPeerGroupMeasures.sort((a, b) =>
      a.label === "Other" ? 1 : b.label === "Other" ? -1 : b.count - a.count
    );
    formattedPeerGroupMeasures.map(
      (item) => (item.label += ` (${item.count})`)
    );
    return formattedPeerGroupMeasures;
  }, [
    companies,
    companyInfo,
    PvPTableYearSelected,
    customCohortCompanies,
    peerGroupMeasureType,
    peerGroupMeasureGroup,
    measure,
  ]);

  const peerGroupMeasuresColumns = [
    {
      title: "Measure",
      dataIndex: "value",
      key: "value",
      align: "center",
    },
    {
      title: "Prevalence Count",
      dataIndex: "count",
      key: "count",
      align: "center",
      render: (text, record) => {
        return (
          <Tooltip
            title={
              <div
                style={{
                  textAlign: "center",
                }}
              >
                <b>Appears in:</b>
                <span>
                  {record.tickers.map((tick) => (
                    <p>{tick}</p>
                  ))}
                </span>
              </div>
            }
          >
            <div>{text}</div>
          </Tooltip>
        );
      },
    },
  ];

  const getExecName = useCallback(
    (execKey) => {
      const exec = companyExecutives.find((exec) => exec.key === execKey);
      if (exec?.name) return exec.name;
      if (execKey && execKey.split("_").length > 1)
        return execKey.split("_")[0];
      return null;
    },
    [companyExecutives]
  );

  const mostCommonMeasures = mostCommonCoSelectedMeasures.map(
    (item, index) => ({
      label: `${item.measureName} (${item.count})`,
      value: item.measureName,
      key: item.measureName + index,
      count: item.count,
      units: "",
    })
  );

  // console.log(PvPTableTSRdata);

  const yearsAvailable = useMemo(() => {
    const newYearsAvailable = [];
    const currentYear =
      new Date().getFullYear() - (new Date().getMonth() < 1 ? 1 : 0);
    for (let year = currentYear; year >= MIN_YEAR; year--) {
      newYearsAvailable.push(year);
    }
    return newYearsAvailable;
  }, []);

  const yearsToDisplay = useMemo(() => {
    const allYears = [];
    const currentYear = PvPTableYearSelected;
    // Could also use: const currentYear = new Date().getFullYear();
    for (
      let year = currentYear;
      year > currentYear - PvPRangeSelected.split("yr")[0];
      year--
    ) {
      allYears.push(year);
    }
    return allYears;
  }, [PvPTableYearSelected, PvPRangeSelected]);

  useEffect(() => {
    const newPEOkeys = [];
    for (const year of [...yearsToDisplay].reverse()) {
      for (const PEO of companyNEOs[year]?.PEOexecs) {
        if (!newPEOkeys.includes(PEO?.key)) {
          newPEOkeys.push(PEO?.key);
        }
      }
    }
    setPEOkeys(newPEOkeys);
  }, [companyNEOs, yearsToDisplay]);

  const getPEOAndNonPEOComp = useCallback(
    (year = PvPTableYearSelected) => {
      const PEOexecs = [];
      const nonPEOexecs = [];
      companyExecutives.forEach((exec) => {
        if (exec?.compensation?.[year]?.total) {
          exec.compensation[year].originalTotal =
            exec?.compensation?.[year]?.total;
        }
        const position = getExecPosition(exec, year);
        if (
          position &&
          position.some(
            (pos) =>
              getExecPositionGroup(pos).abbreviation === "ceo" &&
              exec?.compensation?.[year]?.total
          )
        ) {
          PEOexecs.push(exec);
        } else {
          nonPEOexecs.push(exec);
        }
      });
      return { PEOexecs, nonPEOexecs };
    },
    [companyExecutives, PvPTableYearSelected]
  );

  useMemo(() => {
    const newCompanyNEOs = {};
    yearsToDisplay.forEach((year, index) => {
      const companyExecs = getPEOAndNonPEOComp(year);
      newCompanyNEOs[year] = companyExecs;
    });
    setCompanyNEOs(newCompanyNEOs);
  }, [yearsToDisplay, getPEOAndNonPEOComp]);

  const filteredMeasureOptions = useMemo(() => {
    const filteredMeasureOptions = Object.values(measureOptions)
      .filter(
        (meas) =>
          yearsToDisplay &&
          (yearsToDisplay.some(
            (year) => meas && meas.getValue(companyInfo, year)
          ) ||
            meas?.isAlwaysSelectable) &&
          !["CAP", "TSR"].some((excludeTerm) =>
            meas.title.includes(excludeTerm)
          )
      )
      .map((meas) => {
        return {
          ...meas,
          dataAvailable: yearsToDisplay.every(
            (year) =>
              meas &&
              meas.getValue(companyInfo, year) &&
              meas.getValue(companyInfo, year) !== "N/A"
          )
            ? "all"
            : yearsToDisplay.some(
                (year) =>
                  meas &&
                  meas.getValue(companyInfo, year) &&
                  meas.getValue(companyInfo, year) !== "N/A"
              )
            ? "some"
            : "none",
        };
      });
    if (companyInfo?.Measures?.CoSelectedMeasureName) {
      for (const measureYear of Object.keys(
        companyInfo.Measures.CoSelectedMeasureName
      )) {
        filteredMeasureOptions.push({
          title: companyInfo?.Measures?.CoSelectedMeasureName?.[measureYear],
          displayTitle: (
            <b>
              {companyInfo?.Ticker} Selected Measure ({measureYear})
            </b>
          ),
          description: "Company Selected Measure Found in Current PvP Table",
          dataSource: "None currently available.",
          getValue: function (companyInfo, year) {
            const CoSelectedMeasureAmt =
              companyInfo?.Measures?.CoSelectedMeasureAmt?.[year];
            if (!CoSelectedMeasureAmt) return null;
            return CoSelectedMeasureAmt;
          },
          dataType: "",
          key: companyInfo?.Measures?.CoSelectedMeasureName?.[measureYear],
          isCompanySelectedValue: true,
        });
      }
    }
    filteredMeasureOptions.push({
      title: <b>Most Common Measures</b>,
      description:
        "Select measure(s) from a list of most common company selected measures. Click on the table cells to edit the values for the measure.",
      dataSource: "None currently available.",
      getValue: function (companyInfo, year) {
        return null;
      },
      dataType: "",
      isAlwaysSelectable: true,
      isCustom: true,
    });
    filteredMeasureOptions.push({
      title: <b>Custom Measure</b>,
      description:
        "Type in a custom measure name to add it to the table. Click on the table cells to edit the values for the measure.",
      dataSource: "None currently available.",
      getValue: function (companyInfo, year) {
        return null;
      },
      dataType: "",
      isAlwaysSelectable: true,
      isCustomInput: true,
    });
    filteredMeasureOptions.push({
      title: <b>Comparison Group Measures</b>,
      description: "Measures found within the peer group currently selected.",
      dataSource: "None currently available.",
      getValue: function (companyInfo, year) {
        return null;
      },
      dataType: "",
      isAlwaysSelectable: true,
      isCustomPeerGroupMeasures: true,
    });

    filteredMeasureOptions.push({
      title: <b>Select Measures based on Correlation</b>,
      description:
        "Select Measures based on correlation to the other PvP table columns",
      dataSource: "None currently available.",
      getValue: function (companyInfo, year) {
        return null;
      },
      dataType: "",
      isAlwaysSelectable: true,
      isCustomCorrelationMeasure: true,
    });
    return filteredMeasureOptions;
  }, [companyInfo, yearsToDisplay]);

  const [originalDataSource, setOriginalDataSource] = useState();
  const [triggerUpdate, setTriggerUpdate] = useState(false);
  const [copy, setCopy] = useState({});

  const dataSource = useMemo(() => {
    const getOriginalValue = (key, year) => {
      if (!originalDataSource) return;
      const dataSource = originalDataSource?.find((data) => data.year === year);
      if (!dataSource) return;
      const originalValue = dataSource?.[key]?.props?.cellData;
      return originalValue;
    };

    const getPEOActuallyPaidMeasure = (companyInfo, year, execName) => {
      const allPEOCAPs = getMostRecentMeasure(
        companyInfo,
        yearsAvailable,
        "PeoActuallyPaidCompAmt"
      );
      const splitExecName = execName?.split(" ");
      const execLastName = splitExecName?.[splitExecName.length - 1];
      const PEO_CAP =
        Array.isArray(allPEOCAPs?.value) &&
        allPEOCAPs?.value?.find(
          (item) =>
            item?.year === year &&
            item?.memberValue?.split("Member")?.[0] === execLastName
        );
      return PEO_CAP;
    };

    const newDataSource = yearsToDisplay.map((year, index) => {
      const TSRforYear = parseFloat(
        PvPTableTSRdata.find(
          (item) => item.TSRPeriod?.split(" ")?.[1] === year.toString()
        )?.calculatedTSR
      );
      const peerGroupTSR = parseFloat(
        PvPTableTSRdata.find((item) => {
          return item.TSRPeriod?.split(" ")?.[1] === year.toString();
        })?.[
          `peerGroup${
            weightingSelected.includes("Median")
              ? "MedianWeighted"
              : weightingSelected.includes("201 Compliant")
              ? "201Compliant"
              : weightingSelected.replace("-", "")
          }`
        ]
      );

      const measureValue = customCorrelationMeasureSelected?.getValue
        ? customCorrelationMeasureSelected.getValue(companyInfo, year)
        : measure?.getValue
        ? measure.getValue(companyInfo, year)
        : null;
      const formattedMeasure = formatMeasure(
        measureValue,
        customCorrelationMeasureSelected?.dataType || measure?.dataType
      );

      const PeoTotalCompAmt = PEOkeys?.length
        ? getCompensationAverage(
            [companyNEOs[year]?.PEOexecs.find((PEO) => PEO.key === PEOkeys[0])],
            year
          )
        : null;

      const PeoActuallyPaidCompAmt = !isNaN(
        parseInt(getMeasure(companyInfo, year, "PeoActuallyPaidCompAmt")?.value)
      )
        ? getMeasure(companyInfo, year, "PeoActuallyPaidCompAmt")?.value
        : getPEOActuallyPaidMeasure(companyInfo, year, getExecName(PEOkeys[0]))
            ?.value;
      const NonPeoNeoAvgTotalCompAmt = getCompensationAverage(
        companyNEOs[year]?.nonPEOexecs,
        year
      );
      const NonPeoNeoAvgCompActuallyPaidAmt = getMeasure(
        companyInfo,
        year,
        "NonPeoNeoAvgCompActuallyPaidAmt"
      )?.value;
      const netIncomeValue = getDatapoint(companyInfo, year, "NetIncome")
        ?.value.toLocaleString()
        ?.replaceAll(",", "");

      const dataForYear = {
        key: index,
        year: year,
        PeoTotalCompAmt: (
          <EditableTableCell
            cellData={
              manualData?.PeoTotalCompAmt?.[year] ||
              (PeoTotalCompAmt ? PeoTotalCompAmt : emdash)
            }
            dataType="dollar"
            key={"PeoTotalCompAmt" + year + updateCount}
            originalValue={getOriginalValue("PeoTotalCompAmt", year)}
            currentValue={PeoTotalCompAmt ? PeoTotalCompAmt : emdash}
            // setManualUpdate={setManualData}
            // manualKey={`PeoTotalCompAmt-${year}`}
            setManualUpdate={setManualData}
            manualKey={["PeoTotalCompAmt", year]}
            //TODO: Add properties as needed to complete CSV export
            // setTriggerUpdate={setTriggerUpdate}
            // updateKey={["PeoTotalCompAmt", year]}
            // setCopy={setCurrentPvPTable}
            // year={year}
          />
        ),
        PeoActuallyPaidCompAmt: (
          <EditableTableCell
            cellData={
              manualData?.PeoActuallyPaidCompAmt?.[year] ||
              (PeoActuallyPaidCompAmt ? PeoActuallyPaidCompAmt : emdash)
            }
            setManualUpdate={setManualData}
            manualKey={["PeoActuallyPaidCompAmt", year]}
            dataType="dollar"
            key={"PeoActuallyPaidCompAmt" + year + updateCount}
            originalValue={getOriginalValue("PeoActuallyPaidCompAmt", year)}
            currentValue={
              PeoActuallyPaidCompAmt ? PeoActuallyPaidCompAmt : emdash
            }
          />
        ),
        NonPeoNeoAvgTotalCompAmt: (
          <EditableTableCell
            cellData={
              manualData?.NonPeoNeoAvgTotalCompAmt?.[year] ||
              (NonPeoNeoAvgTotalCompAmt ? NonPeoNeoAvgTotalCompAmt : emdash)
            }
            dataType="dollar"
            key={"NonPeoNeoAvgTotalCompAmt" + year + updateCount}
            originalValue={getOriginalValue("NonPeoNeoAvgTotalCompAmt", year)}
            setManualUpdate={setManualData}
            manualKey={["NonPeoNeoAvgTotalCompAmt", year]}
            currentValue={
              NonPeoNeoAvgTotalCompAmt ? NonPeoNeoAvgTotalCompAmt : emdash
            }
          />
        ),
        NonPeoNeoAvgCompActuallyPaidAmt: (
          <EditableTableCell
            cellData={
              manualData?.NonPeoNeoAvgCompActuallyPaidAmt?.[year] ||
              (NonPeoNeoAvgCompActuallyPaidAmt
                ? NonPeoNeoAvgCompActuallyPaidAmt
                : emdash)
            }
            dataType="dollar"
            key={"NonPeoNeoAvgCompActuallyPaidAmt" + year + updateCount}
            originalValue={getOriginalValue(
              "NonPeoNeoAvgCompActuallyPaidAmt",
              year
            )}
            currentValue={
              NonPeoNeoAvgCompActuallyPaidAmt
                ? NonPeoNeoAvgCompActuallyPaidAmt
                : emdash
            }
            setManualUpdate={setManualData}
            manualKey={["NonPeoNeoAvgCompActuallyPaidAmt", year]}
          />
        ),
        TotalShareholderRtnAmt: calculating ? (
          "Calculating..."
        ) : (
          <EditableTableCell
            cellData={
              manualData?.TotalShareholderRtnAmt?.[year] ||
              (!isNaN(TSRforYear) ? TSRforYear.toFixed(2) : emdash)
            }
            dataType="fixed"
            key={"TotalShareholderRtnAmt" + year + updateCount}
            originalValue={getOriginalValue("TotalShareholderRtnAmt", year)}
            currentValue={!isNaN(TSRforYear) ? TSRforYear.toFixed(2) : emdash}
            setManualUpdate={setManualData}
            manualKey={["TotalShareholderRtnAmt", year]}
          />
        ),
        PeerGroupTotalShareholderRtnAmt: calculating ? (
          "Calculating..."
        ) : (
          <EditableTableCell
            cellData={
              manualData?.PeerGroupTotalShareholderRtnAmt?.[year] ||
              (!isNaN(peerGroupTSR) ? peerGroupTSR.toFixed(2) : emdash)
            }
            dataType="fixed"
            key={"PeerGroupTotalShareholderRtnAmt" + year + updateCount}
            originalValue={getOriginalValue(
              "PeerGroupTotalShareholderRtnAmt",
              year
            )}
            currentValue={
              !isNaN(peerGroupTSR) ? peerGroupTSR.toFixed(2) : emdash
            }
            setManualUpdate={setManualData}
            manualKey={["PeerGroupTotalShareholderRtnAmt", year]}
          />
        ),
        NetIncomeLoss: (
          <EditableTableCell
            cellData={
              manualData?.NetIncomeLoss?.[year] ||
              (netIncomeValue !== "N/A" ? netIncomeValue : emdash)
            }
            dataType="dollar"
            key={"NetIncomeLoss" + year + updateCount}
            originalValue={getOriginalValue("NetIncomeLoss", year)}
            currentValue={netIncomeValue !== "N/A" ? netIncomeValue : emdash}
            setManualUpdate={setManualData}
            manualKey={["NetIncomeLoss", year]}
          />
        ),
      };
      if (customMeasuresSelected.length === 0) {
        dataForYear["CoSelectedMeasureAmt"] = (
          <EditableTableCell
            cellData={
              manualData?.CoSelectedMeasureAmt?.[year] ||
              (formattedMeasure
                ? formattedMeasure?.toString()?.replace("$", "")
                : measure?.isCompanySelectedValue &&
                  companyInfo?.Measures?.CoSelectedMeasureAmt?.[year]
                ? companyInfo?.Measures?.CoSelectedMeasureAmt?.[year]
                : emdash)
            }
            dataType={
              customCorrelationMeasureSelected?.dataType || measure?.dataType
            }
            isPayRatio={
              customCorrelationMeasureSelected?.title === "CEO Pay Ratio" ||
              measure?.title === "CEO Pay Ratio"
            }
            key={"CoSelectedMeasureAmt" + year + updateCount}
            originalValue={getOriginalValue("CoSelectedMeasureAmt", year)}
            currentValue={
              formattedMeasure
                ? formattedMeasure?.toString()?.replace("$", "")
                : measure?.isCompanySelectedValue &&
                  companyInfo?.Measures?.CoSelectedMeasureAmt?.[year]
                ? companyInfo?.Measures?.CoSelectedMeasureAmt?.[year]
                : emdash
            }
            setManualUpdate={setManualData}
            manualKey={["CoSelectedMeasureAmt", year]}
          />
        );
      } else {
        customMeasuresSelected.forEach((meas, index) => {
          dataForYear[`CoSelectedMeasureAmt-${index}`] = (
            <EditableTableCell
              cellData={
                manualData?.[`CoSelectedMeasureAmt-${index}`]?.[year] || emdash
              }
              dataType={meas?.units}
              isPayRatio={meas === "CEO Pay Ratio"}
              key={"CoSelectedMeasureAmt" + year + index + updateCount}
              originalValue={getOriginalValue(
                `CoSelectedMeasureAmt-${index}`,
                year
              )}
              setManualUpdate={setManualData}
              manualKey={[`CoSelectedMeasureAmt-${index}`, year]}
            />
          );
        });
      }
      if (PEOkeys.length > 1) {
        for (let i = 1; i < PEOkeys.length; i++) {
          const PEOTotalComp = getCompensationAverage(
            [companyNEOs[year]?.PEOexecs.find((PEO) => PEO.key === PEOkeys[i])],
            year
          );
          //Ensure it is not already included~
          // const PeoActuallyPaidComp = emdash;
          const PeoActuallyPaidComp = getPEOActuallyPaidMeasure(
            companyInfo,
            year,
            getExecName(PEOkeys[i])
          )?.value;
          if (
            !Object.keys(dataForYear)?.some(
              (item) => item === `PeoTotalCompAmt${i + 1}`
            )
          ) {
            dataForYear[`PeoTotalCompAmt${i + 1}`] = (
              <EditableTableCell
                cellData={
                  manualData?.[`PeoTotalCompAmt${i + 1}`]?.[year] ||
                  (PEOTotalComp ? PEOTotalComp : emdash)
                }
                dataType="dollar"
                key={"PeoTotalCompAmt" + year + updateCount}
                originalValue={getOriginalValue(
                  `PeoTotalCompAmt${i + 1}`,
                  year
                )}
                currentValue={PEOTotalComp ? PEOTotalComp : emdash}
                setManualUpdate={setManualData}
                manualKey={[`PeoTotalCompAmt${i + 1}`, year]}
              />
            );
            dataForYear[`PeoActuallyPaidCompAmt${i + 1}`] = (
              <EditableTableCell
                cellData={
                  manualData?.[`PeoActuallyPaidCompAmt${i + 1}`]?.[year] ||
                  (PeoActuallyPaidComp ? PeoActuallyPaidComp : emdash)
                }
                dataType="dollar"
                key={`PeoActuallyPaidCompAmt${i + 1}` + year + updateCount}
                originalValue={getOriginalValue(
                  `PeoActuallyPaidCompAmt${i + 1}`,
                  year
                )}
                currentValue={
                  PeoActuallyPaidComp ? PeoActuallyPaidComp : emdash
                }
                setManualUpdate={setManualData}
                manualKey={[`PeoActuallyPaidCompAmt${i + 1}`, year]}
              />
            );
          }
        }
      }
      if (PvPIndexesSelected.length) {
        PvPIndexesSelected.forEach((index) => {
          dataForYear[index] = parseFloat(
            PvPTableTSRdata.find((item) => {
              return item.TSRPeriod?.split(" ")?.[1] === year.toString();
            })?.[index]
          );
        });
      }
      return dataForYear;
    });

    return newDataSource;
  }, [
    yearsToDisplay,
    PvPTableTSRdata,
    companyInfo,
    calculating,
    measure,
    customCorrelationMeasureSelected,
    weightingSelected,
    companyNEOs,
    updateCount,
    PEOkeys,
    PvPIndexesSelected,
    customMeasuresSelected,
    originalDataSource,
    yearsAvailable,
    getExecName,
    manualData,
  ]);

  useEffect(() => {
    setOriginalDataSource(undefined);
  }, [
    yearsToDisplay,
    PvPTableTSRdata,
    companyInfo,
    calculating,
    measure,
    weightingSelected,
    PvPIndexesSelected,
    customMeasuresSelected,
  ]);
  useMemo(() => {
    if (!calculating && !originalDataSource) {
      setOriginalDataSource(dataSource);
    }
  }, [dataSource, calculating, originalDataSource]);

  // console.log(triggerUpdate);
  // useEffect(() => {
  //   if (triggerUpdate) {
  //     console.log(dataSource);
  //     setTriggerUpdate(false);
  //   }
  // }, [triggerUpdate, dataSource]);
  // console.log(copy);

  const columns = useMemo(() => {
    const columns = [
      {
        title: "Year",
        dataIndex: "year",
        key: "year",
        align: "center",
      },
      {
        title: (
          <div>
            <p>
              Summary Compensation Table Total for PEO{" "}
              {getExecName(PEOkeys[0]) ? `(${getExecName(PEOkeys[0])}) ` : ""}
              ($)
            </p>
            <p
              className="link"
              onClick={() => {
                setShowPEOsAndNEOsTable(!showPEOsAndNEOsTable);
              }}
            >
              {!showPEOsAndNEOsTable ? "Edit PEOs" : "Done Editing"}
            </p>
          </div>
        ),
        displayTitle: `Summary Compensation Table Total for PEO
              ${
                getExecName(PEOkeys[0]) ? `(${getExecName(PEOkeys[0])}) ` : ""
              }`,
        dataIndex: "PeoTotalCompAmt",
        key: "PeoTotalCompAmt",
        align: "center",
      },
      {
        title: `Compensation Actually Paid to PEO ${
          getExecName(PEOkeys[0]) ? `(${getExecName(PEOkeys[0])}) ` : ""
        }($)`,
        dataIndex: "PeoActuallyPaidCompAmt",
        key: "PeoActuallyPaidCompAmt",
        align: "center",
      },
      {
        title: (
          <div>
            <p>Average Summary Compensation for Non-PEO NEOs ($)</p>
            <p
              className="link"
              onClick={() => {
                setShowPEOsAndNEOsTable(!showPEOsAndNEOsTable);
              }}
            >
              {!showPEOsAndNEOsTable ? "Edit Non-PEOs" : "Done Editing"}
            </p>
          </div>
        ),
        displayTitle: "Average Summary Compensation for Non-PEO NEOs ($)",
        dataIndex: "NonPeoNeoAvgTotalCompAmt",
        key: "NonPeoNeoAvgTotalCompAmt",
        align: "center",
      },
      {
        title: "Average Compensation Actually Paid to Non-PEO NEOs ($)",
        dataIndex: "NonPeoNeoAvgCompActuallyPaidAmt",
        key: "NonPeoNeoAvgCompActuallyPaidAmt",
        align: "center",
      },
      {
        title: `${companyInfo?.Company || companyInfo.Ticker} TSR ($)`,
        dataIndex: "TotalShareholderRtnAmt",
        key: "TotalShareholderRtnAmt",
        align: "center",
      },
      {
        title: `Peer Group TSR ($)`,
        dataIndex: "PeerGroupTotalShareholderRtnAmt",
        key: "PeerGroupTotalShareholderRtnAmt",
        align: "center",
      },
      {
        title: "Net Income ($)",
        dataIndex: "NetIncomeLoss",
        key: "NetIncomeLoss",
        align: "center",
      },
    ];
    if (
      !measure?.isCustom &&
      !measure?.isCustomInput &&
      !measure?.isCustomPeerGroupMeasures
    ) {
      columns.push({
        title: `${
          customCorrelationMeasureSelected?.title ||
          measure?.title ||
          "Company Selected Measure"
        } ${
          customCorrelationMeasureSelected?.dataType === "dollar" ||
          measure?.dataType === "dollar"
            ? "($)"
            : ""
        }`,
        dataIndex: "CoSelectedMeasureAmt",
        key: "CoSelectedMeasureAmt",
        align: "center",
      });
    } else {
      if (customMeasuresSelected.length === 0) {
        columns.push({
          title: `${"Company Selected Measure"}`,
          dataIndex: "CoSelectedMeasureAmt",
          key: "CoSelectedMeasureAmt",
          align: "center",
        });
      } else {
        customMeasuresSelected.forEach((customMeasure, index) => {
          columns.push({
            title: `${customMeasure.value} ${
              customMeasure?.units === "dollar" ? "($)" : ""
            }`,
            dataIndex: `CoSelectedMeasureAmt-${index}`,
            key: `CoSelectedMeasureAmt-${index}`,
            align: "center",
          });
        });
      }
    }
    if (PvPIndexesSelected.length) {
      PvPIndexesSelected.forEach((index) => {
        //Ensure not already included before splicing
        if (!columns.some((item) => item.key && item.key === index)) {
          columns.splice(
            columns.indexOf(
              columns.find((item) => item.key === "NetIncomeLoss")
            ),
            0,
            {
              title: `${index} TSR`,
              dataIndex: index,
              key: index,
              align: "center",
              render: (data) => {
                return !isNaN(parseFloat(data))
                  ? parseFloat(data).toFixed(2)
                  : emdash;
              },
            }
          );
        }
      });
    }
    return columns;
  }, [
    customMeasuresSelected,
    PEOkeys,
    companyInfo,
    measure,
    customCorrelationMeasureSelected,
    PvPIndexesSelected,
    showPEOsAndNEOsTable,
    getExecName,
  ]);

  if (PEOkeys.length > 1) {
    for (let i = 1; i < PEOkeys.length; i++) {
      const execName = getExecName(PEOkeys[i]);
      //Ensure not already included before splicing
      if (
        !columns.some(
          (item) => item.key && item.key.includes(`PeoTotalCompAmt${i + 1}`)
        )
      ) {
        columns.splice(i + 1, 0, {
          title: (
            <div>
              <p>
                Summary Compensation Table Total for PEO {i + 1}{" "}
                {execName ? `(${execName}) ` : ""}($)
              </p>
              <p
                className="link"
                onClick={() => {
                  setShowPEOsAndNEOsTable(!showPEOsAndNEOsTable);
                }}
              >
                {!showPEOsAndNEOsTable ? "Edit PEOs" : "Done Editing"}
              </p>
            </div>
          ),
          displayTitle: `Summary Compensation Table Total for PEO ${i + 1}
                ${execName ? `(${execName}) ` : ""}($)`,
          dataIndex: `PeoTotalCompAmt${i + 1}`,
          key: `PeoTotalCompAmt${i + 1}`,
          align: "center",
        });
        columns.splice(i + i + 2, 0, {
          title: `Compensation Actually Paid to PEO ${i + 1} ${
            execName ? `(${execName}) ` : ""
          }($)`,
          dataIndex: `PeoActuallyPaidCompAmt${i + 1}`,
          key: `PeoActuallyPaidCompAmt${i + 1} `,
          align: "center",
        });
      }
    }
  }

  const handleIndexSelectorChange = (value) => {
    setPvPIndexesSelected(value);
  };

  useEffect(() => {
    const currentYear =
      new Date().getFullYear() - (new Date().getMonth() < 1 ? 1 : 0);
    if (PvPTableYearSelected !== currentYear) {
      setProFormaTable(false);
    }
    if (PvPTableYearSelected === currentYear) {
      setProFormaTable(true);
    }
  }, [PvPTableYearSelected]);

  useEffect(() => {
    const currentYear =
      new Date().getFullYear() - (new Date().getMonth() < 1 ? 1 : 0);

    if (proFormaTable) {
      setPvPTableYearSelected(currentYear);
    }
    if (!proFormaTable) {
      setPvPTableYearSelected(currentYear - 1);
    }
  }, [proFormaTable, setPvPTableYearSelected]);

  const addCustomMeasure = () => {
    if (customMeasureName) {
      setCustomMeasuresSelected((prev) => [
        ...prev,
        {
          label: customMeasureName,
          value: customMeasureName,
          units: "",
          key: customMeasureName + customMeasuresSelected.length,
        },
      ]);
    }
    setCustomMeasureName("");
  };

  useEffect(() => {
    //Update table cells if params are changed:
    if (
      weightingSelected &&
      PvPTableYearSelected &&
      PvPTableMonthSelected &&
      PvPTableTSRdata &&
      PvPRangeSelected
    ) {
      setUpdateCount((prev) => prev + 1);
      setCustomMeasuresSelected([]);
      setCustomMeasureName("");
    }
  }, [
    measure,
    customCorrelationMeasureSelected,
    PvPTableTSRdata,
    weightingSelected,
    PvPTableYearSelected,
    PvPTableMonthSelected,
    PvPRangeSelected,
  ]);

  const updateCustomMeasuresSelected = (val) => {
    const customMeasure = mostCommonMeasures.find((item) => item.value === val);
    setCustomMeasuresSelected((prev) => [...prev, customMeasure]);
  };

  const handleUnitChange = (meas, val) => {
    if (!meas?.isCompanySelectedValue) {
      setCustomMeasuresSelected((prev) =>
        prev.map((item) =>
          item.label !== meas.label ? item : { ...item, units: val }
        )
      );
    } else {
      setMeasure((prev) => ({ ...prev, dataType: val }));
    }
  };

  const handleCustomMeasureInputChange = (input) => {
    //If changing value make sure to change it in
    input = input.replace(DISCLOSURE_REGEX, "").slice(0, 50);
    setCustomMeasureName(input);
  };

  // CSV export
  const formatPvPTableDataForCSVexport = useCallback(() => {
    const getFormattedKey = (key) => {
      return typeof key?.title === "string"
        ? key?.title
        : Array.isArray(key?.title?.props?.children?.[0]?.props?.children)
        ? key?.title?.props?.children?.[0]?.props?.children?.join(" ")
        : key?.title?.props?.children?.[0]?.props?.children;
    };

    const formattedPvPTableData = [];

    dataSource.forEach((yearOfData) => {
      const formattedYearOfData = {};

      for (const column of columns) {
        const key = column.key;
        const formattedKey = getFormattedKey(column);

        const value = yearOfData?.[column.key];

        const currentValue =
          key === "year"
            ? value
            : value?.props &&
              value?.props?.cellData !== emdash &&
              !isNaN(parseFloat(value?.props?.cellData))
            ? value.props.cellData
            : "";

        formattedYearOfData[`"${formattedKey}"`] = !isNaN(
          parseFloat(currentValue)
        )
          ? `"${parseFloat(currentValue.toString().replaceAll(",", ""))}"` +
            (currentValue.toString().includes("%") ? "%" : "")
          : "";
      }
      formattedPvPTableData.push(formattedYearOfData);
    });
    return formattedPvPTableData;
  }, [dataSource, columns]);

  const customMeasureList = (measureArray) => {
    return (
      <div style={{ margin: 10 }}>
        {measureArray.map((meas, index) => (
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              fontSize: 17.5,
              width: "100%",
              margin: 5,
            }}
            key={meas + index}
          >
            {!meas?.isCompanySelectedValue && (
              <img
                alt="remove"
                style={{
                  width: 10,
                  marginRight: 10,
                  marginTop: 5,
                  cursor: "pointer",
                }}
                onClick={() =>
                  setCustomMeasuresSelected((prev) =>
                    prev.filter((item) => item !== meas)
                  )
                }
                src={removeSVG}
              />
            )}
            <span
              style={{
                marginRight: 10,
                textAlign: "bottom",
                alignSelf: "flex-end",
                fontWeight: "bold",
              }}
            >
              {meas?.isCompanySelectedValue
                ? "Company Selected Measure:"
                : `Custom Measure ${index + 1}:`}
            </span>
            <span
              style={{
                marginRight: 25,
                textAlign: "bottom",
                alignSelf: "flex-end",
              }}
            >
              {measure?.isCustom ||
              measure?.isCustomInput ||
              measure?.isCustomPeerGroupMeasures ||
              measure?.isCustomCorrelationMeasure
                ? meas.value
                : meas.title}
            </span>
            <span
              style={{
                marginRight: 10,
                textAlign: "bottom",
                alignSelf: "flex-end",
                fontWeight: "bold",
              }}
            >
              Format:
            </span>
            <Select
              placeholder="Format"
              style={{ width: 100, marginTop: 10 }}
              onChange={(val) => handleUnitChange(meas, val)}
              size="small"
              key={meas + index}
              value={meas?.units || meas?.dataType || undefined}
              options={[
                { value: "dollar", label: "Dollar" },
                { value: "percent", label: "Percent" },
                { value: "fixed", label: "Ratio" },
                { value: "other", label: "Other" },
              ]}
            />
          </div>
        ))}
      </div>
    );
  };

  const COLORS = [
    "#59bd4b",
    "#4f5cc4",
    "#d29fd9",
    "#d95766",
    "#de8c59",
    "#ad7857",
    "#7f91a1",
    "#FFBB28",
    "#FF8042",
    "#0088FE",
    "#00C49F",
  ];

  const renderActiveShape = (props) => {
    const RADIAN = Math.PI / 180;
    const {
      cx,
      cy,
      midAngle,
      innerRadius,
      outerRadius,
      startAngle,
      endAngle,
      fill,
      payload,
      percent,
      value,
    } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? "start" : "end";
    return (
      <g>
        <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
          {payload.name}
        </text>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
        <Sector
          cx={cx}
          cy={cy}
          startAngle={startAngle}
          endAngle={endAngle}
          innerRadius={outerRadius + 6}
          outerRadius={outerRadius + 10}
          fill={fill}
        />
        <path
          d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
          stroke={fill}
          fill="none"
        />
        <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
        <text
          x={ex + (cos >= 0 ? 1 : -1) * 12}
          y={ey}
          textAnchor={textAnchor}
          fill="#333"
        >{`${payload.value}`}</text>
        <text
          x={ex + (cos >= 0 ? 1 : -1) * 12}
          y={ey}
          dy={18}
          textAnchor={textAnchor}
          fill="#999"
        >
          {`(Prevalence ${(percent * 100).toFixed(2)}%)`}
        </text>
      </g>
    );
  };
  const [activeIndex, setActiveIndex] = useState("");
  const onPieEnter = (_, index) => {
    setActiveIndex(index);
  };

  const filteredPeerGroupMeasures = useMemo(() => {
    if (peerGroupMeasures.length < 40) return peerGroupMeasures;
    const filteredMeasures = peerGroupMeasures.filter((item) => item.count > 1);
    const otherMeasures = peerGroupMeasures.filter((item) => item.count <= 1);
    filteredMeasures.push({
      label: "Other",
      value: "Other",
      key: "Other",
      count: otherMeasures.length,
      units: "",
    });
    return filteredMeasures;
  }, [peerGroupMeasures]);

  return (
    <div>
      <h1>Pay-Versus-Performance</h1>
      <RadioSelector
        heading="Year:"
        options={yearsAvailable}
        state={PvPTableYearSelected}
        setState={setPvPTableYearSelected}
      />

      <RadioSelector
        heading="Year Range:"
        options={["3yr", "4yr", "5yr"]}
        state={PvPRangeSelected}
        setState={setPvPRangeSelected}
      />
      <RadioSelector
        heading="TSR Month:"
        options={MONTHS}
        state={PvPTableMonthSelected}
        setState={setPvPTableMonthSelected}
      />
      {CalculatorCohortSelectorComponent}
      <RadioSelector
        options={["Include", "Exclude"]}
        state={includeCompanyInPeerGroup}
        setState={setIncludeCompanyInPeerGroup}
        heading={`Include ${companyInfo.Ticker} in Comparison Group:`}
        style={{ marginBottom: 15 }}
      />
      <RadioSelector
        heading="Comparison Group TSR Weighting Strategy:"
        options={weightingsAvalable}
        state={weightingSelected}
        setState={setWeightingSelected}
      />
      <RadioSelector
        options={["Annual", "Quarterly", "Monthly"]}
        state={PvPPeriodicitySelected}
        setState={setPvPPeriodicitySelected}
        heading="Comparison Group TSR Periodicity:"
      />
      <div
        style={{ width: "100%", display: "flex", marginTop: 20 }}
        className="hasHoverText"
      >
        <Tooltip title={indexComparisonMessage}>
          {hasHoverText(
            <h3
              style={{
                fontSize: 20,
                marginRight: -5,
                marginBottom: 5,
                marginTop: 5,
              }}
            >
              {"Compare to Index:".split(" ").map((head, index) => (
                <span key={index}>{head}&nbsp;</span>
              ))}
            </h3>
          )}
        </Tooltip>
        <Select
          mode="multiple"
          allowClear
          style={{ width: "100%" }}
          placeholder="Select Indexes to Compare"
          onChange={handleIndexSelectorChange}
          options={indexSelectorOptions}
        />
      </div>
      <MeasureSelector
        measure={measure}
        setMeasure={setMeasure}
        measureOptions={filteredMeasureOptions}
        heading="Company Selected Measure:"
        useCustomHeading={true}
        showIfDataAvailable={true}
      />
      {measure?.isCustom && (
        <div
          style={{
            width: "100%",
            display: "flex",
            marginTop: 10,
            marginBottom: 10,
          }}
        >
          <h3 style={{ fontSize: 20, marginRight: 10, marginBottom: 0 }}>
            {"Select Measures:".split(" ").map((head, index) => (
              <span key={index}>{head}&nbsp;</span>
            ))}
          </h3>
          <Select
            mode="multiple"
            allowClear
            style={{
              width: "100%",
            }}
            placeholder="Please select"
            defaultValue={[]}
            onSelect={(value) => updateCustomMeasuresSelected(value)}
            onDeselect={(value) =>
              setCustomMeasuresSelected((prev) =>
                prev.filter((item) => item.value !== value)
              )
            }
            onClear={() => setCustomMeasuresSelected([])}
            value={customMeasuresSelected.map((item) => item.value)}
            options={mostCommonMeasures}
          />
        </div>
      )}
      {measure?.isCustomInput && (
        <div
          style={{
            width: "100%",
            display: "flex",
            marginTop: 10,
            marginBottom: 10,
          }}
        >
          <h3 style={{ fontSize: 20, marginRight: 10, marginBottom: 0 }}>
            {"Custom Measure Name:".split(" ").map((head, index) => (
              <span key={index}>{head}&nbsp;</span>
            ))}
          </h3>
          <Search
            placeholder="Enter custom measure(s)..."
            allowClear
            enterButton="Add Measure"
            onChange={(e) => handleCustomMeasureInputChange(e.target.value)}
            value={customMeasureName}
            onSearch={() => addCustomMeasure(customMeasureName)}
          />
        </div>
      )}
      {measure?.isCustomPeerGroupMeasures && (
        <div
          style={{
            width: "100%",
            display: "flex",
            flexWrap: "wrap",
            marginTop: 10,
            marginBottom: 10,
          }}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              marginTop: 10,
              marginBottom: 10,
            }}
          >
            <h3 style={{ fontSize: 20, marginRight: 10, marginBottom: 0 }}>
              {"Select Peer Group Measures:".split(" ").map((head, index) => (
                <span key={index}>{head}&nbsp;</span>
              ))}
            </h3>

            <Select
              mode="multiple"
              allowClear
              style={{
                width: "100%",
              }}
              placeholder="Please select"
              defaultValue={[]}
              onSelect={(value) => updateCustomMeasuresSelected(value)}
              onDeselect={(value) =>
                setCustomMeasuresSelected((prev) =>
                  prev.filter((item) => item.value !== value)
                )
              }
              onClear={() => setCustomMeasuresSelected([])}
              value={customMeasuresSelected.map((item) => item.value)}
              options={peerGroupMeasures}
            />
          </div>
          <div style={{ width: "100%" }}>
            <RadioSelector
              options={["All Company Measures", "PvP Table Measures"]}
              state={peerGroupMeasureType}
              setState={setPeerGroupMeasureType}
              style={{ justifyContent: "center", width: "100%" }}
              // heading="Select Measure Type:"
            />
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                margin: 0,
              }}
            >
              <hr
                style={{
                  width: "60%",
                  margin: 10,
                }}
              />
            </div>
            <RadioSelector
              options={[
                "Original Measure Names",
                "Revised Measure Names",
                "Measure Subgroups",
                "Measure Categories",
              ]}
              state={peerGroupMeasureGroup}
              setState={setPeerGroupMeasureGroup}
              style={{ justifyContent: "center" }}
              // heading="Select Measure Type:"
            />
          </div>

          <div
            style={{
              width: "100%",
              display: "flex",
              marginTop: 10,
              marginBottom: 10,
              justifyContent: "center",
            }}
          >
            <div
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "center",
                alignContent: "baseline",
              }}
            >
              <div
                style={{
                  width: "100%",
                }}
              >
                <h3 style={{ textAlign: "center" }}>
                  Measure Prevalence Table
                </h3>
              </div>
              <Table
                dataSource={peerGroupMeasures}
                columns={peerGroupMeasuresColumns}
                style={{ marginTop: 5, width: "100%" }}
                size={"small"}
                pagination={{
                  hideOnSinglePage: pagination > 10 ? false : true,
                  position: ["bottomCenter"],
                }}
                onChange={(pagination) => setPagination(pagination.pageSize)}
              />
            </div>
            <div
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "center",
                alignContent: "baseline",
              }}
            >
              <div
                style={{
                  width: "100%",
                }}
              >
                <h3 style={{ textAlign: "center" }}>
                  Measure Prevalence Pie Chart
                </h3>
              </div>

              {/* <ResponsiveContainer key={pagination}> */}
              <PieChart width={700} height={400} style={{ minHeight: 400 }}>
                <Pie
                  data={filteredPeerGroupMeasures}
                  dataKey="count"
                  nameKey="value"
                  cx="50%"
                  cy="50%"
                  outerRadius={150}
                  // label //default label
                  // labelLine={false}
                  isAnimationActive={false}
                  onMouseEnter={onPieEnter}
                  onMouseLeave={() => setActiveIndex("")}
                  activeIndex={activeIndex}
                  activeShape={renderActiveShape}
                >
                  {filteredPeerGroupMeasures.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={
                        entry.label === "Other"
                          ? "var(--gray-1)"
                          : COLORS[index % COLORS.length]
                      }
                    />
                  ))}
                </Pie>
                {/* <RechartsToolTip /> */}
              </PieChart>
              {/* </ResponsiveContainer> */}
            </div>
          </div>
        </div>
      )}
      {measure?.isCustomCorrelationMeasure && (
        <div
          style={{
            width: "100%",
            display: "flex",
            flexWrap: "wrap",
            marginTop: 10,
            marginBottom: 10,
          }}
        >
          <PvPScatterplotAndTable
            companyInfo={companyInfo}
            PvPTableColumns={columns}
            PvPTableData={dataSource}
            PDFprocessing={PDFprocessing}
            yearsToDisplay={yearsToDisplay}
            customCorrelationMeasureSelected={customCorrelationMeasureSelected}
            setCustomCorrelationMeasureSelected={
              setCustomCorrelationMeasureSelected
            }
          />
        </div>
      )}
      {(customMeasuresSelected?.length > 0 ||
        measure?.isCompanySelectedValue) && (
        <div
          style={{
            width: "100%",
            display: "flex",
            flexWrap: "wrap",
            marginTop: 10,
            marginBottom: 10,
          }}
        >
          <h3
            style={{
              fontSize: 20,
              marginRight: 10,
              marginBottom: 0,
              width: "100%",
            }}
          >
            {"Custom Measures Added:".split(" ").map((head, index) => (
              <span key={index}>{head}&nbsp;</span>
            ))}
          </h3>
          {customMeasureList(
            customMeasuresSelected?.length >= 1
              ? customMeasuresSelected
              : [measure]
          )}
        </div>
      )}

      <div style={{ width: "100%", display: "flex" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <h3 style={{ fontSize: 20, marginRight: 10, marginBottom: 0 }}>
            {"Pro Forma Table: ".split(" ").map((head, index) => (
              <span key={index}>{head}&nbsp;</span>
            ))}
          </h3>
        </div>
        <Switch
          checked={proFormaTable}
          onChange={() => setProFormaTable(!proFormaTable)}
        />
      </div>
      <div className="col-md d-flex justify-content-start align-items-end mt-3">
        <h3 style={{ fontSize: 20, marginRight: 10, marginBottom: 0 }}>
          CSV&nbsp;Export:&nbsp;
        </h3>

        <Button
          onClick={() =>
            exportToCSV(
              formatPvPTableDataForCSVexport(),
              `${companyInfo?.Ticker} PvP Table Data ${
                new Date().toString().split("GMT-")[0]
              }.csv`
            )
          }
        >
          Export Table to CSV
        </Button>
      </div>
      <Table
        dataSource={dataSource}
        columns={columns}
        pagination={{
          hideOnSinglePage: true,
          total: triggerUpdate ? 10 : 5,
        }}
        style={{ marginTop: "25px" }}
        scroll={{ x: true }}
      />
      <TSRperiodMessage
        TSRperiod={PvPRangeSelected}
        yearSelected={PvPTableYearSelected}
        monthSelected={PvPTableMonthSelected}
      />
      <p>* These values were manually edited.</p>
      {showPEOsAndNEOsTable && (
        <PEOsAndNEOsTable
          companyNEOs={companyNEOs}
          setCompanyNEOs={setCompanyNEOs}
          NEOyearsAvailable={yearsToDisplay}
          showPEOsAndNEOsTable={showPEOsAndNEOsTable}
          setShowPEOsAndNEOsTable={setShowPEOsAndNEOsTable}
          updateCount={updateCount}
          setUpdateCount={setUpdateCount}
          PDFprocessing={PDFprocessing}
        />
      )}
    </div>
  );
}
