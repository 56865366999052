import React, { useState, useEffect, useMemo } from "react";
import { Button, Select, Table } from "antd";
import { useSelector } from "react-redux";
import { sortClosestCompany } from "../../utils/ExecutiveData/sortClosest";
import { formatMeasure, fromDollar } from "../../utils/Constants/systemSetting";
import removeSVG from "../../assets/close.svg";
import { emdash } from "../../utils/Constants/systemSetting";

export default function PeerGroupTable({
  companyInfo,
  cohortTableTickers,
  peerGroupTSRs,
  peerGroupMarketcaps,
  customCohortCompanies,
  setCustomCohortCompanies,
  monthSelected,
  yearSelected,
  TSRperiod,
  cohortTableSelector,
  peerGroupTableRef,
  scrollToPeerGroup,
  setScrollToPeerGroup,
  includeCompanyInPeerGroup = "Include",
  allowRemoveCompany = false,
  setIncludeCompanyInPeerGroup = () => null,
  showPeerGroup,
  setShowPeerGroup,
}) {
  const { Option } = Select;
  const { companies } = useSelector((state) => state.CompaniesReducer);

  const [searchVal, setSearchVal] = useState("");
  const [companiesChildren, setCompaniesChildren] = useState([]);

  const [addedCohortCompanies, setAddedCohortCompanies] = useState([]);
  const [removedCompanies, setRemovedCompanies] = useState([]);

  useMemo(() => {
    const addedCohortTickers = addedCohortCompanies.map(
      (addedTicker) => addedTicker.split("[")[1].split("]")[0]
    );
    const newCustomCohortCompanies = [...addedCohortTickers];
    if (cohortTableTickers?.length) {
      newCustomCohortCompanies.push(
        ...cohortTableTickers.filter((item) => item)
      );
    }
    const filtedCustomCohortCompanies = newCustomCohortCompanies.filter(
      (ticker) =>
        addedCohortCompanies.some(
          (addedTicker) => addedTicker.split("[")[1].split("]")[0] === ticker
        ) ||
        (cohortTableTickers?.includes(ticker) &&
          !removedCompanies.includes(ticker))
    );
    setCustomCohortCompanies(filtedCustomCohortCompanies);
  }, [
    addedCohortCompanies,
    cohortTableTickers,
    removedCompanies,
    setCustomCohortCompanies,
  ]);

  useEffect(() => {
    // reset props when page is changed
    setAddedCohortCompanies([]);
    setRemovedCompanies([]);
  }, [companyInfo]);

  // Don't remove Companies from "Added Companies" been manually removed:
  useEffect(() => {
    const removedCompaniesNames = removedCompanies.map(
      (ticker) => `${companies[ticker]?.Company || ""} [${ticker}]`
    );
    const newAddedCompanies = addedCohortCompanies.filter((companyName) => {
      return !removedCompaniesNames.includes(companyName);
    });
    // Only update state if it has actually changed
    if (
      newAddedCompanies.length !== addedCohortCompanies.length ||
      !newAddedCompanies.every(
        (company, i) => company === addedCohortCompanies[i]
      )
    ) {
      setAddedCohortCompanies(newAddedCompanies);
    }
  }, [addedCohortCompanies, removedCompanies, companies]);

  // Reset added/removed Companies when chohort is changed
  useEffect(() => {
    setRemovedCompanies([]);
    setAddedCohortCompanies([]);
  }, [cohortTableSelector]);

  // Don't re-add companies that were in the original list
  useEffect(() => {
    setRemovedCompanies((r) =>
      r.filter(
        (ticker) =>
          cohortTableTickers.includes(ticker) &&
          !addedCohortCompanies.some((company) =>
            company.includes(`[${ticker}]`)
          )
      )
    );
  }, [addedCohortCompanies, cohortTableTickers]);

  useEffect(() => {
    if (Object.values(companies).length <= 10) return;
    setCompaniesChildren(
      Object.values(companies)
        .filter((item) => item)
        .filter((item) => {
          return (
            item?.Ticker &&
            // &&
            // customCohortCompanies.every((company) => {
            //   if (!company) return false;
            //   return (
            //     company?.Ticker &&
            //     (company.Company !== item.Company ||
            //       company.Ticker !== item.Ticker)
            //   );
            // }
            (searchVal === "" ||
              searchVal
                .toLowerCase()
                .split(" ")
                .every(
                  (val) =>
                    val === "" ||
                    item.Ticker.toLowerCase().includes(val) ||
                    item.Company.toLowerCase().includes(val)
                ))
          );
        })

        .sort((a, b) => {
          return sortClosestCompany(a, b, searchVal);
        })
        .map((res) => (
          <Option key={res.Company + " [" + res.Ticker + "]"}>
            {res.Company + " [" + res.Ticker + "]"}
          </Option>
        ))
    );
  }, [companies, customCohortCompanies, searchVal]);

  const peerGroupTableColumns = [
    {
      title: `Remove?`,
      dataIndex: "peerGroupRemove",
      key: "peerGroupRemove",
      align: "center",
      width: 20,
    },
    {
      title: `Ticker`,
      dataIndex: "peerGroupTicker",
      key: "peerGroupTicker",
      align: "center",
      sorter: (a, b) => {
        return a.peerGroupTicker.localeCompare(b.peerGroupTicker);
      },
    },
    {
      title: `Company`,
      dataIndex: "peerGroupCompany",
      key: "peerGroupCompany",
      align: "center",
      sorter: (a, b) => {
        return a.peerGroupCompany.localeCompare(b.peerGroupCompany);
      },
    },
    {
      title: `${TSRperiod} TSR ending on ${monthSelected} ${yearSelected}`,
      dataIndex: "peerGroupTSR",
      key: "peerGroupTSR",
      align: "center",
      sorter: (a, b, sortOrder) => {
        const formatNum = (num, sortOrder) =>
          !isNaN(parseFloat(fromDollar(num)))
            ? fromDollar(num)
            : sortOrder === "ascend"
            ? Infinity
            : -Infinity;
        const aNum = formatNum(a.peerGroupTSR, sortOrder);
        const bNum = formatNum(b.peerGroupTSR, sortOrder);
        return aNum - bNum;
      },
    },
    {
      title: `Market Cap on ${monthSelected} ${yearSelected}`,
      dataIndex: "peerGroupMarketcap",
      key: "peerGroupMarketcap",
      align: "center",
      sorter: (a, b, sortOrder) => {
        const formatNum = (num, sortOrder) =>
          !isNaN(parseFloat(fromDollar(num)))
            ? parseFloat(fromDollar(num))
            : sortOrder === "ascend"
            ? Infinity
            : -Infinity;
        console.log(a);
        const aNum = formatNum(a.peerGroupMarketcap, sortOrder);
        const bNum = formatNum(b.peerGroupMarketcap, sortOrder);
        return aNum - bNum;
      },
    },
  ];
  const peerGroupTickers = useMemo(() => {
    // Include company in its own peer group if it is not already there and "Include selected"
    if (includeCompanyInPeerGroup === "Include") {
      const peerGroupList = customCohortCompanies?.includes(companyInfo.Ticker)
        ? customCohortCompanies
        : [companyInfo.Ticker, ...customCohortCompanies];
      return peerGroupList;
    }
    if (includeCompanyInPeerGroup === "Exclude") {
      const peerGroupList = !customCohortCompanies?.includes(companyInfo.Ticker)
        ? customCohortCompanies
        : customCohortCompanies.filter((item) => item !== companyInfo.Ticker);
      return peerGroupList;
    }
    console.warn(
      "includeCompanyInPeerGroup must be set to 'Include' or 'Exclude'"
    );
    return [];
  }, [companyInfo, customCohortCompanies, includeCompanyInPeerGroup]);

  const peerGroupTableData = useMemo(() => {
    return peerGroupTickers.map((ticker, index) => {
      return {
        key: index,
        peerGroupRemove: (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              cursor:
                allowRemoveCompany || ticker !== companyInfo?.Ticker
                  ? "pointer"
                  : "",
              padding: 5,
            }}
            onClick={() =>
              ticker !== companyInfo?.Ticker
                ? setRemovedCompanies((prevRemovedCompanies) => [
                    ...prevRemovedCompanies,
                    ticker,
                  ])
                : setIncludeCompanyInPeerGroup("Exclude")
            }
          >
            {(allowRemoveCompany || ticker !== companyInfo?.Ticker) && (
              <img src={removeSVG} alt="remove" width={11} />
            )}
          </div>
        ),
        peerGroupTicker: ticker || emdash,
        peerGroupCompany: companies?.[ticker]
          ? companies?.[ticker]?.Company || emdash
          : emdash,
        peerGroupMarketcap: !isNaN(parseFloat(peerGroupMarketcaps[ticker]))
          ? formatMeasure(peerGroupMarketcaps[ticker], "dollar")
          : emdash,
        peerGroupTSR: peerGroupTSRs.some(
          (item) =>
            item.ticker === ticker && !isNaN(parseFloat(item.peerCompanyTSR))
        )
          ? "$" +
            parseFloat(
              peerGroupTSRs.find((item) => item.ticker === ticker)
                .peerCompanyTSR
            ).toFixed(2)
          : emdash,
      };
    });
  }, [
    companies,
    companyInfo,
    peerGroupTSRs,
    peerGroupMarketcaps,
    peerGroupTickers,
    allowRemoveCompany,
    setIncludeCompanyInPeerGroup,
  ]);

  const scrollToRef = (ref) => {
    ref.current.scrollIntoView({ behavior: "smooth" });
  };
  const [highlightSection, setHighlightSection] = useState(false);
  useEffect(() => {
    if (scrollToPeerGroup) {
      scrollToRef(peerGroupTableRef);
      setHighlightSection(true);
      setShowPeerGroup(true);
      setScrollToPeerGroup(false);
    }
    setTimeout(function () {
      setHighlightSection(false);
    }, 1800);
    return;
  }, [
    peerGroupTableRef,
    showPeerGroup,
    scrollToPeerGroup,
    setScrollToPeerGroup,
  ]);

  return (
    <div
      style={{
        marginBottom: 0,
        backgroundColor: highlightSection ? "lightblue" : "",
        transition: "background-color 1000ms linear",
      }}
      ref={peerGroupTableRef}
    >
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Button
          onClick={() => setShowPeerGroup(!showPeerGroup)}
          style={{ marginBottom: 20, fontWeight: "bold" }}
        >
          {!showPeerGroup ? "Show" : "Hide"} Comparison Group
          {/* {(cohortTableSelector.includes("Peer Group")
            ? "Peer Group"
            : "Cohort") + (showPeerGroup ? "" : "?")} */}
        </Button>
      </div>

      {showPeerGroup && (
        <div>
          <div
            className="industry (company_filter)"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: 10,
              marginBottom: 0,
            }}
          >
            <div
              className="title"
              style={{
                width: cohortTableSelector.includes("Peer Group") ? 350 : 300,
                display: "flex",
                justifyContent: "center",
              }}
            >
              <h5>
                Add{" "}
                {cohortTableSelector.includes("Peer Group")
                  ? "Peer Group"
                  : "Cohort"}{" "}
                Companies:
              </h5>
            </div>
            <Select
              mode="multiple"
              style={{ width: "100%", marginBottom: 10 }}
              allowClear
              value={addedCohortCompanies}
              filterOption={false}
              onSearch={(val) => {
                setSearchVal(val);
              }}
              onBlur={() => {
                setSearchVal("");
              }}
              loading={companies?.length < 8}
              onChange={(value) => {
                setSearchVal("");
                setAddedCohortCompanies(value);
              }}
            >
              {companiesChildren}
            </Select>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexWrap: "wrap",
            }}
          >
            <Table
              style={{ width: "95%" }}
              dataSource={peerGroupTableData}
              columns={peerGroupTableColumns}
              // pagination={{ hideOnSinglePage: true }}
              bordered={true}
            />
          </div>
        </div>
      )}
    </div>
  );
}
