export const execPositionMap = [
  {
    term: "chief executive",
    abbreviation: "ceo",
    formatted: "Chief Executive Officer",
  },
  {
    term: "executive officer",
    abbreviation: "ceo",
    formatted: "Chief Executive Officer",
  },
  {
    term: "financial",
    abbreviation: "cfo",
    formatted: "Chief Financial Officer",
  },
  {
    term: "finance",
    abbreviation: "cfo",
    formatted: "Chief Financial Officer",
  },
  {
    term: "marketing",
    abbreviation: "cmo",
    formatted: "Chief Marketing Officer",
  },
  {
    term: "operations",
    abbreviation: "coo",
    formatted: "Chief Operations Officer",
  },
  {
    term: "operating",
    abbreviation: "coo",
    formatted: "Chief Operating Officer",
  },
  {
    term: "human resources",
    abbreviation: "chro",
    formatted: "Chief Human Resources Officer",
  },
  {
    term: "hr",
    abbreviation: "chro",
    formatted: "Chief Human Resources Officer",
  },
  {
    term: "compliance",
    abbreviation: "cco",
    formatted: "Chief Compliance Officer",
  },
  {
    term: "strategy",
    abbreviation: "cso",
    formatted: "Chief Strategy Officer",
  },
  {
    term: "strategic",
    abbreviation: "cso",
    formatted: "Chief Strategic Officer",
  },
  {
    term: "administrative",
    abbreviation: "cao",
    formatted: "Chief Administrative Officer",
  },
  {
    term: "administration",
    abbreviation: "cao",
    formatted: "Chief Administration Officer",
  },
  { term: "legal", abbreviation: "clo", formatted: "Chief Legal Officer" },
  { term: "gc", abbreviation: "clo", formatted: "Chief Legal Officer" },
  { term: "chief counsel", abbreviation: "clo", formatted: "Chief Counsel" },
  {
    term: "General counsel",
    abbreviation: "clo",
    formatted: "General Counsel",
  },
  { term: "revenue", abbreviation: "cro", formatted: "Chief Revenue Officer" },
  {
    term: "technology",
    abbreviation: "cto",
    formatted: "Chief Technology Officer",
  },
  {
    term: "investment",
    abbreviation: "cio",
    formatted: "Chief Investment Officer",
  },
  {
    term: "business",
    abbreviation: "cbo",
    formatted: "Chief Business Officer",
  },
  { term: "people", abbreviation: "cpo", formatted: "Chief People Officer" },
  {
    term: "development",
    abbreviation: "cdo",
    formatted: "Chief Development Officer",
  },
  { term: "medical", abbreviation: "cmeo", formatted: "Chief Medical Officer" },
  {
    term: "accounting",
    abbreviation: "caco",
    formatted: "Chief Accounting Officer",
  },
  {
    term: "scientific",
    abbreviation: "csco",
    formatted: "Chief Scientific Officer",
  },
  {
    term: "information",
    abbreviation: "cino",
    formatted: "Chief Information Officer",
  },
  { term: "risk", abbreviation: "crio", formatted: "Chief Risk Officer" },
  { term: "credit", abbreviation: "ccro", formatted: "Chief Credit Officer" },
  { term: "product", abbreviation: "cpro", formatted: "Chief Product Officer" },
  { term: "lending", abbreviation: "cleo", formatted: "Chief Lending Officer" },
  {
    term: "procurement",
    abbreviation: "cproc",
    formatted: "Chief Procurement Officer",
  },
  { term: "digital", abbreviation: "cdio", formatted: "Chief Digital Officer" },
  { term: "sales", abbreviation: "csao", formatted: "Chief Sales Officer" },
  {
    term: "commercial",
    abbreviation: "ccoo",
    formatted: "Chief Commercial Officer",
  },
  {
    term: "supply chain",
    abbreviation: "cscho",
    formatted: "Chief Supply Chain Officer",
  },
  {
    term: "division",
    abbreviation: "divisionPresident",
    formatted: "Division President",
  },
  {
    term: "divisional",
    abbreviation: "divisionPresident",
    formatted: "Division President",
  },
  {
    term: "vice president",
    abbreviation: "vicePresident",
    formatted: "Vice President",
  },
  {
    term: "svp",
    abbreviation: "vicePresident",
    formatted: "Vice President",
  },
  {
    term: "evp",
    abbreviation: "vicePresident",
    formatted: "Vice President",
  },
  { term: "president", abbreviation: "president", formatted: "President" },
  { term: "secretary", abbreviation: "secretary", formatted: "Secretary" },
  { term: "director", abbreviation: "director", formatted: "Director" },
  { term: "chairman", abbreviation: "chairman", formatted: "Chairman" },
];

export function getExecPosition(executive, year) {
  const getClosestYear = (executive, year) => {
    const yearsAvailable = [];
    if (!executive?.["position"]) return ["N/A"];
    for (const [key, value] of Object.entries(executive?.["position"])) {
      if (value?.length && !yearsAvailable.includes(key)) {
        yearsAvailable.push(key);
      }
    }
    // Can return years if only one option or if a year available === current query
    if (yearsAvailable.length === 1) return yearsAvailable[0];
    if (yearsAvailable.includes(year)) return year;

    // Otherwise, find the most relevent year:
    let currentBest = {};
    for (const dataYear of yearsAvailable) {
      let score = dataYear - year;
      if (
        !currentBest?.score ||
        (score > currentBest?.score && score <= 1) ||
        (score < currentBest?.score && score >= 1)
      ) {
        currentBest = { score, year: dataYear };
      }
    }
    return currentBest?.year;
  };
  let tempPositions = [];
  let closestYear = getClosestYear(executive, year);
  let posFound = false;

  if (!executive?.["position"]) return ["N/A"];
  for (const [key, value] of Object.entries(executive?.["position"])) {
    if (key === closestYear) {
      posFound = true;
      for (const position of value) {
        tempPositions.push(position);
      }
    }
  }
  if (!posFound) tempPositions.push("N/A");

  return tempPositions;
}

export function getExecPositionGroup(position) {
  if (!position || position === "N/A") return "N/A";
  position = position.toLowerCase().replace("co-", "");
  for (const map of execPositionMap) {
    if (
      position === map.abbreviation.toLowerCase() ||
      position
        .split(" ")
        .some((item) => item === map.abbreviation.toLowerCase()) ||
      position.includes(map.term.toLowerCase())
    ) {
      return map;
    }
  }
  return "N/A";
}

export function getExecPositionScore(position) {
  if (!position || position === "N/A") return Infinity;
  if (execPositionMap.indexOf(position) > -1) {
    return execPositionMap.indexOf(position);
  }
  return Infinity;
}

export function posGroupMatch(
  year,
  exec,
  execPositionGroupArray,
  includeFormer = false
) {
  year = year?.toString();
  const getBestPosYear = (exec) => {
    let bestYearMatch = 0;
    for (let posYear of Object.keys(exec.position)) {
      if (parseInt(posYear) === parseInt(year)) {
        bestYearMatch = posYear;
      }
      if (
        !bestYearMatch &&
        parseInt(posYear) > parseInt(year) &&
        parseInt(posYear) > parseInt(bestYearMatch) &&
        !Object.keys(exec.position).includes(year)
      ) {
        bestYearMatch = posYear;
      }
    }
    return bestYearMatch;
  };

  //TODO: remove:
  // (posGroup.abbreviation === "chair" &&
  // (pos.toLowerCase().includes("chair") ||
  //   pos.toLowerCase().includes("chairman"))) ||
  // .. if it is causing issues
  // ..was added to allow for chairs in pay ratio document, may not be useful here

  const bestYearMatch = getBestPosYear(exec);
  return [bestYearMatch].some((year) =>
    // getExecPosition(exec, year)
    exec?.position?.[year]?.some((pos) =>
      execPositionGroupArray.some(
        (posGroup) =>
          (includeFormer || !pos.toLowerCase().includes("former")) &&
          (pos.toLowerCase().includes(posGroup.abbreviation) ||
            (posGroup.abbreviation === "chair" &&
              (pos.toLowerCase().includes("chair") ||
                pos.toLowerCase().includes("chairman"))) ||
            (posGroup.abbreviation === "divisionPresident" &&
              (pos.toLowerCase().includes("division") ||
                pos.toLowerCase().includes("divisional"))) ||
            (posGroup.abbreviation === "ceo" &&
              (pos.toLowerCase().includes("chief executive") ||
                pos.toLowerCase().includes("executive officer"))) ||
            (posGroup.abbreviation === "cfo" &&
              (pos.toLowerCase().includes("financial") ||
                pos.toLowerCase().includes("finance"))) ||
            (posGroup.abbreviation === "cmo" &&
              pos.toLowerCase().includes("marketing")) ||
            (posGroup.abbreviation === "coo" &&
              (pos.toLowerCase().includes("operations") ||
                pos.toLowerCase().includes("operating"))) ||
            (posGroup.abbreviation === "chro" &&
              (pos.toLowerCase().includes("human resources") ||
                pos.includes("HR"))) ||
            (posGroup.abbreviation === "cco" &&
              pos.toLowerCase().includes("compliance")) ||
            (posGroup.abbreviation === "cso" &&
              (pos.toLowerCase().includes("strategy") ||
                pos.toLowerCase().includes("strategic"))) ||
            (posGroup.abbreviation === "cao" &&
              (pos.toLowerCase().includes("administrative") ||
                pos.toLowerCase().includes("administration"))) ||
            (posGroup.abbreviation === "clo" &&
              (pos.toLowerCase().includes("legal") ||
                pos.toLowerCase().includes("gc") ||
                pos.toLowerCase().includes("chief counsel") ||
                pos.toLowerCase().includes("general counsel"))) ||
            (posGroup.abbreviation === "cro" &&
              pos.toLowerCase().includes("revenue")) ||
            (posGroup.abbreviation === "cto" &&
              pos.toLowerCase().includes("technology")) ||
            (posGroup.abbreviation === "cio" &&
              pos.toLowerCase().includes("investment")) ||
            (posGroup.abbreviation === "cbo" &&
              pos.toLowerCase().includes("business")) ||
            (posGroup.abbreviation === "cpo" &&
              pos.toLowerCase().includes("people")) ||
            (posGroup.abbreviation === "cdo" &&
              pos.toLowerCase().includes("development")) ||
            (posGroup.abbreviation === "cmeo" &&
              pos.toLowerCase().includes("medical")) ||
            (posGroup.abbreviation === "caco" &&
              pos.toLowerCase().includes("accounting")) ||
            (posGroup.abbreviation === "csco" &&
              pos.toLowerCase().includes("scientific")) ||
            (posGroup.abbreviation === "ccoo" &&
              pos.toLowerCase().includes("compliance")) ||
            (posGroup.abbreviation === "cino" &&
              pos.toLowerCase().includes("information")) ||
            (posGroup.abbreviation === "crio" &&
              pos.toLowerCase().includes("risk")) ||
            (posGroup.abbreviation === "ccro" &&
              pos.toLowerCase().includes("credit")) ||
            (posGroup.abbreviation === "cpro" &&
              pos.toLowerCase().includes("product")) ||
            (posGroup.abbreviation === "cleo" &&
              pos.toLowerCase().includes("lending")) ||
            (posGroup.abbreviation === "cproc" &&
              pos.toLowerCase().includes("procurement")) ||
            (posGroup.abbreviation === "cdio" &&
              pos.toLowerCase().includes("digital")) ||
            (posGroup.abbreviation === "csao" &&
              pos.toLowerCase().includes("sales")) ||
            pos.toLowerCase().includes(posGroup.term))
      )
    )
  );
}

export function posGroupMatchFromString(
  posString,
  execPositionGroupArray,
  includeFormer = false
) {
  const positions = posString.split(",");
  return positions?.some((pos) =>
    execPositionGroupArray.some(
      (posGroup) =>
        (includeFormer || !pos.toLowerCase().includes("former")) &&
        (pos.toLowerCase().includes(posGroup.abbreviation) ||
          (posGroup.abbreviation === "ceo" &&
            (pos.toLowerCase().includes("chief executive") ||
              pos.toLowerCase().includes("executive officer"))) ||
          (posGroup.abbreviation === "divisionPresident" &&
            (pos.toLowerCase().includes("division") ||
              pos.toLowerCase().includes("divisional"))) ||
          (posGroup.abbreviation === "cfo" &&
            (pos.toLowerCase().includes("financial") ||
              pos.toLowerCase().includes("finance"))) ||
          (posGroup.abbreviation === "cmo" &&
            pos.toLowerCase().includes("marketing")) ||
          (posGroup.abbreviation === "coo" &&
            (pos.toLowerCase().includes("operations") ||
              pos.toLowerCase().includes("operating"))) ||
          (posGroup.abbreviation === "chro" &&
            (pos.toLowerCase().includes("human resources") ||
              pos.includes("HR"))) ||
          (posGroup.abbreviation === "cco" &&
            pos.toLowerCase().includes("compliance")) ||
          (posGroup.abbreviation === "cso" &&
            (pos.toLowerCase().includes("strategy") ||
              pos.toLowerCase().includes("strategic"))) ||
          (posGroup.abbreviation === "cao" &&
            (pos.toLowerCase().includes("administrative") ||
              pos.toLowerCase().includes("administration"))) ||
          (posGroup.abbreviation === "clo" &&
            (pos.toLowerCase().includes("legal") ||
              pos.toLowerCase().includes("gc") ||
              pos.toLowerCase().includes("chief counsel") ||
              pos.toLowerCase().includes("general counsel"))) ||
          (posGroup.abbreviation === "cro" &&
            pos.toLowerCase().includes("revenue")) ||
          (posGroup.abbreviation === "cto" &&
            pos.toLowerCase().includes("technology")) ||
          (posGroup.abbreviation === "cio" &&
            pos.toLowerCase().includes("investment")) ||
          (posGroup.abbreviation === "cbo" &&
            pos.toLowerCase().includes("business")) ||
          (posGroup.abbreviation === "cpo" &&
            pos.toLowerCase().includes("people")) ||
          (posGroup.abbreviation === "cdo" &&
            pos.toLowerCase().includes("development")) ||
          (posGroup.abbreviation === "cmeo" &&
            pos.toLowerCase().includes("medical")) ||
          (posGroup.abbreviation === "caco" &&
            pos.toLowerCase().includes("accounting")) ||
          (posGroup.abbreviation === "csco" &&
            pos.toLowerCase().includes("scientific")) ||
          (posGroup.abbreviation === "ccoo" &&
            pos.toLowerCase().includes("compliance")) ||
          (posGroup.abbreviation === "cino" &&
            pos.toLowerCase().includes("information")) ||
          (posGroup.abbreviation === "crio" &&
            pos.toLowerCase().includes("risk")) ||
          (posGroup.abbreviation === "ccro" &&
            pos.toLowerCase().includes("credit")) ||
          (posGroup.abbreviation === "cpro" &&
            pos.toLowerCase().includes("product")) ||
          (posGroup.abbreviation === "cleo" &&
            pos.toLowerCase().includes("lending")) ||
          (posGroup.abbreviation === "cproc" &&
            pos.toLowerCase().includes("procurement")) ||
          (posGroup.abbreviation === "cdio" &&
            pos.toLowerCase().includes("digital")) ||
          (posGroup.abbreviation === "csao" &&
            pos.toLowerCase().includes("sales")) ||
          pos.toLowerCase().includes(posGroup.term))
    )
  );
}
