import question from "../../assets/question-mark.svg";
import NAICSmedianCategories from "../../assets/json/NAICSmedianCategories.json";
import { Radio, Tooltip } from "antd";
import { Link } from "react-router-dom";
import { CalculatorFAQTopics } from "../../assets/faqTopicsCalculators";

export const MAX_YEAR = "2024";
export const DEFAULT_YEAR = "2023";
export const MIN_YEAR = 2019;

export const DOMAIN = "https://pay-gap-api.vercel.app/api";
// export const DOMAIN = "http://localhost:5000/api";

// "http://localhost:3000/api/csuitecomp" // local backend
// https://csuite-api.vercel.app/api/csuitecomp // old backend
// "https://api.csuitecomp.com/api/csuitecomp" // new backend 9/23/22

// window.location.hostname works for "localhost" and IP address (useful for mobile testing)
export const CSUITE_DOMAIN =
  process.env.NODE_ENV === "development"
    ? `http://${window.location.hostname}:3000/api/csuitecomp`
    : "https://api2.csuitecomp.com/api/csuitecomp";

export const CSUITE_DOMAIN_3 =
  process.env.NODE_ENV === "development"
    ? `http://${window.location.hostname}:3000/api/csuitecomp`
    : "https://api3.csuitecomp.com/api/csuitecomp";
//Outdated backend routes:
//// export const CSUITE_DOMAIN = "https://csuite-api.vercel.app/api/csuitecomp";
//// export const CSUITE_DOMAIN = "https://8783-2601-1c2-1402-1e46-1c9b-bccf-f2c9-1d03.ngrok.io/api/csuitecomp";

// run "ScrapeAllExchanges/formatCsuiteData" if running into errors, data too large for git
export const CSUITE_PUBLIC_DOMAIN =
  process.env.NODE_ENV === "development"
    ? `http://${window.location.hostname}:3000`
    : "https://csuitecomp.com";

export const STRIPE_KEY_2022 =
  process.env.NODE_ENV === "development"
    ? "pk_test_51JHakrJLlwUlZhKEDGoeHEGubJJDygTxtjgqebTFGu5FMgLjzvqVBFcqD6WryRvOzDqBqTO8VXEuBiYc9zIriDd900HKtHOVoD"
    : "pk_live_51JHakrJLlwUlZhKEjzsP07J8DpotRRKr5jBfdYsPTBXbnT0s6t26ECP4EZMdfopNwdkf7UzquHpDP5MDh13oG3tG00ebWe5XMB";

export const PLAN_NAME = "C-Suite Comp Data Analytics & Visualization Plan";

export const ETRADE_URL =
  "https://www.etrade.wallst.com/v1/stocks/snapshot/snapshot.asp?symbol=";
export const INDEED_URL = "https://www.indeed.com/jobs?q=";

export const PUBLIC_RECAPTCHA_SITE_KEY =
  "6LcwKdQdAAAAAMr-jW0r2VlTr4mD2GnGXV0pMds2";
export const PUBLIC_INVISIBLE_RECAPTCHA_SITE_KEY =
  "6LfzA9QdAAAAAMugg_9oGm-48ck01yXzullP6rIO";

export const STRIPE_KEY_OLD =
  "pk_test_51J652LK4HtbfzuPcNCs48gSPj80Oo5OmhCigUh55FDqaSDFlI2y3wQvGWWHknhCiUtaslzJJbiVij7zqvc5WyWby00HfTLkXd1";

export const ACCESS_TOKEN = "ACCESS_TOKEN";
export const USER_INFO = "USER_INFO";
export const KEY = "KEY";

export const EMAIL_REGEX =
  /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
export const PASSWORD_REGEX =
  /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,21}$/;

export const DISCLOSURE_REGEX = /[^a-zA-Z0-9 -.,$]/gm;
export const COMP_AI_REGEX = /[^a-zA-Z0-9 -.,$?]/gm;

export const GEO_DOMAIN = "https://geolocation-db.com/json/";

export const emdash = "–";

export const STATUS_CODE = {
  SUCCESS: 200,
  NOT_FOUND: 404,
  NOT_ACCEPTABLE: 406,
  SERVER_ERROR: 500,
  UNPROCESSABLE_ENTITY: 422,
};

export const MONTHS = [
  "Dec",
  "Nov",
  "Oct",
  "Sep",
  "Aug",
  "Jul",
  "Jun",
  "May",
  "Apr",
  "Mar",
  "Feb",
  "Jan",
];

export const FULL_MONTHS = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const defaultPeerGroupOptions = [
  "Analyst Peer Group",
  "Proxy Peer Group",
  "Proxy Peer Group (Compensation)",
  "Proxy Peer Group (Performance)",
  "Comp AI Proxy Peer Group",
  "Comp AI Proxy Peer Group (Compensation)",
  "Comp AI Proxy Peer Group (Performance)",
  "Sector Cohort",
  "Industry Cohort",
  "SIC Major Group (2)",
  "SIC Industry Group (3)",
  "SIC Industry (4)",
  "New Custom Peer Group",
];

export const CARD_OPTIONS = {
  iconStyle: "solid",
  style: {
    base: {
      iconColor: "#a8a8a8",
      color: "#000",
      // fontWeight: 500,
      lineHeight: "40px",
      borderRadius: "20px",
      backgroundColor: "#fff",
      fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
      fontSize: "16px",
      fontSmoothing: "antialiased",
      ":-webkit-autofill": { color: "#000" },
      "::placeholder": { color: "lightgray" },
    },
    invalid: {
      iconColor: "red",
      color: "red",
    },
  },
};

export const BACKGROUND_COLOR = [
  "rgba(255, 99, 132, 0.2)",
  "rgba(54, 162, 235, 0.2)",
  "rgba(255, 206, 86, 0.2)",
  "rgba(75, 192, 192, 0.2)",
  "rgba(153, 102, 255, 0.2)",
  "rgba(255, 159, 64, 0.2)",
];

export const BORDER_COLOR = [
  "rgba(255, 99, 132, 1)",
  "rgba(54, 162, 235, 1)",
  "rgba(255, 206, 86, 1)",
  "rgba(75, 192, 192, 1)",
  "rgba(153, 102, 255, 1)",
  "rgba(255, 159, 64, 1)",
];

export const BORDER_WIDTH = 1;

export const renderNetIncome = (netIncome) => {
  if (netIncome === "N/A") {
    return <span>N/A</span>;
  } else if (netIncome < 0) {
    return (
      <span className="text-danger">
        ({toDollar(Number(netIncome.toString().slice(1)))})
      </span>
    );
  } else {
    return <span className="text-success">{toDollar(netIncome)}</span>;
  }
};

export const getDatapoint = (companyInfo, yearNeeded, datapointName) => {
  /* Allows for getting most recent year */
  if (
    companyInfo?.[datapointName]?.[yearNeeded?.toString()]?.value ||
    companyInfo?.[datapointName]?.[yearNeeded?.toString()]?.value === 0 // revenue can be 0
  ) {
    return {
      year: yearNeeded,
      value: companyInfo?.[datapointName]?.[yearNeeded?.toString()]?.value,
      calculated: companyInfo?.[datapointName]?.[yearNeeded?.toString()]
        ?.calculated
        ? true
        : false,
    };
  }
  // }
  return { year: "", value: "N/A" };
};

export const getMeasure = (companyInfo, year, measureName) => {
  /* Allows for getting most recent year */
  if (
    companyInfo?.Measures?.[measureName]?.[year?.toString()] ||
    companyInfo?.Measures?.[measureName]?.[year?.toString()] === 0 // revenue can be 0
  ) {
    return {
      year: year,
      value: companyInfo?.Measures?.[measureName]?.[year?.toString()],
    };
  }
  // }
  return { year: "", value: null };
};

export const getMostRecentMeasure = (
  companyInfo,
  yearsAvailable,
  measureName
) => {
  /* Allows for getting most recent year */
  for (const year of yearsAvailable) {
    if (
      companyInfo?.Measures?.[measureName]?.[year?.toString()] ||
      companyInfo?.Measures?.[measureName]?.[year?.toString()] === 0 // revenue can be 0
    ) {
      return {
        year: year,
        value: companyInfo?.Measures?.[measureName]?.[year?.toString()],
      };
    }
  }
  return { year: "", value: null };
};
export const toDollar = (input, decimalPlaces = 0) => {
  try {
    return input
      ? parseFloat(input).toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
          maximumFractionDigits: decimalPlaces,
          minimumFractionDigits: decimalPlaces,
        })
      : null;
  } catch {
    return null;
  }
};

export const payRatioCalculatedMessage = (companyInfo, year) => {
  // const companySector =
  //   NAICSmedianCategories[
  //     companyInfo?.NAICS?.slice(0, 2)
  //   ].description.toLowerCase();
  return (
    <span>
      <sup>† </sup>This pay ratio was calculated from {companyInfo.Ticker}'s CEO
      total compensation and the median pay in {companyInfo.Ticker}'s sector in{" "}
      {year}
    </span>
  );
};

export const fromDollar = (input) => {
  if (!input) return null;
  if (
    ["N/A", "N", "P"].some((excludeValue) =>
      input.toString().includes(excludeValue)
    )
  )
    return input;
  return input ? parseFloat(input.toString().replace(/[^0-9.-]+/g, "")) : null;
};

export const customParseFloat = (number) => {
  if (isNaN(parseFloat(number)) === false) {
    let toFixedLength = 0;
    let str = String(number);
    ["."].forEach((seperator) => {
      let arr = str.split(seperator);
      if (arr.length === 2) {
        toFixedLength = arr[1].length <= 2 ? arr[1].length : 2;
      }
    });
    return parseFloat(str).toFixed(toFixedLength);
  }
  return number;
};

export const formatMeasure = (value, dataType) => {
  if (!value || !dataType) return undefined;
  switch (dataType) {
    case "dollar":
      return toDollar(value);
    case "percent":
      return !isNaN(parseFloat(value))
        ? `${parseFloat(value).toFixed(2)}%`
        : value;
    case "ratio":
      if (isNaN(parseFloat(value))) {
        return value;
      }
      return Math.abs(parseFloat(value)) < 1
        ? `${parseFloat(value).toPrecision(2)}` // formerly 3, but was stated it should be to 2
        : Math.abs(parseFloat(value)) < 10
        ? `${parseFloat(value).toFixed(2)}`
        : parseInt(value);
    case "fixed":
      return !isNaN(parseFloat(value))
        ? `${parseFloat(value).toFixed(2)}`
        : value;
    case "int":
      return !isNaN(parseFloat(value)) ? `${value.toLocaleString()}` : value;
    default:
      console.log("Unknown dataType provided to format measure");
      return value;
  }
};

export const abbreviateAndFormatMeasure = (value, dataType) => {
  const abbreviateValue = (value) => {
    if (Math.abs(value) >= 1000000000) {
      return value / 1000000000 + "B";
    } else if (Math.abs(value) >= 1000000) {
      return value / 1000000 + "M";
    } else if (Math.abs(value) >= 10000) {
      return value / 1000 + "K";
    } else {
      return value;
    }
  };

  if (dataType === "ratio") {
    if (isNaN(parseFloat(value))) {
      return value;
    }
    return Math.abs(parseFloat(value)) < 1
      ? `${parseFloat(value).toPrecision(2)}`
      : Math.abs(parseFloat(value)) < 10
      ? `${parseFloat(value).toFixed(2)}`
      : parseInt(value);
  }

  let formattedValue = abbreviateValue(value);
  if (dataType === "percent") {
    formattedValue = formattedValue + "%";
  }
  if (dataType === "dollar") {
    formattedValue = "$" + formattedValue.toLocaleString();
  }
  return formattedValue;
};

export const indexComparisonMessage = (
  <div>
    <p>Compare TSR to an index (e.g. Russell 3000, S&P 500).</p>
    <p>
      {" "}
      If we are missing an index that you would like to benchmark to, please{" "}
      <Link to="/contact" style={{ color: "var(--light-blue)" }}>
        contact us
      </Link>{" "}
      so we can add it.
    </p>
  </div>
);

export const dataKeyMap = {
  salary: { key: "salary", title: "Base Salary", order: 1 },
  bonus: { key: "bonus", title: "Bonus Payout", order: 2 },
  RSUs: { key: "RSUs", title: "Restricted Stock Unit Awards", order: 3 },
  PSUs: { key: "PSUs", title: "Performance Stock Unit Awards", order: 4 },
  SARaward: { key: "SARaward", title: "SAR Awards", order: 5 },
  stockAward: { key: "stockAward", title: "Stock Awards", order: 6 },
  optionAward: { key: "optionAward", title: "Option Awards", order: 7 },
  nonEquity: {
    key: "nonEquity",
    title: "Non-Equity Incentive Plan Compensation",
    order: 8,
  },
  pension: {
    key: "pension",
    title:
      "Change in Pension Value & Nonqualified Deferred Compensation Earnings",
    order: 9,
  },
  other: { key: "other", title: "All Other Compensation", order: 10 },
  total: { key: "total", title: "Total Compensation", order: 11 },
  originalTotal: { key: "originalTotal", title: "Original Total", order: 12 },
  manuallyEdited: {
    key: "manuallyEdited",
    title: "Manually Edited",
    order: 13,
  },
  sumOfCompensationComponents: {
    key: "sumOfCompensationComponents",
    title: "Sum Of Compensation Components",
    order: 14,
  },
};

export const boardPayMap = {
  year: ["Report Year"],
  name: ["Name", "Director", "Non Employee Director"],
  count: ["Other Board Appearances"],
  feesEarnedOrPaid: ["Fees Earned or Paid in Cash"],
  bonus: ["Bonus Payout"],
  optionAwards: ["Option Awards"],
  stockAwards: [
    "Stock Awards",
    "Restricted Stock",
    "Stock Units",
    "Unit Awards",
  ],
  RSUs: ["RSUs", "RSU", "Restricted Stock Units", "Restricted Stock Awards"],
  PSUs: ["PSUs", "PSU", "Performance Stock Units", "Performance Stock Units"],
  nonEquity: [
    "Non-equity Incentive Plan Compensation",
    "Nonqualified Deferred Compensation Earnings",
  ],
  pension: [
    "Change in Pension Value",
    "Change in Pension Value and Nonqualified Deferred Compensation Earnings",
  ],
  other: ["All Other Compensation", "Other Compensation"],
  total: ["Total", "Total Compensation"],
};

export const getExecCompensationBreakdown = (executivesList, year) => {
  const compensationBreakdown = {};
  Object.keys(dataKeyMap).forEach((key) => {
    compensationBreakdown[key] = [];
  });
  executivesList.forEach((exec) => {
    if (!exec?.compensation?.[year]) return;
    for (const compKey of Object.keys(compensationBreakdown)) {
      compensationBreakdown[compKey].push(
        exec.compensation[year]?.[compKey] === "–"
          ? 0
          : exec.compensation[year]?.[compKey] || 0
      );
    }
  });
  for (const [compKey, compBreakdown] of Object.entries(
    compensationBreakdown
  )) {
    compensationBreakdown[compKey] = compBreakdown.sort((a, b) => b - a);
  }
  return compensationBreakdown;
};

export const getPercentile = (sortedArray, percentile) => {
  const pos = (sortedArray.length - 1) * percentile;
  const base = Math.floor(pos);
  const rest = pos - base;
  if (sortedArray[base + 1] !== undefined) {
    return (
      sortedArray[base] + rest * (sortedArray[base + 1] - sortedArray[base])
    );
  } else {
    return sortedArray[base];
  }
};

export const getPercentileFromValue = (array, n, debugMeasure = "") => {
  array.sort((a, b) => a - b);
  //if only 1 value, let it be 100:
  if (array.length === 1) return 100;

  var L = 0;
  var S = 0;
  var N = array.length - 1;

  for (var i = 1; i <= array.length; i++) {
    if (array[i] <= n) {
      L += 1;
    } else if (array[i] === n) {
      S += 1;
    } else {
    }
  }
  var pct = parseFloat((100 * (L + 0.5 * S)) / N);
  return pct;
};

export const isFirstYearOfComp = (executive, year) => {
  const compensationData = executive?.compensation;
  return (
    compensationData &&
    compensationData[year]?.total > 0 &&
    !Object.keys(compensationData).some((compYear) => {
      return (
        compensationData[compYear]?.total &&
        compensationData[compYear]?.total > 0 &&
        parseInt(compYear) < year
      );
    })
  );
};

export const dataHasDiscrepancy = (executive, year) => {
  const compensationData = executive?.compensation;
  return (
    compensationData &&
    compensationData[year]?.total >= 0 &&
    compensationData[year]?.sumOfCompensationComponents &&
    compensationData[year]?.sumOfCompensationComponents > 0
  );
};

export const getAllDefinedValues = (data, value) => {
  // Filter out undefined values and $0 comps
  return data
    .filter(
      (item) =>
        item?.[value] &&
        (value !== "compensation" ||
          (value === "compensation" && item?.[value] !== 0))
    )

    .map((item) => item[value]);
};

export const hasHoverText = (element) => {
  return (
    <span
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        // marginLeft: 25,
      }}
    >
      {element}
      &nbsp;
      <img
        src={question}
        alt="question_mark"
        style={{
          width: 25,
          paddingBottom: 2,
          paddingRight: 5,
          filter: "invert(0.5) saturate(100) hue-rotate(0deg)",
        }}
      />
    </span>
  );
};

export const RadioSelector = ({
  options,
  state,
  setState,
  heading = "",
  style = {},
  hoverText = "",
  optionsHoverTextMap = {},
}) => {
  if (!options) return;
  return (
    <div style={{ width: "100%", display: "flex", ...style }}>
      {heading && hoverText ? (
        <Tooltip title={hoverText}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <h3 style={{ fontSize: 20, marginRight: 10, marginBottom: 0 }}>
              {hasHoverText(
                <span>
                  {heading.split(" ").map((head, index) => (
                    <span key={index}>{head}&nbsp;</span>
                  ))}
                </span>
              )}
            </h3>
          </div>
        </Tooltip>
      ) : heading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <h3 style={{ fontSize: 20, marginRight: 10, marginBottom: 0 }}>
            {heading.split(" ").map((head, index) => (
              <span key={index}>{head}&nbsp;</span>
            ))}
          </h3>
        </div>
      ) : (
        <div></div>
      )}
      <Radio.Group
        size="medium"
        onChange={(e) => {
          setState(e.target.value);
        }}
        value={state}
      >
        {options.map((option) => (
          <Radio.Button
            value={option}
            key={option}
            className="custom-radio-button"
            style={{ margin: 5 }}
          >
            {optionsHoverTextMap?.[option] ? (
              <Tooltip
                title={optionsHoverTextMap?.[option]}
                overlayClassName="white-link"
              >
                <div>{option}</div>
              </Tooltip>
            ) : (
              option
            )}
          </Radio.Button>
        ))}
      </Radio.Group>
    </div>
  );
};

export function addSpaceBetweenCapitalizedWords(str) {
  return str.replace(/([a-z])([A-Z])/g, "$1 $2");
}
export function toTitleCase(str) {
  return str.replace(
    /\w\S*/g,
    (text) => text.charAt(0).toUpperCase() + text.substring(1).toLowerCase()
  );
}

export const TSRperiodMessage = ({
  TSRperiod,
  yearSelected,
  monthSelected,
  includeHoverText = false,
}) => {
  // TSR periods go 1-10 years, should never exceed twenty
  const textNumber = [
    "zero",
    "one",
    "two",
    "three",
    "four",
    "five",
    "six",
    "seven",
    "eight",
    "nine",
    "ten",
    "eleven",
    "twelve",
    "thirteen",
    "fourteen",
    "fifteen",
    "sixteen",
    "seventeen",
    "eighteen",
    "nineteen",
    "twenty",
  ];
  if (!TSRperiod || !yearSelected || !monthSelected) {
    console.log(
      `missing [${!TSRperiod ? "TSRperiod, " : ""}${
        !yearSelected ? "yearSelected, " : ""
      }
      ${!monthSelected ? "monthSelected" : ""}] for TSRperiodMessage`
    );
    return <p></p>;
  }
  const date = new Date(
    yearSelected,
    [...MONTHS].reverse().indexOf(monthSelected) + 1,
    0
  );

  //Old method for date: (displays date like Octber 31, 2022)
  // date
  // .toDateString()
  // .slice(4)
  // .split(" ")
  // .toSpliced(2, 0, ",")
  // .join(" ")
  // .replace(" ,", ",")

  const defaultMessage = (
    <p>
      This table reports TSRs throughout a{" "}
      {textNumber?.[TSRperiod.split("yr")[0]]}-year holding period ending on the
      last trading day in {FULL_MONTHS[date.getMonth()]} {date.getFullYear()}.
    </p>
  );
  return (
    <>
      {includeHoverText ? (
        <Tooltip
          title={CalculatorFAQTopics.tsr_table.short_desc}
          overlayClassName="white-link"
        >
          <div style={{ display: "inline-block" }}>{defaultMessage}</div>
        </Tooltip>
      ) : (
        <>{defaultMessage}</>
      )}
    </>
  );
};

export function levenshteinDistance(str1 = "", str2 = "") {
  str1 = str1.toLowerCase();
  str2 = str2.toLowerCase();
  const track = Array(str2.length + 1)
    .fill(null)
    .map(() => Array(str1.length + 1).fill(null));
  for (let i = 0; i <= str1.length; i += 1) {
    track[0][i] = i;
  }
  for (let j = 0; j <= str2.length; j += 1) {
    track[j][0] = j;
  }
  for (let j = 1; j <= str2.length; j += 1) {
    for (let i = 1; i <= str1.length; i += 1) {
      const indicator = str1[i - 1] === str2[j - 1] ? 0 : 1;
      track[j][i] = Math.min(
        track[j][i - 1] + 1, // deletion
        track[j - 1][i] + 1, // insertion
        track[j - 1][i - 1] + indicator // substitution
        // ^^ substitution was overcorrecting "Mr. Ostrom" === "Mr. Tom" when set to "<= 3"
      );
    }
  }
  return track[str2.length][str1.length];
}

export function getSumOfComponents(compensation) {
  if (!compensation) return 0;
  let sumOfCompensationComponents = 0;
  for (const [key, val] of Object.entries(compensation)) {
    if (key !== "total" && key !== "rank") {
      sumOfCompensationComponents += val;
    }
  }
  return sumOfCompensationComponents;
}
