import React, { useState, useMemo, useEffect, useCallback } from "react";
import { Select, Switch, Table, Tooltip } from "antd";
import {
  toTitleCase,
  addSpaceBetweenCapitalizedWords,
  toDollar,
} from "../../utils/Constants/systemSetting";

import CustomInput from "./utils/CustomInput";
import FinalValue from "./utils/FinalValue";

import {
  getOptionsAssumptions,
  getTreasuryYields,
} from "../../redux/actions/CompanyAction";

import { OptionsFAQTopics } from "../../assets/faqTopicsOptions";
import {
  hasHoverText,
  RadioSelector,
} from "../../utils/Constants/systemSetting";
import { useSelector, useDispatch } from "react-redux";
import CustomTitleElement from "./utils/CustomTitleElement";
import CustomDatePicker from "./utils/CustomDatePicker";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import CohortSelector from "../ExecutiveOverview/CohortSection/CohortSelector";

dayjs.extend(customParseFormat);

export default function BlackScholesCalculator({
  companyInfo,
  lookbackVolatilityBlendedAverage,
  VolatilitySwitch,
  showVolatilityEditView,
}) {
  const dateFormat = "YYYY-MM-DD";
  const dispatch = useDispatch();
  const { optionsAssumptions, treasuryYields } = useSelector(
    (state) => state.CompanyReducer
  );
  const { companies } = useSelector((state) => state.CompaniesReducer);

  const [cohortTableTickers, setCohortTableTickers] = useState([]);
  const [cohortSelected, setCohortSelected] = useState("None");

  useEffect(() => {
    const token = localStorage.getItem("ACCESS_TOKEN");
    const ticker = companyInfo?.Ticker;

    if (ticker && !cohortTableTickers.length) {
      dispatch(getOptionsAssumptions([ticker], token));
    } else if (ticker && cohortTableTickers.length) {
      dispatch(
        getOptionsAssumptions(
          [ticker, ...cohortTableTickers.filter((item) => item !== ticker)],
          token
        )
      );
    }
  }, [companyInfo, cohortTableTickers, dispatch]);

  const [yearSelected, setYearSelected] = useState("");
  const yearsAvailable = useMemo(() => {
    if (!optionsAssumptions) return [];
    setYearSelected("");

    const yearKeys = {};

    Object.values(optionsAssumptions).forEach((item, index) => {
      if (typeof item === "object") {
        for (const assumptionData of Object.values(item)) {
          if (typeof assumptionData === "object") {
            for (const year of Object.keys(assumptionData)) {
              yearKeys[year] = "";
            }
          }
        }
      }
    });
    return Object.keys(yearKeys)
      .map((item) => ({ value: item, label: item, key: item }))
      .sort((a, b) => parseInt(b.value) - parseInt(a.value));
  }, [optionsAssumptions]);

  useEffect(() => {
    if (yearsAvailable && yearsAvailable.length && yearsAvailable?.[0]?.value) {
      setYearSelected(yearsAvailable[0].value);
    }
  }, [yearsAvailable]);

  const peerAssumptionComparisonColumns = useMemo(() => {
    if (!optionsAssumptions || typeof optionsAssumptions !== "object")
      return [];
    const keyKeys = { Ticker: "", Company: "" };
    for (const [ticker, assumptionsData] of Object.entries(
      optionsAssumptions
    )) {
      if (typeof assumptionsData !== "object") return;
      for (const [key, value] of Object.entries(assumptionsData)) {
        if (key !== "_id" && typeof value === "object" && value[yearSelected]) {
          keyKeys[key] = key;
        }
      }
    }

    const percentageKeys = [
      "ExpectedVolatility",
      "RiskFreeInterestRate",
      "DividendYield",
    ];

    const dollarKeys = ["WeightedAverageFairValueOfOptionsGranted"];

    const numericKeys = ["ExpectedLifeInYears"];

    const currentColumns = Object.keys(keyKeys).map((key) => {
      return {
        title: addSpaceBetweenCapitalizedWords(key),
        dataIndex: key,
        key: key,
        align: "center",
        render: (value) =>
          !value
            ? ""
            : percentageKeys.includes(key)
            ? parseFloat(100 * parseFloat(value)).toFixed(2) + "%"
            : dollarKeys.includes(key)
            ? "$" + parseFloat(value).toFixed(2)
            : numericKeys.includes(key)
            ? parseFloat(value).toFixed(2)
            : value,
        // sorter: (a, b) => (a?.award || "").localeCompare(b?.award || ""),
      };
    });

    currentColumns.push({
      title: "Black Scholes Value with Given Assumptions",
      dataIndex: "BSvalue",
      key: "BSvalue",
      align: "center",
      render: (value) => (!value ? "" : "$" + parseFloat(value).toFixed(2)),
      // sorter: (a, b) => (a?.award || "").localeCompare(b?.award || ""),
    });
    return currentColumns;
  }, [optionsAssumptions, yearSelected]);

  function periodToYears(period) {
    if (!period) return null;
    // Initialize the total years to 0
    let totalYears = 0;

    // Use regex to extract the years, months, and days from the period string
    const regex = /P(?:(\d+)Y)?(?:(\d+)M)?(?:(\d+)D)?/;
    const match = period.match(regex);

    // If there are matches, assign the values or default to 0
    const years = match[1] ? parseInt(match[1]) : 0;
    const months = match[2] ? parseInt(match[2]) : 0;
    const days = match[3] ? parseInt(match[3]) : 0;

    // Add the number of years for the years
    totalYears += years;

    // Add the number of years for the months (12 months per year)
    totalYears += months / 12;

    // Add the years directly from the period (365.25 days per year)
    totalYears += days / 365.25;

    return totalYears;
  }

  const peerAssumptionsData = useMemo(() => {
    if (!optionsAssumptions || typeof optionsAssumptions !== "object")
      return [];

    const allData = [];
    for (const [ticker, assumptionsData] of Object.entries(
      optionsAssumptions
    )) {
      if (typeof assumptionsData !== "object") return;
      const data = {
        Ticker: ticker,
        Company: companies?.[ticker]?.["Company"] || "",
      };
      for (const [key, value] of Object.entries(assumptionsData)) {
        if (key !== "_id" && typeof value === "object" && value[yearSelected]) {
          if (key === "ExpectedLifeInYears") {
            const years = periodToYears(value[yearSelected]);
            data[key] = years;
          } else {
            data[key] = value[yearSelected];
          }
        }
      }
      allData.push(data);
    }
    return allData;
  }, [companies, optionsAssumptions, yearSelected]);

  const [strikePriceDividendAdjusted, setStrikePriceDividendAdjusted] =
    useState(false);

  const filterInput = (input, min, max) => {
    let newInput = input.replace(/[^0-9.]/g, "").slice(0, 15);
    if (newInput.split(".").length > 2) {
      newInput = newInput.split(".").slice(0, 2).join(".");
    }
    if (newInput === "") return "";
    if (newInput >= max) return max;
    if (newInput <= min) return min;
    return newInput;
  };

  const [grantDate, setGrantDate] = useState(dayjs().subtract(3, "month"));
  const [valuationDate, setValuationDate] = useState(
    dayjs().subtract(2, "month")
  );
  const [useCalculatedRiskFreeRate, setUseCalculatedRiskFreeRate] =
    useState(true);

  const [blackScholesParameters, setBlackScholesParameters] = useState({
    annualVolatility: {
      label: "Annual Volatility",
      value: 20.0, //percent
      min: 0,
      max: 1e6,
      hoverText: OptionsFAQTopics.annual_volatility.short_desc,
    },
    strikePrice: {
      label: "Strike Price (Per Share)",
      value: 100,
      min: 0,
      max: 1e6,
      hoverText: OptionsFAQTopics.strike_price.short_desc,
    },

    companyAssumedLife: {
      label: "Company Assumed Life (in years)",
      value: 4.3,
      min: 0,
      max: 1000,
      // hoverText: OptionsFAQTopics.time_to_maturity.short_desc,
    },

    timeToMaturity: {
      label: "Max Term (in years)",
      value: 10,
      min: 0,
      max: 1000,
      hoverText: OptionsFAQTopics.time_to_maturity.short_desc,
    },

    currentStockPrice: {
      label: "Valuation Date Share Price",
      value: 100,
      min: 0,
      max: 1e6,
      hoverText: OptionsFAQTopics.current_stock_price.short_desc,
    },
    annualDividendPerShare: {
      label: "Annual Dividend (Per Share)",
      value: 5,
      min: 0,
      max: 1e6,
      hoverText:
        OptionsFAQTopics.expected_future_dividends_selection.short_desc,
    },
    dividendYield: {
      label: "Dividend Yield (%)",
      value: 5,
      min: 0,
      max: 1e6,
    },

    annualRiskFreeRate: {
      label: "Continuously Compounded Annual Riskfree Rate",
      value: 4.67, // percent
      min: 0,
      max: 1e6,
      hoverText:
        OptionsFAQTopics.continuously_compounded_riskfree_rate.short_desc,
    },
    dividendAdjustedCurrentStockPrice: {
      label: "Dividend Adjusted Current Stock Price",
      value: 99.03, //Just for testing, 100 can be default
      min: 0,
      max: 1e6,
      hoverText:
        OptionsFAQTopics.expected_future_dividends_selection.short_desc,
    },
  });

  useEffect(() => {
    const token = localStorage.getItem("ACCESS_TOKEN");
    // Treasury yield data is based on start date
    if (valuationDate) {
      const formattedDate = valuationDate.format(dateFormat);
      dispatch(getTreasuryYields([formattedDate], token));
    }
  }, [valuationDate, dispatch]);

  const [volatilityUpdated, setUpdatedVolatility] = useState(false);
  useEffect(() => {
    if (lookbackVolatilityBlendedAverage && !volatilityUpdated) {
      setBlackScholesParameters((prev) => ({
        ...prev,
        annualVolatility: {
          ...prev.annualVolatility,
          value: parseFloat(
            parseFloat(lookbackVolatilityBlendedAverage).toFixed(2)
          ),
        },
      }));
      setUpdatedVolatility(true);
    }
  }, [lookbackVolatilityBlendedAverage, volatilityUpdated]);

  const [dividendYieldNeedsUpdate, setDividendYieldNeedsUpdate] =
    useState(false);

  useEffect(() => {
    if (
      blackScholesParameters.annualDividendPerShare.value &&
      blackScholesParameters.currentStockPrice.value &&
      dividendYieldNeedsUpdate
    ) {
      setBlackScholesParameters((prev) => ({
        ...prev,
        dividendYield: {
          ...prev.dividendYield,
          value: parseFloat(
            parseFloat(
              (100 * blackScholesParameters.annualDividendPerShare.value) /
                blackScholesParameters.currentStockPrice.value
            ).toFixed(2)
          ),
        },
      }));
      setDividendYieldNeedsUpdate(false);
    }
  }, [blackScholesParameters, dividendYieldNeedsUpdate]);

  const [usingDefaultRiskFreeRate, setUsingDefaultRiskFreeRate] =
    useState(false);
  useEffect(() => {
    if (blackScholesParameters && usingDefaultRiskFreeRate) {
      setUsingDefaultRiskFreeRate(false);
    }
  }, [blackScholesParameters, usingDefaultRiskFreeRate]);

  const calculateRate = useCallback(
    (days) => {
      if (!valuationDate || !days) {
        if (!usingDefaultRiskFreeRate) {
          setUsingDefaultRiskFreeRate(true);
        }
        return null;
      }
      const formattedDate = valuationDate.format(dateFormat);

      const findSurroundingPoints = (data, targetDays) => {
        // Ensure the array is sorted by the 'days' property
        const sortedData = [...data].sort((a, b) => a.days - b.days);

        let lowerPoint = null;
        let upperPoint = null;

        for (let i = 0; i < sortedData.length; i++) {
          if (
            sortedData[i].days <= targetDays &&
            sortedData[i].rate &&
            !isNaN(sortedData[i].rate)
          ) {
            lowerPoint = sortedData[i];
          }
          if (
            sortedData[i].days > targetDays &&
            sortedData[i].rate &&
            !isNaN(sortedData[i].rate)
          ) {
            upperPoint = sortedData[i];
            break;
          }
        }

        return { lowerPoint, upperPoint };
      };

      // Set up dates in table (rows)

      // dataSource.map(
      //   (item) =>
      //     (item.numberOfDaysInFuture =
      //       item.date && valuationDate
      //         ? item.date.diff(valuationDate, "day")
      //         : null)
      // );

      // dataSource.map((item) => {
      const interpolateRate = (x, x1, y1, x2, y2) => {
        return y1 + ((x - x1) / (x2 - x1)) * (y2 - y1);
      };

      const surroundingDays = treasuryYields?.[formattedDate]?.Rates
        ? findSurroundingPoints(treasuryYields[formattedDate]?.Rates, days)
        : null;
      if (surroundingDays) {
        const interpolatedRate = interpolateRate(
          days,
          surroundingDays.lowerPoint?.days,
          surroundingDays.lowerPoint?.rate,
          surroundingDays.upperPoint?.days,
          surroundingDays.upperPoint?.rate
        );
        const rate = parseFloat(interpolatedRate).toFixed(4);
        return rate;
      }
      if (!usingDefaultRiskFreeRate) {
        setUsingDefaultRiskFreeRate(true);
      }
      return null;
      // });
    },
    [treasuryYields, valuationDate, usingDefaultRiskFreeRate]
  );
  const annualRiskFreeRate = 4.67;
  const [riskFreeRateUpdated, setUpdatedRiskFreeRate] = useState(false);
  useEffect(() => {
    if (annualRiskFreeRate && !riskFreeRateUpdated) {
      setBlackScholesParameters((prev) => ({
        ...prev,
        annualRiskFreeRate: {
          ...prev.annualRiskFreeRate,
          value: parseFloat(parseFloat(annualRiskFreeRate).toFixed(2)),
        },
      }));
      setUpdatedRiskFreeRate(true);
    }
  }, [annualRiskFreeRate, riskFreeRateUpdated]);

  const calculateNormalDistribution = (z, method = 3) => {
    if (method === 1) {
      const GetZPercent = (z) => {
        //z == number of standard deviations from the mean

        //if z is greater than 6.5 standard deviations from the mean
        //the number of significant digits will be outside of a reasonable
        //range
        if (z < -6.5) return 0.0;
        if (z > 6.5) return 1.0;

        var factK = 1;
        var sum = 0;
        var term = 1;
        var k = 0;
        var loopStop = Math.exp(-23);
        while (Math.abs(term) > loopStop) {
          term =
            //Note: 1/(sqrt(2*pi)) === 0.3989422804
            (((0.3989422804 * Math.pow(-1, k) * Math.pow(z, k)) /
              (2 * k + 1) /
              Math.pow(2, k)) *
              Math.pow(z, k + 1)) /
            factK;
          sum += term;
          k++;
          factK *= k;
        }
        sum += 0.5;

        return sum;
      };
      return GetZPercent(z);
    }

    if (method === 2) {
      const T = 1 / (1 + 0.2316419 * Math.abs(z));
      const D = 0.3989423 * Math.exp((-z * z) / 2);
      const cumulativeDistribution =
        D *
        T *
        (0.3193815 +
          T * (-0.3565638 + T * (1.781478 + T * (-1.821256 + T * 1.330274))));
      if (z > 0) return 1 - cumulativeDistribution;
      return cumulativeDistribution;
    }
    if (method === 3) {
      function normDistZ(z) {
        const a1 = 0.31938153;
        const a2 = -0.356563782;
        const a3 = 1.781477937;
        const a4 = -1.821255978;
        const a5 = 1.330274429;

        const L = Math.abs(z);
        const K = 1 / (1 + 0.2316419 * L);
        const term =
          a1 * K +
          a2 * Math.pow(K, 2) +
          a3 * Math.pow(K, 3) +
          a4 * Math.pow(K, 4) +
          a5 * Math.pow(K, 5);
        const phi =
          1 - (1 / Math.sqrt(2 * Math.PI)) * Math.exp(-0.5 * L * L) * term;

        return z >= 0 ? phi : 1 - phi;
      }
      return normDistZ(z);
    }
  };

  const calculatedBlackScholesValues = useCallback(
    (termLength = null, riskFreeRate = null) => {
      //Note: Math.log(x) === ln(x) in excel
      if (isNaN(riskFreeRate) && !usingDefaultRiskFreeRate) {
        setUsingDefaultRiskFreeRate(true);
        riskFreeRate = blackScholesParameters.annualRiskFreeRate.value || 4.67;
      }
      const sigma = blackScholesParameters.annualVolatility.value / 100; //if as a percent
      const T = termLength
        ? termLength
        : blackScholesParameters.timeToMaturity.value;
      const K = blackScholesParameters.strikePrice.value;
      const S0 = blackScholesParameters.currentStockPrice.value;
      const S0Div =
        blackScholesParameters.dividendAdjustedCurrentStockPrice.value;
      const r = riskFreeRate / 100;
      const discountRate = blackScholesParameters.dividendYield.value / 100;

      const S0Selected = strikePriceDividendAdjusted ? S0Div : S0;

      const d1Numerator =
        Math.log(S0Selected / K) +
        (r - discountRate + Math.pow(sigma, 2) / 2) * T;
      const d1Denominator = sigma * Math.sqrt(T);
      const d1 = d1Numerator / d1Denominator;

      const d2 = d1 - sigma * Math.sqrt(T);

      const N_D1 = calculateNormalDistribution(d1);
      const N_D2 = calculateNormalDistribution(d2);

      const callPriceEstimate =
        S0Selected * Math.exp(-discountRate * T) * N_D1 -
        K * Math.exp(-r * T) * N_D2;
      const putPriceEstimate =
        callPriceEstimate + (K * Math.exp(-r * T) - S0Selected);

      return { callPriceEstimate, putPriceEstimate };
    },
    [
      blackScholesParameters,
      strikePriceDividendAdjusted,
      usingDefaultRiskFreeRate,
    ]
  );

  const [storedPrevVolatility, setStoredPrevVolatility] = useState(20.0);
  useMemo(() => {
    if (showVolatilityEditView && !isNaN(lookbackVolatilityBlendedAverage)) {
      setBlackScholesParameters((prev) => {
        const newParams = { ...prev };
        const prevValue = newParams["annualVolatility"];

        if (prevValue.value !== lookbackVolatilityBlendedAverage.toFixed(5))
          setStoredPrevVolatility(prevValue.value);

        newParams["annualVolatility"] = {
          ...prevValue,
          value: lookbackVolatilityBlendedAverage.toFixed(2),
        };
        return newParams;
      });
    } else {
      setBlackScholesParameters((prev) => {
        const newParams = { ...prev };
        const prevValue = newParams["annualVolatility"];
        newParams["annualVolatility"] = {
          ...prevValue,
          value: storedPrevVolatility,
        };
        return newParams;
      });
    }
  }, [
    lookbackVolatilityBlendedAverage,
    showVolatilityEditView,
    storedPrevVolatility,
  ]);

  const [showOptionsAssumptions, setShowOptionsAssumptions] = useState(false);
  const [optionsAssumptionsYears, setOptionsAssumptionsYears] = useState([]);

  const optionsAssumptionsColumns = useMemo(() => {
    if (!optionsAssumptions?.[companyInfo?.Ticker]) return [];
    const ignoreKeys = ["Ticker", "Company", "_id"];

    const tableColumns = Object.keys(optionsAssumptions[companyInfo?.Ticker]);

    const filteredAssumptions = Object.entries(
      optionsAssumptions[companyInfo?.Ticker]
    ).filter(([key, val]) => !ignoreKeys.includes(key));

    const allKeyYears = filteredAssumptions.map((item) => item[1]);

    let minYear = Infinity;
    let maxYear = 0;

    for (const yearArray of allKeyYears) {
      if (typeof yearArray !== "object") continue;
      for (const year of Object.keys(yearArray)) {
        if (isNaN(parseInt(year))) continue;
        const intYear = parseInt(year);
        if (intYear > maxYear) {
          maxYear = intYear;
        }
        if (intYear < minYear) {
          minYear = intYear;
        }
      }
    }

    const years = [];

    for (let i = minYear; i <= maxYear; i++) {
      years.push(i);
    }

    setOptionsAssumptionsYears(years);

    const columns = [
      { title: "Assumption", dataIndex: "Assumption", key: "Assumption" },
      ...years.map((year) => ({
        title: year,
        dataIndex: year,
        key: year,
      })),
    ];
    return columns;
  }, [companyInfo, optionsAssumptions]);

  const blackScholesTableColumns = [
    {
      title: "Assumed Option Term (Years)",
      dataIndex: "time",
      key: "time",
      render: (item, rowData) => (
        <span
          style={{
            fontWeight: [
              blackScholesParameters.companyAssumedLife.value,
              blackScholesParameters.timeToMaturity.value,
            ].includes(rowData.time)
              ? "bold"
              : "",
          }}
        >
          {item}
        </span>
      ),
    },
    {
      title: "Effective Term (Years)",
      dataIndex: "effectiveTime",
      key: "effectiveTime",
      render: (item, rowData) => <span>{item ? item.toFixed(2) : "N/A"}</span>,
    },
    {
      title: "Risk Free Rate (%)",
      dataIndex: "riskFreeRate",
      key: "riskFreeRate",
      render: (item, rowData) => {
        return (
          <span>
            {item && !isNaN(item) ? parseFloat(item).toFixed(2) : "4.67*"}
          </span>
        );
      },
    },
    {
      title: "Dividend Yield (%)",
      dataIndex: "dividendYield",
      key: "dividendYield",
      render: (item, rowData) => (
        <span>{item && !isNaN(item) ? parseFloat(item).toFixed(2) : ""}</span>
      ),
    },
    {
      title: "Valuation ($)",
      dataIndex: "valuation",
      key: "valuation",
      render: (item, rowData) => (
        <span
          style={{
            fontWeight: [
              blackScholesParameters.companyAssumedLife.value,
              blackScholesParameters.timeToMaturity.value,
            ].includes(rowData.time)
              ? "bold"
              : "",
          }}
        >
          {!isNaN(item) && parseFloat(item).toFixed(2) === "0.00"
            ? "$0.00"
            : toDollar(item, 2)}
        </span>
      ),
    },
  ];

  const blackScholesTableData = useMemo(() => {
    const maxYear = blackScholesParameters.timeToMaturity.value;
    const companyAssumedYears = blackScholesParameters.companyAssumedLife.value;

    if (isNaN(maxYear)) return [];
    const roundedMaxYear = Math.floor(maxYear);

    const tableData = [];
    for (let i = 2; i <= roundedMaxYear; i++) {
      const effectiveTime =
        i - dayjs(valuationDate).diff(grantDate, "year", true);
      const riskFreeRate = useCalculatedRiskFreeRate
        ? calculateRate(effectiveTime * 365.25)
        : blackScholesParameters.annualRiskFreeRate.value;
      tableData.push({
        time: i,
        effectiveTime: effectiveTime,
        dividendYield: blackScholesParameters.dividendYield.value,
        riskFreeRate: riskFreeRate,
        valuation: calculatedBlackScholesValues(effectiveTime, riskFreeRate)
          .callPriceEstimate,
      });
    }

    if (
      !tableData.some(
        (item) =>
          companyAssumedYears &&
          !isNaN(companyAssumedYears) &&
          !isNaN(item.time) &&
          parseFloat(item.time) === parseFloat(companyAssumedYears)
      )
    ) {
      const effectiveTime =
        companyAssumedYears -
        dayjs(valuationDate).diff(grantDate, "year", true);
      const riskFreeRate = useCalculatedRiskFreeRate
        ? calculateRate(effectiveTime * 365.25)
        : blackScholesParameters.annualRiskFreeRate.value;
      tableData.push({
        time: companyAssumedYears,
        effectiveTime: effectiveTime,
        dividendYield: blackScholesParameters.dividendYield.value,
        riskFreeRate: riskFreeRate,
        valuation: calculatedBlackScholesValues(effectiveTime, riskFreeRate)
          .callPriceEstimate,
      });
    }

    if (maxYear !== roundedMaxYear) {
      const effectiveTime =
        maxYear - dayjs(valuationDate).diff(grantDate, "year", true);
      const riskFreeRate = useCalculatedRiskFreeRate
        ? calculateRate(effectiveTime * 365.25)
        : blackScholesParameters.annualRiskFreeRate.value;
      tableData.push({
        time: maxYear,
        effectiveTime: effectiveTime,
        dividendYield: blackScholesParameters.dividendYield.value,
        riskFreeRate: riskFreeRate,
        valuation: calculatedBlackScholesValues(effectiveTime, riskFreeRate)
          .callPriceEstimate,
      });
    }

    return tableData
      .filter((item) => item?.time)
      .sort((a, b) => a.time - b.time);
  }, [
    blackScholesParameters,
    grantDate,
    valuationDate,
    useCalculatedRiskFreeRate,
    calculateRate,
    calculatedBlackScholesValues,
  ]);

  const optionsAssumptionsData = useMemo(() => {
    const optionsAssumptionsData = optionsAssumptions?.[companyInfo?.Ticker];
    if (!optionsAssumptionsData) return [];

    delete optionsAssumptionsData._id;
    delete optionsAssumptionsData.Ticker;
    delete optionsAssumptionsData.Company;

    const tableData = Object.entries(optionsAssumptionsData).map(
      ([key, values]) => ({
        Assumption: toTitleCase(addSpaceBetweenCapitalizedWords(key)),
        ...Object.fromEntries(
          optionsAssumptionsYears.map((year) => [
            year,
            values[year]
              ? key?.includes("Value")
                ? toDollar(values[year], 2)
                : key.includes("Years")
                ? parseFloat(periodToYears(values[year])).toFixed(2)
                : parseFloat(100 * values[year]).toFixed(2) + "%"
              : null,
          ])
        ), // Fill missing years with null
      })
    );

    return tableData;
  }, [companyInfo, optionsAssumptions, optionsAssumptionsYears]);

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          margin: 20,
        }}
      >
        <div>
          <CustomTitleElement
            heading="View Historical Assumptions:"
            // hoverText={OptionsFAQTopics.select_performance_mode.short_desc}
            element={
              <Switch
                checked={showOptionsAssumptions}
                onChange={() => setShowOptionsAssumptions((prev) => !prev)}
              />
            }
          />
        </div>
      </div>
      {showOptionsAssumptions && (
        <div>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Table
              columns={optionsAssumptionsColumns}
              dataSource={optionsAssumptionsData}
              pagination={{ hideOnSinglePage: true }}
              style={{ width: "90%" }}
            />
          </div>

          <hr />
          <h3>Compare Assumptions to Peers</h3>

          <div>
            <CohortSelector
              heading={"Select Comparison Group:"}
              companyInfo={companyInfo}
              setCohortTableTickers={setCohortTableTickers}
              cohortTableSelector={cohortSelected}
              setCohortTableSelector={setCohortSelected}
              removeCustomCohort={true}
              additionalOptions={["None"]}
            />
            <div
              style={{
                display: "flex",
                justifyContent: "left",
                marginTop: 20,
              }}
            >
              <h3
                style={{
                  fontSize: 20,
                  marginRight: 10,
                  marginTop: 4,
                  textAlign: "bottom",
                }}
              >
                Select Assumptions Year:{" "}
              </h3>
              <Select
                defaultValue={
                  yearsAvailable?.length ? yearsAvailable?.[0]?.value : ""
                }
                style={{ width: 120, marginBottom: 20 }}
                options={yearsAvailable}
                onChange={(value) => setYearSelected(value)}
                value={yearSelected}
              />
            </div>
          </div>
          {cohortTableTickers.length > 0 && (
            <div>
              <Table
                columns={peerAssumptionComparisonColumns}
                dataSource={peerAssumptionsData}
                pagination={{ defaultPageSize: 20, hideOnSinglePage: true }}
                size="small"
              />
            </div>
          )}
        </div>
      )}

      <VolatilitySwitch />
      <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
        <div style={{ width: "68%" }}>
          {Object.entries(blackScholesParameters)
            .filter(
              ([key, val]) =>
                key !== "annualRiskFreeRate" || !useCalculatedRiskFreeRate
            )
            .map(([parameterKey, parameterValues], index) => (
              <div
                key={index}
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  width: "100%",
                }}
              >
                {parameterKey === "dividendAdjustedCurrentStockPrice" ? (
                  <div></div>
                ) : (
                  <div style={{ width: "100%" }}>
                    <CustomInput
                      state={parameterValues.value}
                      setState={(val) =>
                        setBlackScholesParameters((prev) => {
                          val = filterInput(
                            val,
                            parameterValues.min,
                            parameterValues.max
                          );
                          const newParams = { ...prev };
                          const prevValue = newParams[parameterKey];
                          newParams[parameterKey] = {
                            ...prevValue,
                            value: val,
                          };
                          return newParams;
                        })
                      }
                      heading={parameterValues.label + ":"}
                      style={{
                        margin: 15,
                        marginLeft:
                          parameterKey === "dividendYield" ? "50px" : "",
                      }}
                      onBlur={() => {
                        if (
                          parameterKey === "currentStockPrice" ||
                          parameterKey === "annualDividendPerShare"
                        ) {
                          setDividendYieldNeedsUpdate(true);
                        }
                      }}
                      disabled={
                        parameterKey === "annualVolatility" &&
                        showVolatilityEditView
                      }
                      key={parameterKey}
                      hoverText={parameterValues?.hoverText}
                    />
                    {parameterKey === "annualVolatility" && (
                      <div style={{ width: "100%" }}>
                        <hr style={{ width: "95%" }} />

                        <CustomDatePicker
                          state={grantDate}
                          setState={setGrantDate}
                          heading={"Grant Date: "}
                          style={{ margin: "25px 0px 15px 0px" }}
                          key={"grantDate"}
                          // hoverText={parameterValues.hoverText}
                        />
                      </div>
                    )}

                    {parameterKey === "timeToMaturity" && (
                      <div style={{ width: "100%" }}>
                        <hr style={{ width: "95%" }} />
                        <CustomDatePicker
                          state={valuationDate}
                          setState={setValuationDate}
                          heading={"Valuation Date: "}
                          style={{ margin: "25px 0px 15px 0px" }}
                          key={"valuationDate"}
                          // hoverText={parameterValues.hoverText}
                        />
                      </div>
                    )}
                    {parameterKey === "dividendYield" && (
                      <hr style={{ width: "95%" }} />
                    )}
                    {parameterKey === "dividendYield" && (
                      <CustomTitleElement
                        heading="Use Calculated Risk Free Rate:"
                        // hoverText={OptionsFAQTopics.select_performance_mode.short_desc}
                        element={
                          <Switch
                            checked={useCalculatedRiskFreeRate}
                            onChange={() =>
                              setUseCalculatedRiskFreeRate((prev) => !prev)
                            }
                          />
                        }
                      />
                    )}
                  </div>
                )}
              </div>
            ))}
        </div>
      </div>
      {/* <div style={{ width: "100%", display: "flex", margin: 15 }}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexWrap: "wrap",
          }}
        >
          <Tooltip
            title={OptionsFAQTopics.dividend_paying_stock.short_desc}
            overlayClassName="white-link"
            overlayInnerStyle={{ width: 500 }}
          >
            <h3 style={{ fontSize: 20, marginRight: 10, marginBottom: 0 }}>
              {hasHoverText(
                "Use Dividend Adjusted Stock Price: "
                  .split(" ")
                  .map((head, index) => <span key={index}>{head}&nbsp;</span>)
              )}
            </h3>
          </Tooltip>
          <Switch
            onChange={() =>
              setStrikePriceDividendAdjusted(!strikePriceDividendAdjusted)
            }
            checked={strikePriceDividendAdjusted}
          />
        </div>
      </div> */}
      {Object.entries(blackScholesParameters)
        .filter(
          ([key, val]) =>
            key === "dividendAdjustedCurrentStockPrice" &&
            strikePriceDividendAdjusted
        )
        .map(([parameterKey, parameterValues], index) => (
          <div
            key={index}
            style={{
              display: "flex",
              justifyContent: "flex-start",
            }}
          >
            <CustomInput
              state={parameterValues.value}
              setState={(val) =>
                setBlackScholesParameters((prev) => {
                  val = filterInput(
                    val,
                    parameterValues.min,
                    parameterValues.max
                  );
                  const newParams = { ...prev };
                  const prevValue = newParams[parameterKey];
                  newParams[parameterKey] = { ...prevValue, value: val };
                  return newParams;
                })
              }
              heading={parameterValues.label + ":"}
              style={{ margin: 15 }}
              disabled={
                parameterKey === "annualVolatility" && showVolatilityEditView
              }
              key={parameterKey}
              hoverText={parameterValues?.hoverText}
            />
          </div>
        ))}

      <div style={{ margin: 20 }} />
      <hr />
      <div style={{ margin: 35 }} />
      <h2>Results:</h2>
      <FinalValue
        heading="Black-Scholes Call Price Estimate (Company Assumed Term)"
        value={
          calculatedBlackScholesValues(
            blackScholesParameters.companyAssumedLife.value -
              dayjs(valuationDate).diff(grantDate, "year", true),
            calculateRate(
              365.25 *
                (blackScholesParameters.companyAssumedLife.value -
                  dayjs(valuationDate).diff(grantDate, "year", true))
            )
          )?.callPriceEstimate
        }
        headingWidth={745}
        decimalPlaces={2}
        isDollar={true}
      />
      <FinalValue
        heading={`Black-Scholes Call Price Estimate (Max Term)`}
        value={
          calculatedBlackScholesValues(
            blackScholesParameters.timeToMaturity.value -
              dayjs(valuationDate).diff(grantDate, "year", true),
            calculateRate(
              365.25 *
                (blackScholesParameters.timeToMaturity.value -
                  dayjs(valuationDate).diff(grantDate, "year", true))
            )
          )?.callPriceEstimate
        }
        headingWidth={570}
        decimalPlaces={2}
        isDollar={true}
      />
      <Table
        columns={blackScholesTableColumns}
        dataSource={blackScholesTableData}
        pagination={{ defaultPageSize: 20, hideOnSinglePage: true }}
        size="large"
        onRow={(record) => ({
          style: {
            fontSize: "1rem",
            backgroundColor:
              (!isNaN(blackScholesParameters.companyAssumedLife.value) &&
                !isNaN(record.time) &&
                parseFloat(record.time) ===
                  parseFloat(
                    blackScholesParameters.companyAssumedLife.value
                  )) ||
              record.time === blackScholesParameters.timeToMaturity.value
                ? "var(--primary-highlight)"
                : "transparent",
          },
        })}
        onHeaderRow={(record) => ({
          style: {
            fontSize: "1rem",
          },
        })}
      />
      {usingDefaultRiskFreeRate && (
        <div>
          <p style={{ marginTop: 10 }}>
            {`* Risk Free rate cannot be caculated for the valuation date / term selected. Please try another date or uncheck "Use Calculated Riskfree Rate" above.`}
          </p>
          <p style={{ marginLeft: 9.5 }}>
            Default riskfree rate of 4.67 is being used instead.
          </p>
        </div>
      )}

      {/* <FinalValue
        heading="Black-Scholes Put Price Estimate"
        value={calculatedBlackScholesValues()?.putPriceEstimate}
        headingWidth={417}
        decimalPlaces={6}
      /> */}
      <hr style={{ marginTop: 25 }} />
    </div>
  );
}
