import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Table, Button, Tooltip } from "antd";
import { getEquityPoolUsage } from "../redux/actions/CompanyAction";
import { emdash, toDollar } from "../utils/Constants/systemSetting";
import { exportToCSV } from "../utils/ExecutiveData/csvExport";
import CohortSelector from "./ExecutiveOverview/CohortSection/CohortSelector";

export default function EquityPoolUsage({ companyInfo, PDFprocessing }) {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.UserReducer);
  const { subscription } = useSelector((state) => state.CustomerReducer);
  const isTier2 = useMemo(() => {
    if (user?.tier2access || subscription?.plan?.metadata?.isTier2 === "true") {
      return true;
    }
    return false;
  }, [user, subscription]);

  function addSpaceBetweenCapitalizedWords(str) {
    return str.replace(/([a-z])([A-Z])/g, "$1 $2");
  }
  function toTitleCase(str) {
    return str.replace(
      /\w\S*/g,
      (text) => text.charAt(0).toUpperCase() + text.substring(1).toLowerCase()
    );
  }

  const [cohortTableTickers, setCohortTableTickers] = useState([]);
  const [cohortSelected, setCohortSelected] = useState("None");

  const { equityPoolUsage } = useSelector((state) => state.CompanyReducer);

  useMemo(() => {
    const token = localStorage.getItem("ACCESS_TOKEN");
    const ticker = companyInfo?.Ticker;
    if (ticker && !cohortTableTickers.length) {
      dispatch(getEquityPoolUsage([ticker], token));
    } else if (ticker && cohortTableTickers.length) {
      dispatch(
        getEquityPoolUsage(
          [ticker, ...cohortTableTickers.filter((item) => item !== ticker)],
          token
        )
      );
    }
  }, [companyInfo, cohortTableTickers, dispatch]);

  // useMemo(() => {
  //   const token = localStorage.getItem("ACCESS_TOKEN");
  //   const ticker = companyInfo?.Ticker;
  //   if (cohortTableTickers.length) {
  //     dispatch(
  //       getEquityPoolUsage(
  //         [ticker, ...cohortTableTickers.filter((item) => item !== ticker)],
  //         token
  //       )
  //     );
  //   }
  // }, [companyInfo, cohortTableTickers, dispatch]);

  const getEquityPoolColumns = useCallback((isPeerGroupTable = false) => {
    const defaultColumns = [
      {
        title: "Award Type",
        dataIndex: "award",
        key: "award",
        align: "center",
        render: (item) => addSpaceBetweenCapitalizedWords(item),
        sorter: (a, b) => (a?.award || "").localeCompare(b?.award || ""),
      },
      {
        title: "Granted",
        dataIndex: "Granted",
        key: "Granted",
        align: "center",
        render: (item) => (item ? item.toLocaleString() : ""),
        sorter: (a, b) => parseInt(a?.Granted || 0) - parseInt(b?.Granted || 0),
      },
      {
        title: "Vested",
        dataIndex: "Vested",
        key: "Vested",
        align: "center",
        render: (item) => (item ? item.toLocaleString() : ""),
        sorter: (a, b) => parseInt(a?.Vested || 0) - parseInt(b?.Vested || 0),
      },
      {
        title: "Exercised",
        dataIndex: "Exercised",
        key: "Exercised",
        align: "center",
        render: (item) => (item ? item.toLocaleString() : ""),
        sorter: (a, b) =>
          parseInt(a?.Exercised || 0) - parseInt(b?.Exercised || 0),
      },
      {
        title: "Forfeited",
        dataIndex: "Forfited",
        key: "Forfited",
        align: "center",
        render: (item) => (item ? item.toLocaleString() : ""),
        sorter: (a, b) =>
          parseInt(a?.Forfited || 0) - parseInt(b?.Forfited || 0),
      },
      {
        title: "Outstanding",
        dataIndex: "Outstanding",
        key: "Outstanding",
        align: "center",
        render: (item) => (item ? item.toLocaleString() : ""),
        sorter: (a, b) =>
          parseInt(a?.Outstanding || 0) - parseInt(b?.Outstanding || 0),
      },
    ];
    if (!isPeerGroupTable) {
      defaultColumns.push({
        title: "Additional Details",
        dataIndex: "AdditionalDetails",
        key: "AdditionalDetails",
        align: "center",
      });
    }

    if (isPeerGroupTable) {
      defaultColumns.unshift({
        title: "Ticker",
        dataIndex: "Ticker",
        key: "Ticker",
        align: "center",
        sorter: (a, b) => (a?.Ticker || "").localeCompare(b?.Ticker || ""),
      });
    }

    defaultColumns.push({
      title: "Citation",
      dataIndex: "Citation",
      key: "Citation",
      align: "center",
    });
    return defaultColumns;
  }, []);

  const peerGroupOverviewColumns = [
    {
      title: "Ticker",
      dataIndex: "Ticker",
      key: "Ticker",
      align: "center",
      sorter: (a, b) => (a?.Ticker || "").localeCompare(b?.Ticker || ""),
    },
    {
      title: "Total Granted",
      dataIndex: "TotalGranted",
      key: "TotalGranted",
      align: "center",
      render: (item) => (item ? item.toLocaleString() : ""),
      sorter: (a, b) =>
        parseInt(a?.TotalGranted || 0) - parseInt(b?.TotalGranted || 0),
    },
    {
      title: "Total Equity Outstanding",
      dataIndex: "TotalOutstanding",
      key: "TotalOutstanding",
      align: "center",
      render: (item) => (item ? item.toLocaleString() : ""),
      sorter: (a, b) =>
        parseInt(a?.TotalOutstanding || 0) - parseInt(b?.TotalOutstanding || 0),
    },
    {
      title: "Total Common Shares Outstanding",
      dataIndex: "TotalCommonOutstanding",
      key: "TotalCommonOutstanding",
      align: "center",
      render: (item) => (item ? item.toLocaleString() : ""),
      sorter: (a, b) =>
        parseInt(a?.TotalCommonOutstanding || 0) -
        parseInt(b?.TotalCommonOutstanding || 0),
    },
    {
      title: "Burn Rate (%)",
      dataIndex: "BurnRate",
      key: "BurnRate",
      align: "center",
      sorter: (a, b) =>
        parseFloat(a?.BurnRate || 0) - parseFloat(b?.BurnRate || 0),
    },
    {
      title: "Plan Overhang (%)",
      dataIndex: "PlanOverhang",
      key: "PlanOverhang",
      align: "center",
      sorter: (a, b) =>
        parseFloat(a?.PlanOverhang || 0) - parseFloat(b?.PlanOverhang || 0),
    },
    {
      title: "Citation",
      dataIndex: "Citation",
      key: "Citation",
      align: "center",
    },
  ];

  const [burnRate, setBurnRate] = useState(null);
  const [adjustedBurnRate, setAdjustedBurnRate] = useState(null);
  const [planOverhang, setPlanOverhang] = useState(null);
  const [EquityPoolData, setEquityPoolData] = useState(null);

  const equityPoolUsageData = useMemo(() => {
    if (!equityPoolUsage?.[companyInfo?.Ticker]) return [];
    return [equityPoolUsage?.[companyInfo?.Ticker]];
  }, [companyInfo, equityPoolUsage]);

  useEffect(() => {
    const currentData = equityPoolUsageData[0]?.ShareUsageData?.[0];
    if (!currentData) return;

    const awardTypes = [
      "RestrictedStockUnits",
      "PerformanceShareUnits",
      "StockOptions",
      "OtherAwards",
    ];

    const formattedData = [];

    let TotalGranted = 0;
    let TotalOutstanding = 0;

    let keyID = 1;

    for (const awardType of awardTypes) {
      if (!currentData?.[awardType]) continue;
      keyID += 1;
      const newData = { award: awardType, key: keyID };
      if (!currentData?.[awardType]) continue;
      for (const [key, val] of Object.entries(currentData[awardType])) {
        newData[key] = val;
        if (key === "Granted" && val > 0) {
          TotalGranted += parseInt(val);
        }
        if (key === "Outstanding" && val > 0) {
          TotalOutstanding += parseInt(val);
        }
      }

      formattedData.push(newData);
    }

    const totalSharesOutstanding =
      equityPoolUsageData[0]?.ShareUsageData?.[0]?.TotalOutstaning;
    const SharesAuthorized =
      equityPoolUsageData[0]?.ShareUsageData?.[0]?.SharesAuthorized;

    setBurnRate(
      parseFloat((100 * TotalGranted) / totalSharesOutstanding).toFixed(2)
    );

    setAdjustedBurnRate(
      parseFloat(
        (100 * TotalGranted + SharesAuthorized) / totalSharesOutstanding
      ).toFixed(2)
    );

    setPlanOverhang(
      parseFloat((100 * TotalOutstanding) / totalSharesOutstanding).toFixed(2)
    );

    setEquityPoolData(formattedData);

    return formattedData;
  }, [equityPoolUsageData, companyInfo]);

  const [EquityPoolPeerData, setEquityPoolPeerData] = useState(null);
  const [EquityPoolOverviewData, setEquityPoolOverviewData] = useState(null);

  useMemo(() => {
    const formattedData2 = [];
    const formattedOverviewData = [];
    if (!equityPoolUsage) return;

    for (const ticker of cohortTableTickers) {
      const currentData = equityPoolUsage?.[ticker]?.ShareUsageData?.[0];

      if (!currentData) continue;

      const awardTypes = [
        "RestrictedStockUnits",
        "PerformanceShareUnits",
        "StockOptions",
        "OtherAwards",
      ];

      let TotalGranted = 0;
      let TotalOutstanding = 0;

      let keyID = 1;

      for (const awardType of awardTypes) {
        if (!currentData?.[awardType]) continue;
        keyID += 1;
        const newData = {
          Ticker: ticker,
          award: awardType,
          key: ticker + keyID,
        };
        for (const [key, val] of Object.entries(currentData[awardType])) {
          newData[key] = val;
          if (key === "Granted" && val > 0) {
            TotalGranted += parseInt(val);
          }
          if (key === "Outstanding" && val > 0) {
            TotalOutstanding += parseInt(val);
          }
        }

        formattedData2.push(newData);
      }

      const totalSharesOutstanding = currentData?.TotalOutstaning;

      const totalSharesCitation = currentData?.TotalOutstaningCitation;

      const SharesAuthorized = currentData?.SharesAuthorized;

      const BurnRate = parseFloat(
        (100 * TotalGranted) / totalSharesOutstanding
      ).toFixed(2);

      // setAdjustedBurnRate(
      //   parseFloat(
      //     (100 * TotalGranted + SharesAuthorized) / totalSharesOutstanding
      //   ).toFixed(2)
      // );

      const PlanOverhang = parseFloat(
        (100 * TotalOutstanding) / totalSharesOutstanding
      ).toFixed(2);
      const newData = {
        Ticker: ticker,
        key: ticker,
      };

      newData["TotalGranted"] = TotalGranted || "";
      newData["TotalOutstanding"] = TotalOutstanding || "";
      newData["TotalCommonOutstanding"] = totalSharesOutstanding || "";
      newData["BurnRate"] = BurnRate || "";
      newData["PlanOverhang"] = PlanOverhang || "";
      newData["Citation"] = totalSharesCitation || "";

      formattedOverviewData.push(newData);
    }

    setEquityPoolOverviewData(formattedOverviewData);
    setEquityPoolPeerData(formattedData2);
  }, [equityPoolUsage, cohortTableTickers]);

  return (
    <>
      {!isTier2 ? (
        <div
          className="comp-ai-section col-lg-9 executives "
          style={{ height: "100%", padding: 50 }}
        >
          <h3
            style={{
              textAlign: "center",
              fontWeight: "normal",
              fontSize: 22,
            }}
          >
            <p style={{ fontWeight: "bold" }}>
              This page is for C-Suite Comp&reg; Enterprise users only.
            </p>
            <br />
            <p>Contact your account manager to access Equity Pool Usage Data</p>
          </h3>
        </div>
      ) : (
        <>
          <div className="company-calculator-section col-lg-9 executives p-0">
            {!equityPoolUsageData[0]?.ShareUsageData ||
            !equityPoolUsageData[0]?.ShareUsageData.length > 0 ? (
              <h2 style={{ textAlign: "center", marginTop: 50 }}>
                Equity Pool Usage Data for {companyInfo?.Ticker} Coming Soon
              </h2>
            ) : (
              <div>
                <h2>Equity Pool Usage</h2>
                {/* {!PDFprocessing && (
                  <div style={{ width: "100%" }}>
                    <Button
                      style={{ marginBottom: 10 }}
                      onClick={() =>
                        exportToCSV(
                          formatDataForCSVexport(),
                          `${companyInfo?.Ticker} ${
                            changeInControls?.[companyInfo?.Ticker]
                              ?.ChangeInControlDetails || ""
                          } Equity Pool Usage ${
                            new Date().toString().split("GMT-")[0]
                          }.csv`
                        )
                      }
                    >
                      Export Equity Pool Usage as CSV
                    </Button>
                  </div>
                )} */}
                <Table
                  columns={getEquityPoolColumns()}
                  dataSource={EquityPoolData}
                  pagination={{ hideOnSinglePage: true, defaultPageSize: 25 }}
                />
                {/* {equityPoolUsageData[0]?.ShareUsageData?.[0]
                  ?.SharesAuthorized && (
                  <p style={{ marginTop: 10 }}>
                    <b>Total Authorized: </b>

                    {equityPoolUsageData[0]?.ShareUsageData?.[0]?.SharesAuthorized?.toLocaleString()}
                  </p>
                )} */}
                {/* {equityPoolUsageData[0]?.ShareUsageData?.[0]
                  ?.SharesAuthorizedCitation && (
                  <p style={{ marginTop: 10, marginLeft: 25 }}>
                    <b>Citation: </b>

                    {
                      equityPoolUsageData[0]?.ShareUsageData?.[0]
                        ?.SharesAuthorizedCitation
                    }
                  </p>
                )} */}
                {equityPoolUsageData[0]?.ShareUsageData?.[0]
                  ?.TotalOutstaning && (
                  <p style={{ marginTop: 10 }}>
                    <b>Total Shares Outstaning: </b>

                    {equityPoolUsageData[0]?.ShareUsageData?.[0]?.TotalOutstaning?.toLocaleString()}
                  </p>
                )}
                {equityPoolUsageData[0]?.ShareUsageData?.[0]
                  ?.TotalOutstaningCitation && (
                  <p style={{ marginTop: 10, marginLeft: 25 }}>
                    <b>Citation: </b>

                    {
                      equityPoolUsageData[0]?.ShareUsageData?.[0]
                        ?.TotalOutstaningCitation
                    }
                  </p>
                )}
                <div style={{ margin: 25 }} />
                {/* Burn Rate */}
                <h2>
                  <Tooltip title="Burn rate is defined by the sum of all awards granted in the report year divided by the total common shares outstanding. Expressed as a percentage.">
                    <span style={{ cursor: "help" }}>Burn (Run) Rate:</span>
                  </Tooltip>{" "}
                  {burnRate}%
                </h2>
                {/* <h2>Adjusted Burn (Run) Rate: {adjustedBurnRate}%</h2> */}
                <h2>
                  {" "}
                  <Tooltip title="Plan Overhang is defined by the sum of all awards outstanding in the report year divided by the total common shares outstanding. Expressed as a percentage.">
                    <span style={{ cursor: "help" }}>Plan Overhang:</span>
                  </Tooltip>{" "}
                  {planOverhang}%
                </h2>
              </div>
            )}
          </div>
          <div className="company-calculator-section col-lg-12 executives mt-5 p-0">
            <div className="company-calculator-section executives">
              <div style={{ margin: 10 }} />
              <hr />
              <h2 style={{ textAlign: "center" }}>Peer Group Comparison</h2>
              <p style={{ textAlign: "center" }}>
                Select a Comparison Group to compare to {companyInfo.Company}
                's Equity Pool Usage:
              </p>
              <CohortSelector
                heading={"Select Comparison Group:"}
                companyInfo={companyInfo}
                setCohortTableTickers={setCohortTableTickers}
                cohortTableSelector={cohortSelected}
                setCohortTableSelector={setCohortSelected}
                removeCustomCohort={true}
                additionalOptions={["None"]}
              />
              {cohortTableTickers.length ? (
                <div style={{ marginTop: 20 }}>
                  <h2>Peer Group Equity Overview</h2>
                  <Table
                    columns={peerGroupOverviewColumns}
                    dataSource={EquityPoolOverviewData}
                    size={"small"}
                    pagination={{ hideOnSinglePage: true, defaultPageSize: 25 }}
                  />
                  <div style={{ marginTop: 20 }} />

                  <h2>Peer Group Equity Types</h2>
                  <Table
                    columns={getEquityPoolColumns(true)}
                    dataSource={EquityPoolPeerData}
                    size={"small"}
                    pagination={{ hideOnSinglePage: true, defaultPageSize: 25 }}
                  />
                </div>
              ) : null}
            </div>
          </div>
        </>
      )}
    </>
  );
}
