/* eslint-disable import/no-anonymous-default-export */
import {
  GET_PARAGRAPHS_8K502_SAGA,
  SET_COMPANY_INFO,
  SET_DISCLOSURE_RESULTS,
  SET_COMP_AI_RESULTS,
  SET_COMP_AI_AVAILABILITY_RESULTS,
  SET_HORSE_RACE_RESULTS,
  SET_INDEX_MONTHLY_RETURNS,
  SET_MARKET_CAP,
  SET_MEDIAN_BY_YEAR,
  SET_MONTHLY_MARKETCAPS,
  SET_MONTHLY_RETURNS,
  SET_BOARD_PAY,
  SET_ALL_BOARD_PAY,
  SET_PARAGRAPHS_8K502,
  SET_TSR_VALUES,
  SET_WEEKLY_RETURNS,
  SET_DAILY_PRICES,
  SET_OPTIMISTIC_HORSE_RACE_RESULTS,
  SET_BOARD_PAY_PROFILES,
  SET_AWARDS,
  SET_RISK_FACTORS,
  SET_PLAN_BASED_AWARDS,
  SET_COMPENSATION_DECISIONS,
  SET_CHANGE_IN_CONTROL,
  SET_BOARD_PAY_STRUCTURE,
  SET_TREASURY_YIELDS,
  SET_EXECUTIVE_PROFILES,
  SET_EXECUTIVE_PERKS,
  SET_OPTIONS_ASSUMPTIONS,
  SET_EQUITY_POOL_USAGE,
} from "../constants/CompanyConstant";
import { SET_COMPANY_EXECUTIVES } from "../constants/ExecutivesConstant";

const initialState = {
  info: {},
  executives: {},
  marketCap: {},
  paragraphsFrom8k502: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_COMPANY_EXECUTIVES: {
      return { ...state, executives: { ...action.executives } };
    }
    case SET_COMPANY_INFO: {
      return { ...state, info: { ...action.info } };
    }
    case SET_MARKET_CAP: {
      return { ...state, marketCap: { ...action.marketCap } };
    }

    case SET_PARAGRAPHS_8K502: {
      return {
        ...state,
        paragraphsFrom8k502: { ...action.paragraphsFrom8k502 },
      };
    }

    case SET_MONTHLY_RETURNS: {
      return {
        ...state,
        monthlyReturns: { ...action.monthlyReturns },
      };
    }

    case SET_WEEKLY_RETURNS: {
      return {
        ...state,
        weeklyReturns: { ...action.weeklyReturns },
      };
    }

    case SET_DAILY_PRICES: {
      return {
        ...state,
        dailyPrices: { ...action.dailyPrices },
      };
    }

    case SET_TREASURY_YIELDS: {
      return {
        ...state,
        treasuryYields: { ...action.treasuryYields },
      };
    }

    case SET_AWARDS: {
      return {
        ...state,
        awards: { ...action.awards },
      };
    }

    case SET_RISK_FACTORS: {
      return {
        ...state,
        riskFactors: { ...action.riskFactors },
      };
    }

    case SET_PLAN_BASED_AWARDS: {
      return {
        ...state,
        planBasedAwards: { ...action.planBasedAwards },
      };
    }

    case SET_COMPENSATION_DECISIONS: {
      return {
        ...state,
        compensationDecisions: { ...action.compensationDecisions },
      };
    }

    case SET_CHANGE_IN_CONTROL: {
      return {
        ...state,
        changeInControls: { ...action.changeInControls },
      };
    }

    case SET_INDEX_MONTHLY_RETURNS: {
      return {
        ...state,
        indexMonthlyReturns: { ...action.indexMonthlyReturns },
      };
    }

    case SET_MONTHLY_MARKETCAPS: {
      return {
        ...state,
        monthlyMarketcaps: { ...action.monthlyMarketcaps },
      };
    }

    case SET_BOARD_PAY: {
      return {
        ...state,
        boardPay: { ...action.boardPay },
      };
    }

    case SET_ALL_BOARD_PAY: {
      return {
        ...state,
        allBoardPay: { ...action.boardPay },
      };
    }

    case SET_BOARD_PAY_PROFILES: {
      return {
        ...state,
        boardPayProfiles: { ...action.boardPayProfiles },
      };
    }

    case SET_EXECUTIVE_PROFILES: {
      return {
        ...state,
        executiveProfiles: { ...action.executiveProfiles },
      };
    }

    case SET_BOARD_PAY_STRUCTURE: {
      return {
        ...state,
        boardPayStructure: { ...action.boardPayStructure },
      };
    }

    case SET_EXECUTIVE_PERKS: {
      return {
        ...state,
        executivePerks: { ...action.executivePerks },
      };
    }

    case SET_OPTIONS_ASSUMPTIONS: {
      return {
        ...state,
        optionsAssumptions: { ...action.optionsAssumptions },
      };
    }

    case SET_EQUITY_POOL_USAGE: {
      return {
        ...state,
        equityPoolUsage: { ...action.equityPoolUsage },
      };
    }

    case SET_MEDIAN_BY_YEAR: {
      return {
        ...state,
        medianByYear: { ...action.medianByYear },
      };
    }

    case SET_DISCLOSURE_RESULTS: {
      return {
        ...state,
        disclosureResults: { ...action.disclosureResults },
      };
    }

    case SET_COMP_AI_RESULTS: {
      return {
        ...state,
        compAIResults: { ...action.compAIResults },
      };
    }

    case SET_COMP_AI_AVAILABILITY_RESULTS: {
      return {
        ...state,
        compAIAvailability: { ...action.compAIAvailability },
      };
    }

    case SET_HORSE_RACE_RESULTS: {
      return {
        ...state,
        horseRaceResults: { ...action.horseRaceResults },
      };
    }

    case SET_OPTIMISTIC_HORSE_RACE_RESULTS: {
      return {
        ...state,
        optimisticHorseRaceResults: { ...action.optimisticHorseRaceResults },
      };
    }

    default:
      return state;
  }
};
