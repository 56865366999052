import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useRef,
} from "react";
import "../css/overview.css";
import {
  Radio,
  Table,
  Checkbox,
  Select,
  Tooltip,
  SelectProps,
  Button,
  Col,
  Row,
  Input,
} from "antd";
import {
  getIndexMonthlyReturns,
  getMonthlyMarketcaps,
  getMonthlyReturns,
} from "../redux/actions/CompanyAction";
import { useDispatch, useSelector } from "react-redux";
import {
  MONTHS,
  emdash,
  RadioSelector,
  TSRperiodMessage,
  hasHoverText,
  indexComparisonMessage,
  defaultPeerGroupOptions,
} from "../utils/Constants/systemSetting";
import { CalculatorFAQTopics } from "../assets/faqTopicsCalculators";
import { sortClosestCompany } from "../utils/ExecutiveData/sortClosest";
import { exportToCSV } from "../utils/ExecutiveData/csvExport";
import toast from "react-hot-toast";
import { updateUser } from "../redux/actions/UserAction";
import UserService from "../services/UserService";

import CohortSelector from "./ExecutiveOverview/CohortSection/CohortSelector.js";
import TSRLineGraph from "./CompanyCalculators/TSRLineGraph";
import PeerGroupTable from "./CompanyCalculators/PeerGroupTable";
import PvPTable from "./CompanyCalculators/PvPTable.js";

const { Option } = Select;

export default function CompanyCalculator({
  companyInfo,
  companyInfoComponent,
  companyExecutives,
  PDFprocessing = false,
}) {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.UserReducer);
  const { subscription } = useSelector((state) => state.CustomerReducer);

  const isTier2 = useMemo(() => {
    if (user?.tier2access || subscription?.plan?.metadata?.isTier2 === "true") {
      return true;
    }
    return false;
  }, [user, subscription]);

  const { monthlyReturns, indexMonthlyReturns, monthlyMarketcaps } =
    useSelector((state) => state.CompanyReducer);
  const [monthSelected, setMonthSelected] = useState("Dec");
  const [periodicitySelected, setPeriodicitySelected] = useState("Annual");

  const [yearsAvailable, setYearsAvailable] = useState([]);
  const [yearSelected, setYearSelected] = useState(
    new Date().getFullYear() - (new Date().getMonth() < 3 ? 1 : 0)
  );
  const [PvPTableYearSelected, setPvPTableYearSelected] = useState(
    new Date().getFullYear() - (new Date().getMonth() < 3 ? 2 : 1)
  );
  const [PvPTableMonthSelected, setPvPTableMonthSelected] = useState("Dec");
  const [calculating, setCalculating] = useState(true);

  useEffect(() => {
    const newYearsAvailable = [];
    const currentYear =
      new Date().getFullYear() - (new Date().getMonth() < 1 ? 1 : 0);
    for (let year = currentYear; year >= currentYear - 10; year--) {
      newYearsAvailable.push(year);
    }
    setYearsAvailable(newYearsAvailable);
  }, []);

  const [TSRperiod, setTSRperiod] = useState("5yr");
  const TSRperiodsAvailable = [
    "1yr",
    "2yr",
    "3yr",
    "4yr",
    "5yr",
    "7yr",
    "10yr",
  ];

  const [cohortTableSelector, setCohortTableSelector] = useState("");
  const [cohortTableTickers, setCohortTableTickers] = useState([]);

  const [peerGroupTSRs, setPeerGroupTSRs] = useState([]);
  const [peerGroupMarketcaps, setPeerGroupMarketcaps] = useState([]);

  const [customCohortCompanies, setCustomCohortCompanies] = useState([]);

  const [indexesSelected, setIndexesSelected] = useState([]);
  const [PvPIndexesSelected, setPvPIndexesSelected] = useState([]);

  const [searchVal, setSearchVal] = useState("");
  const [companiesChildren, setCompaniesChildren] = useState([]);

  const [addedComparisonCompanies, setAddedComparisonCompanies] = useState([]);
  const { companies } = useSelector((state) => state.CompaniesReducer);

  const [includeCompanyInPeerGroup, setIncludeCompanyInPeerGroup] =
    useState("Include");

  useEffect(() => {
    const token = localStorage.getItem("ACCESS_TOKEN");
    if (companyInfo?.Ticker) {
      const addedComparisonCompaniesTickers = addedComparisonCompanies.map(
        (item) => item.split("[")[1].replace("]", "")
      );
      dispatch(getIndexMonthlyReturns(token));
      dispatch(
        getMonthlyReturns(
          [
            companyInfo.Ticker,
            ...customCohortCompanies,
            ...addedComparisonCompaniesTickers,
          ],
          token
        )
      );
      dispatch(
        getMonthlyMarketcaps(
          [
            companyInfo.Ticker,
            ...customCohortCompanies,
            ...addedComparisonCompaniesTickers,
          ],
          token
        )
      );
    }
  }, [
    companyInfo,
    cohortTableTickers,
    customCohortCompanies,
    addedComparisonCompanies,
    dispatch,
  ]);

  useEffect(() => {
    if (Object.values(companies).length <= 10) return;
    setCompaniesChildren(
      Object.values(companies)
        .filter(
          (item) =>
            item &&
            customCohortCompanies.every((company) => {
              if (!company) return false;
              return (
                company.Company !== item.Company ||
                company.Ticker !== item.Ticker
              );
            }) &&
            (searchVal === "" ||
              searchVal
                .toLowerCase()
                .split(" ")
                .every(
                  (val) =>
                    val === "" ||
                    item.Ticker.toLowerCase().includes(val) ||
                    item.Company.toLowerCase().includes(val)
                ))
        )
        .sort((a, b) => {
          return sortClosestCompany(a, b, searchVal);
        })
        .map((res) => (
          <Option key={res.Company + " [" + res.Ticker + "]"}>
            {res.Company + " [" + res.Ticker + "]"}
          </Option>
        ))
    );
  }, [companies, customCohortCompanies, searchVal]);

  const [disabled, setDisabled] = useState(false);
  const [disabledReason, setDisabledReason] = useState("");
  const [cohortUpdateCount, setCohortUpdateCount] = useState(1);
  const [customPeerGroupName, setCustomPeerGroupName] = useState(
    "Custom Peer Group 1"
  );
  const [isAboutToDelete, setAboutToDelete] = useState(false);
  const [peerGroupKey, setPeerGroupKey] = useState("");
  const [customCohortTickers, setCustomCohortTickers] = useState([]);

  const [customUserPeerGroups, setCustomUserPeerGroups] = useState(
    user?.customPeerGroups || {}
  );

  const CheckboxGroup = Checkbox.Group;
  const plainOptions = [
    "Value-Weighted (Item 201 Compliant)",
    "Equal-Weighted",
    "Median Composite",
  ];
  const defaultWeightingsCheckList = [
    "Value-Weighted (Item 201 Compliant)",
    "Equal-Weighted",
    "Median Composite",
  ];
  const [weightingsCheckList, setWeightingsCheckList] = useState([
    defaultWeightingsCheckList[0],
  ]);
  const onWeightingsCheckListChange = (list) => {
    setWeightingsCheckList(list);
  };

  useMemo(() => {
    setTimeout(() => {
      setCalculating(
        monthlyMarketcaps &&
          monthlyReturns &&
          customCohortCompanies &&
          !customCohortCompanies.every(
            (ticker) =>
              Object.keys(monthlyMarketcaps).includes(ticker) &&
              Object.keys(monthlyReturns).includes(ticker)
          )
      );
    }, 1000);
    return;
  }, [
    monthlyMarketcaps,
    customCohortCompanies,
    monthlyReturns,
    setCalculating,
  ]);

  const TSRtableColumns = useMemo(() => {
    const intialTSRtableColumns = [
      {
        title: `TSR Period ending ${monthSelected} ${yearSelected}`,
        dataIndex: "TSRperiod",
        key: "TSRperiod",
        align: "center",
      },
      {
        title: `${companyInfo.Company} TSR`,
        dataIndex: "calculatedTSR",
        key: "calculatedTSR",
        align: "center",
        render: (data) => {
          return !isNaN(parseFloat(data)) ? parseFloat(data).toFixed(2) : data;
        },
      },
    ];
    if (weightingsCheckList.includes("Value-Weighted (Item 201 Compliant)")) {
      intialTSRtableColumns.push({
        title: (
          // <span>Peer Group TSR (Item 201 Compliant)</span>
          <Tooltip
            title={CalculatorFAQTopics.item_201_compliant.short_desc}
            overlayClassName="white-link"
          >
            {hasHoverText(
              <span>
                <p>Peer Group TSR (Value Weighted, </p>
                <p>Item 201 Compliant)</p>
              </span>
            )}
          </Tooltip>
        ),
        dataIndex: "peerGroup201Compliant",
        key: "peerGroup201Compliant",
        width: 280,
        align: "center",
        render: (data) => {
          return !isNaN(parseFloat(data)) ? parseFloat(data).toFixed(2) : data;
        },
      });
    }
    if (weightingsCheckList.includes("Equal-Weighted")) {
      intialTSRtableColumns.push({
        title: (
          <Tooltip
            title={CalculatorFAQTopics.equal_weighted.short_desc}
            overlayClassName="white-link"
          >
            {hasHoverText(
              <span>
                {" "}
                <p>Peer Group TSR</p> <p>(Equal-Weigted)</p>
              </span>
            )}
          </Tooltip>
        ),
        dataIndex: "peerGroupTSR",
        key: "peerGroupTSR",
        align: "center",
        render: (data) => {
          return !isNaN(parseFloat(data)) ? parseFloat(data).toFixed(2) : data;
        },
      });
    }
    if (weightingsCheckList.includes("Median Composite")) {
      intialTSRtableColumns.push({
        title: (
          <Tooltip
            title={CalculatorFAQTopics.median_composite.short_desc}
            overlayClassName="white-link"
          >
            {hasHoverText(
              <span>
                <p>Peer Group TSR</p> <p>(Median Composite)</p>
              </span>
            )}
          </Tooltip>
        ),
        dataIndex: "peerGroupMedianWeighted",
        key: "peerGroupMedianWeighted",
        align: "center",
        render: (data) => {
          return !isNaN(parseFloat(data)) ? parseFloat(data).toFixed(2) : data;
        },
      });
    }

    if (addedComparisonCompanies.length) {
      addedComparisonCompanies.forEach((index) => {
        intialTSRtableColumns.push({
          title: `${index} TSR`,
          dataIndex: index,
          key: index,
          align: "center",
          render: (data) => {
            return !isNaN(parseFloat(data))
              ? parseFloat(data).toFixed(2)
              : data;
          },
        });
      });
    }

    if (indexesSelected.length) {
      indexesSelected.forEach((index) => {
        intialTSRtableColumns.push({
          title: `${index} TSR`,
          dataIndex: index,
          key: index,
          align: "center",
          render: (data) => {
            return !isNaN(parseFloat(data))
              ? parseFloat(data).toFixed(2)
              : data;
          },
        });
      });
    }
    return intialTSRtableColumns;
  }, [
    weightingsCheckList,
    companyInfo,
    indexesSelected,
    addedComparisonCompanies,
    monthSelected,
    yearSelected,
  ]);

  const getYearsNeeded = useCallback(
    (TSRperiodNeeded = TSRperiod, yearNeeded = yearSelected) => {
      const numberOfYearsneeded = TSRperiodNeeded.split("yr")[0];
      const yearsNeededForTSR = [];
      for (
        let year = yearNeeded + 1 - numberOfYearsneeded;
        year <= yearNeeded;
        year++
      ) {
        yearsNeededForTSR.push(year);
      }
      return yearsNeededForTSR;
    },
    [TSRperiod, yearSelected]
  );

  const getMonthsNeeded = useCallback(
    (
      TSRperiodNeeded = TSRperiod,
      yearNeeded = yearSelected,
      monthNeeded = monthSelected
    ) => {
      const monthsInOrder = [...MONTHS].reverse();
      const currentMonthIndex = monthsInOrder.indexOf(monthNeeded);

      const yearsNeededForTSR = getYearsNeeded(TSRperiodNeeded, yearNeeded);
      const allMonthsNeeded = [];
      for (const year of yearsNeededForTSR) {
        for (let i = 0; i < 12; i++) {
          allMonthsNeeded.push(
            `${
              monthsInOrder[
                currentMonthIndex + i > 11
                  ? currentMonthIndex + i - 12
                  : currentMonthIndex + i
              ]
            } ${currentMonthIndex + i > 11 ? year : year - 1}`
          );
        }
      }
      allMonthsNeeded.push(`${monthNeeded} ${yearNeeded}`);
      return allMonthsNeeded;
    },
    [TSRperiod, monthSelected, yearSelected, getYearsNeeded]
  );

  const calculateTSR = useCallback(
    (
      allMonthsNeeded,
      tickerNeeded = undefined,
      TSRperiodNeeded = TSRperiod,
      yearNeeded = yearSelected,
      monthNeeded = monthSelected,
      indexNeeded = undefined
    ) => {
      const getIndexData = (index) => {
        if (!indexMonthlyReturns) return;
        const allIndexes = Object.values(indexMonthlyReturns);
        return allIndexes.find((indexData) => indexData.index === index);
      };

      const monthlyReturnsForTSR = [];
      const allMonthsTSR = {};

      const indexMonthlyReturnsData = {};
      if (indexNeeded && getIndexData(indexNeeded)) {
        for (const [key, val] of Object.entries(getIndexData(indexNeeded))) {
          indexMonthlyReturnsData[key] = val;
        }
      }
      // Loop through all relevent months/years of data (e.g. "Dec 2020")
      for (const monthYearOfData of allMonthsNeeded) {
        const monthyReturnForMonth = indexNeeded
          ? indexMonthlyReturnsData?.[monthYearOfData] // || 0
          : monthlyReturns?.[tickerNeeded]?.[monthYearOfData]; // || 0;

        // Add all TSR values to array until date selected by user is reached:
        if (
          monthYearOfData !==
          `${monthNeeded} ${yearNeeded - TSRperiodNeeded.split("yr")[0]}`
        ) {
          monthlyReturnsForTSR.push(monthyReturnForMonth);
        }

        // Reduce all values in array into one accumulated value (accumulator * (1+TSR), starting with $1 investment
        const calculateTSRproduct = monthlyReturnsForTSR.reduce(
          (accumulator, currentValue) => {
            return accumulator * (currentValue >= -1 ? currentValue + 1 : null);
          },
          1
        );
        // If TSR sumproduct is a valid number:
        if (!isNaN(calculateTSRproduct)) {
          // Multiply it by 100 ($100 investment) and store it as the datapoint for that month (["Dec 2021"] = result)
          allMonthsTSR[monthYearOfData] = parseFloat(100 * calculateTSRproduct);
        } else {
          // Otherwise set to null
          allMonthsTSR[monthYearOfData] = null;
        }
      }
      return allMonthsTSR;
    },
    [
      monthlyReturns,
      indexMonthlyReturns,
      TSRperiod,
      monthSelected,
      yearSelected,
    ]
  );

  const calculatePeerGroupTSR = useCallback(
    (
      allMonthsNeeded,
      tickersNeeded = [companyInfo.Ticker],
      weighting = "Equal",
      TSRperiodNeeded = "3yr", //3yr default is backup for PvP table
      yearNeeded = yearSelected,
      monthNeeded = monthSelected,
      periodicitySelectedForTSR = periodicitySelected
    ) => {
      if (!monthlyReturns) return;

      const monthlyReturnsForTSR = [];
      const allMonthsTSR = {};
      const allPeerGroupMarketcaps = {};
      const startingPeerGroupMarketCap = {};
      const allPeerGroupTSRs = {};

      const consolidatedReturns = {};

      const periodicityPeriod =
        periodicitySelectedForTSR === "Annual"
          ? 12
          : periodicitySelectedForTSR === "Quarterly"
          ? 3
          : 1;

      const allPeriodicityMonthsNeeded = [];
      for (const tickerNeeded of tickersNeeded) {
        consolidatedReturns[tickerNeeded] = {};
        let currentPeriod = 0;
        let currentConsolidatedReturn = 1;
        for (const monthYearOfData of allMonthsNeeded) {
          // Don't return any periods with no data for at least one company (.some())
          if (
            !tickersNeeded.some(
              (ticker) =>
                monthlyReturns?.[ticker]?.[monthYearOfData] &&
                monthlyReturns?.[ticker]?.[monthYearOfData] !== "#N/A"
            )
          ) {
            continue;
          }
          if (currentPeriod === periodicityPeriod) {
            currentConsolidatedReturn *=
              1 + (monthlyReturns?.[tickerNeeded]?.[monthYearOfData] || 0);
            consolidatedReturns[tickerNeeded][monthYearOfData] =
              currentConsolidatedReturn - 1;
            currentConsolidatedReturn = 1;
            currentPeriod = 1;
            if (!allPeriodicityMonthsNeeded.includes(monthYearOfData)) {
              allPeriodicityMonthsNeeded.push(monthYearOfData);
            }
          } else {
            currentConsolidatedReturn *=
              1 + (monthlyReturns?.[tickerNeeded]?.[monthYearOfData] || 0);
            if (currentPeriod === 0) {
              if (!allPeriodicityMonthsNeeded.includes(monthYearOfData)) {
                allPeriodicityMonthsNeeded.push(monthYearOfData);
              }
              currentConsolidatedReturn = 1;
            }
            currentPeriod += 1;
          }
        }
      }
      // Loop through all relevent months/years of data (e.g. "Dec 2020")
      for (const monthYearOfData of allPeriodicityMonthsNeeded) {
        const monthlyReturnsForTickersNeeded = {};
        for (const tickerNeeded of tickersNeeded) {
          const monthyReturnForCompanyMonth =
            consolidatedReturns?.[tickerNeeded]?.[monthYearOfData] || null;

          // Add all TSR values to array until date selected by user is reached:
          monthlyReturnsForTickersNeeded[tickerNeeded] =
            monthyReturnForCompanyMonth;

          if (
            weighting === "201 Compliant" &&
            monthlyMarketcaps?.[tickerNeeded]?.[monthYearOfData] &&
            Object.entries(monthlyReturnsForTickersNeeded).some(
              ([ticker, monthlyReturn]) => {
                return (
                  tickerNeeded === ticker && !isNaN(parseFloat(monthlyReturn))
                );
              }
            )
          ) {
            //// Reinvest strat based on previous month of Marketcap
            const prevMonthYear =
              allPeriodicityMonthsNeeded[
                allPeriodicityMonthsNeeded.indexOf(monthYearOfData) - 1
              ];
            allPeerGroupMarketcaps[tickerNeeded] =
              monthlyMarketcaps[tickerNeeded]?.[prevMonthYear];
            //// For 201 Compliant weighting: (no longer used, replaced by value weighted)
            // const startingMonthYear = `${monthNeeded} ${
            //   yearNeeded - TSRperiodNeeded.split("yr")[0]
            // }`;
            // startingPeerGroupMarketCap[tickerNeeded] =
            //   monthlyMarketcaps[tickerNeeded]?.[startingMonthYear];

            const peerCompanyTSR = calculateTSR(
              allPeriodicityMonthsNeeded,
              tickerNeeded
            );
            allPeerGroupTSRs[tickerNeeded] = peerCompanyTSR;
          }
        }

        let monthlyReturnForGroup = null;
        // let monthlyTSRForGroup = null; //For Item 201 weighting, no longer used

        // This is the same as "value weighted" now, is default option
        if (weighting === "201 Compliant") {
          const remappedMonthlyReturns = {};

          const filteredMonthlyReturns = Object.entries(
            monthlyReturnsForTickersNeeded
          ).filter(([key, val]) => val !== null);

          filteredMonthlyReturns.forEach((item) => {
            if (item[0] && item[1]) {
              remappedMonthlyReturns[item[0]] = item[1];
            }
          });
          const totalPeerGroupMarketCap = Object.values(allPeerGroupMarketcaps)
            .filter((item) => item !== null)
            .reduce((acc, marketcap) => {
              return acc + (marketcap || 0);
            }, 0);

          monthlyReturnForGroup = Object.entries(remappedMonthlyReturns).reduce(
            (acc, [ticker, monthlyReturn]) => {
              return (
                acc +
                (!isNaN(parseFloat(monthlyReturn)) &&
                allPeerGroupMarketcaps[ticker]
                  ? monthlyReturn *
                    (allPeerGroupMarketcaps[ticker] / totalPeerGroupMarketCap)
                  : null)
              );
            },
            0
          );
        }

        if (weighting === "Equal") {
          const filteredMonthlyReturns = Object.values(
            monthlyReturnsForTickersNeeded
          ).filter((item) => item !== null);

          monthlyReturnForGroup =
            Object.values(filteredMonthlyReturns).reduce((a, b) => a + b, 0) /
            Object.values(filteredMonthlyReturns).length;
        }
        if (weighting === "Median") {
          const sortedMonthlyReturns = Object.values(
            monthlyReturnsForTickersNeeded
          )
            .filter((item) => item !== null)
            .sort((a, b) => a - b);
          monthlyReturnForGroup =
            sortedMonthlyReturns.length % 2 !== 0
              ? sortedMonthlyReturns[(sortedMonthlyReturns.length - 1) / 2]
              : (sortedMonthlyReturns[
                  (sortedMonthlyReturns.length - 1) / 2 - 0.5
                ] +
                  sortedMonthlyReturns[
                    (sortedMonthlyReturns.length - 1) / 2 + 0.5
                  ]) /
                2;
        }

        // Previous 201 Compliant method, now using "value" approach
        // TODO: Delete if not needed, after confirming all calcs are correct
        // if (weighting === "201 Compliant") {
        //   const startingTotalMarketCap = Object.values(
        //     startingPeerGroupMarketCap
        //   ).reduce((acc, marketcap) => {
        //     return acc + (marketcap || 0);
        //   }, 0);
        //   // if (!startingPeerGroupMarketCap) return null;
        //   monthlyTSRForGroup = Object.entries(allPeerGroupTSRs).reduce(
        //     (a, [ticker, TSRs]) => {
        //       return (
        //         a +
        //         (!isNaN(parseFloat(TSRs?.[monthYearOfData])) &&
        //         startingPeerGroupMarketCap[ticker]
        //           ? (TSRs[monthYearOfData] / 100) *
        //             (startingPeerGroupMarketCap[ticker] /
        //               startingTotalMarketCap)
        //           : 0)
        //       );
        //     },
        //     0
        //   );
        //   if (!isNaN(monthlyTSRForGroup)) {
        //     // Multiply it by 100 ($100 investment) and store it as the datapoint for that month (["Dec 2021"] = result)
        //     allMonthsTSR[monthYearOfData] = parseFloat(
        //       100 * monthlyTSRForGroup
        //     );
        //   } else {
        //     // Otherwise set to null
        //     allMonthsTSR[monthYearOfData] = null;
        //   }
        //   continue; // This method does not need an accumulation like the other options
        // }

        if (
          monthYearOfData !==
          `${monthNeeded} ${yearNeeded - TSRperiodNeeded.split("yr")[0]}`
        ) {
          monthlyReturnsForTSR.push(monthlyReturnForGroup);
        }

        // Reduce all values in array into one accumulated value (accumulator * (1+TSR), starting with $1 investment
        const calculateTSRproduct = monthlyReturnsForTSR.reduce(
          (accumulator, currentValue) => {
            return accumulator * (currentValue ? currentValue + 1 : null);
          },
          1
        );

        // If TSR sumproduct is a valid number:
        if (!isNaN(calculateTSRproduct)) {
          // Multiply it by 100 ($100 investment) and store it as the datapoint for that month (["Dec 2021"] = result)
          allMonthsTSR[monthYearOfData] = parseFloat(100 * calculateTSRproduct);
        } else {
          // Otherwise set to null
          allMonthsTSR[monthYearOfData] = null;
        }
      }
      return allMonthsTSR;
    },
    [
      monthlyReturns,
      monthSelected,
      yearSelected,
      companyInfo,
      monthlyMarketcaps,
      periodicitySelected,
      calculateTSR,
    ]
  );

  const peerGroupList = useMemo(() => {
    // Include company in its own peer group if it is not already there and "Include selected"
    if (includeCompanyInPeerGroup === "Include") {
      const peerGroupList = customCohortCompanies?.includes(companyInfo.Ticker)
        ? customCohortCompanies
        : [companyInfo.Ticker, ...customCohortCompanies];
      return peerGroupList;
    }
    if (includeCompanyInPeerGroup === "Exclude") {
      const peerGroupList = !customCohortCompanies?.includes(companyInfo.Ticker)
        ? customCohortCompanies
        : customCohortCompanies.filter((item) => item !== companyInfo.Ticker);
      return peerGroupList;
    }
    console.warn(
      "includeCompanyInPeerGroup must be set to 'Include' or 'Exclude'"
    );
    return [];
  }, [companyInfo, customCohortCompanies, includeCompanyInPeerGroup]);

  const TSRtableData = useMemo(() => {
    const monthsToDisplay = getMonthsNeeded();
    //.filter((ticker) => {
    //   return (
    //     monthlyReturns?.[ticker] &&
    //     monthsToDisplay.some((key) => monthlyReturns[ticker]?.[key])
    //   );
    // });

    // Use calculateTSR function (with default variables) get get all company TGRs for monthYears needed:
    const calculatedCompanyTSRs = calculateTSR(
      monthsToDisplay,
      companyInfo.Ticker
    );

    const filteredPeerGroupList = peerGroupList;

    // Calculate TSR for all needed companies in peer group list:
    const allPeerCompanyTSRs = {};
    for (const ticker of peerGroupList) {
      const peerCompanyTSR = calculateTSR(monthsToDisplay, ticker);
      allPeerCompanyTSRs[ticker] = peerCompanyTSR;
    }
    // (Same as value weighted now)
    const item201compliantTSRs = calculatePeerGroupTSR(
      monthsToDisplay,
      filteredPeerGroupList,
      "201 Compliant",
      TSRperiod
    );

    const equalWeightedTSRs = calculatePeerGroupTSR(
      monthsToDisplay,
      filteredPeerGroupList,
      "Equal",
      TSRperiod
    );

    const medianWeightedTSRs = calculatePeerGroupTSR(
      monthsToDisplay,
      filteredPeerGroupList,
      "Median",
      TSRperiod
    );

    // Calculate TSR for all indexes selected:
    const indexTSRs = {};
    const addedComparisonCompaniesTSRs = {};
    if (indexesSelected.length) {
      indexesSelected.forEach((index) => {
        indexTSRs[index] = calculating
          ? "Calculating..."
          : calculateTSR(
              monthsToDisplay,
              undefined,
              TSRperiod,
              yearSelected,
              monthSelected,
              index
            ) || emdash;
      });
    }

    if (addedComparisonCompanies.length) {
      addedComparisonCompanies.forEach((company) => {
        const comparisonCompanyTicker = company.split("[")[1].replace("]", "");
        addedComparisonCompaniesTSRs[company] = calculating
          ? "Calculating..."
          : calculateTSR(
              monthsToDisplay,
              comparisonCompanyTicker,
              TSRperiod,
              yearSelected,
              monthSelected
            ) || emdash;
      });
    }

    // For each month that is required for TSR on frontend:
    return monthsToDisplay
      .filter(
        // If not using "Monthly perodicity" only return annual data (i.e. month of data === monthSelected)
        (key, index) =>
          periodicitySelected === "Monthly" ||
          (periodicitySelected === "Quarterly" && index % 3 === 0) ||
          (periodicitySelected === "Annual" && index % 12 === 0)
        // key.split(" ")[0] === monthSelected
      )
      .map((TSRkey, index) => {
        const allPeerGroupTSRs = [];
        const allPeerGroupMarketcaps = {};

        // If annual datapoint, define which one (e.g. 1yr, 2yr, 3yr, etc.) as TSRyear
        const TSRyear =
          TSRkey.split(" ")[0] === monthSelected
            ? `${
                TSRperiod.split("yr")[0] - (yearSelected - TSRkey.split(" ")[1])
              }yr`
            : null;

        //  Get the calculated TSR value from calculateTSR function, Unless displaying initial investment ($100)
        const calculatedCompanyTSR =
          TSRyear === "0yr"
            ? parseFloat(100).toFixed(2)
            : calculatedCompanyTSRs?.[TSRkey];

        for (const ticker of peerGroupList) {
          // If this company has a marketCap value and at least one TSR value, add its marketcap to allPeerGroupMarketcaps
          if (monthlyMarketcaps?.[ticker]?.[TSRkey]) {
            allPeerGroupMarketcaps[ticker] =
              monthlyMarketcaps[ticker]?.[TSRkey];
          }
          if (allPeerCompanyTSRs?.[ticker]?.[TSRkey]) {
            allPeerGroupTSRs.push({
              ticker,
              peerCompanyTSR: allPeerCompanyTSRs?.[ticker]?.[TSRkey],
            });
          }
        }

        const peerGroupAverage = equalWeightedTSRs?.[TSRkey];
        const peerGroupMedianWeighted = medianWeightedTSRs?.[TSRkey];
        const peerGroup201Compliant = item201compliantTSRs?.[TSRkey];

        // Sets peer group TSRs and marketCaps to get latest (user selcted) datapoint for each company
        setPeerGroupTSRs(allPeerGroupTSRs);
        setPeerGroupMarketcaps(allPeerGroupMarketcaps);

        // Return all calculated values for each monthYear needed:
        const newTSRTableData = {
          key: index,
          TSRperiod:
            index === 0
              ? `${TSRkey} (Initial Investment)`
              : TSRkey.split(" ")[0] === monthSelected
              ? `${TSRkey} (${
                  TSRperiod.split("yr")[0] -
                  (yearSelected - TSRkey.split(" ")[1])
                }yr)`
              : TSRkey,
          calculatedTSR: calculatedCompanyTSR || emdash,
          peerGroupTSR: calculating
            ? "Calculating..."
            : peerGroupAverage || emdash,

          peerGroupMedianWeighted: calculating
            ? "Calculating..."
            : peerGroupMedianWeighted || emdash,
          peerGroup201Compliant: calculating
            ? "Calculating..."
            : peerGroup201Compliant || emdash,
        };
        if (addedComparisonCompanies.length) {
          addedComparisonCompanies.forEach((index) => {
            newTSRTableData[index] = calculating
              ? "Calculating..."
              : addedComparisonCompaniesTSRs[index]?.[TSRkey] || emdash;
          });
        }
        if (indexesSelected.length) {
          indexesSelected.forEach((index) => {
            newTSRTableData[index] = calculating
              ? "Calculating..."
              : indexTSRs[index]?.[TSRkey] || emdash;
          });
        }

        return newTSRTableData;
      });
  }, [
    calculating,
    TSRperiod,
    monthSelected,
    yearSelected,
    periodicitySelected,
    monthlyMarketcaps,
    companyInfo,
    indexesSelected,
    addedComparisonCompanies,
    peerGroupList,
    calculatePeerGroupTSR,
    getMonthsNeeded,
    calculateTSR,
  ]);

  const [PvPRangeSelected, setPvPRangeSelected] = useState("3yr");

  const PvPTableTSRdata = useMemo(() => {
    const PvPTSRPeriod = PvPRangeSelected;
    const monthsToDisplay = getMonthsNeeded(
      PvPTSRPeriod,
      PvPTableYearSelected,
      PvPTableMonthSelected
    );

    // Calculate TSR for all indexes selected:
    const PvPIndexTSRs = {};
    if (PvPIndexesSelected.length) {
      PvPIndexesSelected.forEach((index) => {
        PvPIndexTSRs[index] = calculating
          ? "Calculating..."
          : calculateTSR(
              monthsToDisplay,
              undefined,
              PvPTSRPeriod,
              PvPTableYearSelected,
              PvPTableMonthSelected,
              index
            ) || emdash;
      });
    }

    const filteredPeerGroupList = peerGroupList.filter((ticker) => {
      return (
        monthlyReturns?.[ticker] &&
        monthsToDisplay.some((key) => monthlyReturns[ticker]?.[key])
      );
    });

    const item201compliantTSRs = calculatePeerGroupTSR(
      monthsToDisplay,
      filteredPeerGroupList,
      "201 Compliant",
      PvPRangeSelected,
      PvPTableYearSelected,
      PvPTableMonthSelected,
      periodicitySelected
    );
    const equalWeightedTSRs = calculatePeerGroupTSR(
      monthsToDisplay,
      filteredPeerGroupList,
      "Equal",
      PvPRangeSelected,
      PvPTableYearSelected,
      PvPTableMonthSelected,
      periodicitySelected
    );
    const medianWeightedTSRs = calculatePeerGroupTSR(
      monthsToDisplay,
      filteredPeerGroupList,
      "Median",
      PvPRangeSelected,
      PvPTableYearSelected,
      PvPTableMonthSelected,
      periodicitySelected
    );
    // const valueWeightedTSRs = calculatePeerGroupTSR(
    //   monthsToDisplay,
    //   filteredPeerGroupList,
    //   "Value",
    //   PvPRangeSelected,
    //   PvPTableYearSelected,
    //   PvPTableMonthSelected,
    //   periodicitySelected
    // );

    return monthsToDisplay
      .filter((key) => key.split(" ")[0] === PvPTableMonthSelected)
      .map((TSRkey, index) => {
        const TSRyear =
          TSRkey.split(" ")[0] === PvPTableMonthSelected
            ? `${
                PvPTSRPeriod.split("yr")[0] -
                (PvPTableYearSelected - TSRkey.split(" ")[1])
              }yr`
            : null;
        const peerGroup201Compliant = item201compliantTSRs?.[TSRkey];
        const peerGroupAverage = equalWeightedTSRs?.[TSRkey];
        // const peerGroupValueWeighted = valueWeightedTSRs?.[TSRkey];
        const peerGroupMedianWeighted = medianWeightedTSRs?.[TSRkey];

        const PvPTableData = {
          key: index,
          TSRPeriod:
            index === 0
              ? `${TSRkey} (Initial Investment)`
              : TSRkey.split(" ")[0] === PvPTableMonthSelected
              ? `${TSRkey} (${
                  PvPTSRPeriod.split("yr")[0] -
                  (PvPTableYearSelected - TSRkey.split(" ")[1])
                }yr)`
              : TSRkey,
          calculatedTSR:
            TSRyear === "0yr"
              ? parseFloat(100).toFixed(2)
              : calculateTSR(
                  monthsToDisplay,
                  companyInfo.Ticker,
                  PvPTSRPeriod,
                  PvPTableYearSelected,
                  PvPTableMonthSelected
                )?.[TSRkey] || emdash,
          peerGroupEqualWeighted: calculating
            ? "Calculating..."
            : peerGroupAverage || emdash,
          // peerGroupValueWeighted: calculating
          //   ? "Calculating..."
          //   : peerGroupValueWeighted || emdash,
          peerGroupMedianWeighted: calculating
            ? "Calculating..."
            : peerGroupMedianWeighted || emdash,
          peerGroup201Compliant: calculating
            ? "Calculating..."
            : peerGroup201Compliant || emdash,
        };
        if (PvPIndexesSelected.length) {
          PvPIndexesSelected.forEach((index) => {
            PvPTableData[index] = calculating
              ? "Calculating..."
              : PvPIndexTSRs[index]?.[TSRkey] || emdash;
          });
        }
        return PvPTableData;
      });
  }, [
    companyInfo.Ticker,
    calculating,
    PvPTableMonthSelected,
    PvPTableYearSelected,
    monthlyReturns,
    peerGroupList,
    PvPIndexesSelected,
    PvPRangeSelected,
    periodicitySelected,
    getMonthsNeeded,
    calculateTSR,
    calculatePeerGroupTSR,
  ]);

  const indexSelectorOptions = useMemo(() => {
    if (!indexMonthlyReturns) return [];
    const newOptions = [];
    const indexMonthlyReturnsArray = Object.values(indexMonthlyReturns);
    for (const indexMonthlyReturnData of indexMonthlyReturnsArray) {
      for (const [key, val] of Object.entries(indexMonthlyReturnData)) {
        if (key === "index") {
          newOptions.push({ label: val, value: val });
        }
      }
    }
    return newOptions.sort((a, b) => a.label.localeCompare(b.label));
  }, [indexMonthlyReturns]);
  const handleIndexSelectorChange = (value) => {
    setIndexesSelected(value);
  };

  const [scrollToPeerGroup, setScrollToPeerGroup] = useState(false);
  const peerGroupTableRef = useRef(null);

  const CalculatorCohortSelectorComponent = useMemo(() => {
    if (!companyInfo) return <div></div>;
    return (
      <div
        style={{
          width: "100%",
          display: "flex",
          flexWrap: "wrap",
          marginBottom: 10,
        }}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            marginBottom: 10,
          }}
        >
          <CohortSelector
            companyInfo={companyInfo}
            setCohortTableTickers={setCohortTableTickers}
            cohortTableSelector={cohortTableSelector}
            setCohortTableSelector={setCohortTableSelector}
            removeCustomCohort={false}
            heading="Comparison Group:"
            key={cohortUpdateCount}
          />
        </div>
        <h3
          style={{
            fontSize: 20,
            marginRight: 10,
            marginTop: 13.5,
          }}
        >
          {"Edit Comparison Group:".split(" ").map((head, index) => (
            <span key={index}>{head}&nbsp;</span>
          ))}
        </h3>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Button
            onClick={() => {
              setScrollToPeerGroup(true);
            }}
            style={{ margin: 10, padding: "10px, 20px" }}
          >
            Edit Comparison Group
            {/* {cohortTableSelector.includes("Peer Group")
              ? "Peer Group"
              : "Cohort"} */}
          </Button>
        </div>
      </div>
    );
  }, [
    companyInfo,
    cohortTableSelector,
    setCohortTableTickers,
    setCohortTableSelector,
    cohortUpdateCount,
  ]);

  useMemo(() => {
    if (user?.customPeerGroups && Object.keys(user?.customPeerGroups).length) {
      setCustomUserPeerGroups(user?.customPeerGroups);
    }
  }, [user?.customPeerGroups]);

  const handleSaveCohortSubmit = useCallback(
    async (e, peerGroupUpdateKey = "") => {
      try {
        e.preventDefault();

        const newUserDetails = {
          ...user,
        };
        if (newUserDetails?.customPeerGroups) {
          const currentCustomPeerGroups = user.customPeerGroups;
          currentCustomPeerGroups[peerGroupUpdateKey || customPeerGroupName] =
            customCohortCompanies;
          newUserDetails.customPeerGroups = currentCustomPeerGroups;
        } else {
          const newPeerGroup = {};
          newPeerGroup[customPeerGroupName] = customCohortCompanies;
          newUserDetails.customPeerGroups = newPeerGroup;
        }

        setDisabled(true);
        setDisabledReason("Currently Updating Peer Group List...");
        const { status, data } = await UserService.updateUser(newUserDetails);
        if (status == 200) {
          await dispatch(updateUser(newUserDetails));
          toast.success(
            `Custom Peer Group ${peerGroupUpdateKey ? "Updated" : "Added"}!` +
              (peerGroupUpdateKey ? "" : " Scroll up to see new peer group."),
            { duration: 3000 }
          );
          await UserService.getUserInfo(localStorage.getItem("ACCESS_TOKEN"));
          setCohortUpdateCount((prev) => prev + 1);
          setCohortTableSelector(peerGroupUpdateKey || customPeerGroupName);
          // setDisabled(false);
          setDisabledReason("");
        } else {
          setDisabled(false);
          setDisabledReason("");
        }
      } catch (err) {
        //   console.log(err);
        console.log(err?.response);
        toast.error(
          err?.response?.data ||
            "Cohorts cannot be updated right now. Please try again later."
        );
        setDisabled(false);
        setDisabledReason("");
      }
    },
    [user, customPeerGroupName, customCohortCompanies, dispatch]
  );

  const currentCompanyPeerGroup = useMemo(() => {
    return companyInfo?.peerGroupKey || [];
  }, [companyInfo]);

  const isCustomPeerGroup = useCallback(
    (peerGroupKey) =>
      Object.keys(customUserPeerGroups).length &&
      Object.keys(customUserPeerGroups).some((key) => key === peerGroupKey),
    [customUserPeerGroups]
  );

  const handleCustomPeerGroupNameChange = (e) => {
    let value = e.target.value;
    value = value.replace(/[^a-z0-9 ]/gi, "").slice(0, 25);
    setCustomPeerGroupName(value);
  };

  const updatedTableTickers = useMemo(() => {
    let updatedTableTickers = [];

    if (customCohortTickers.length) {
      customCohortTickers.forEach((ticker) => {
        if (!updatedTableTickers.includes(ticker)) {
          updatedTableTickers.push(ticker);
        }
      });
    }

    return [...new Set(updatedTableTickers)];
  }, [customCohortTickers]);

  const currentCustomUserPeerGroups = useMemo(() => {
    if (cohortUpdateCount > 0) {
      return user?.customPeerGroups;
    }
  }, [user, cohortUpdateCount]);

  useEffect(() => {
    if (defaultPeerGroupOptions.includes(customPeerGroupName)) {
      setDisabled(true);
      setDisabledReason(
        "Peer group name is reserved for default options. Please use a different custom peer group name."
      );
    } else if (
      currentCustomUserPeerGroups &&
      Object.keys(currentCustomUserPeerGroups).length &&
      Object.keys(currentCustomUserPeerGroups).some(
        (name) => name === customPeerGroupName
      )
    ) {
      setDisabled(true);
      setDisabledReason(
        "Peer group name already exists. Please use a different custom peer group name."
      );
    } else if (customCohortCompanies.length > 50) {
      setDisabled(true);
      setDisabledReason(
        "Peer group is too long. Peer groups must be 50 companies or less."
      );
    } else {
      setDisabled(false);
      setDisabledReason("");
    }
  }, [
    currentCustomUserPeerGroups,
    customPeerGroupName,
    cohortUpdateCount,
    customCohortCompanies,
  ]);

  const handleDeleteCohort = useCallback(
    async (peerGroupKey) => {
      try {
        const newUserDetails = {
          ...user,
        };
        const currentCustomPeerGroups = user?.customPeerGroups;
        if (!currentCustomPeerGroups) {
          toast.error("No user cohorts to delete");
          return;
        } else {
          delete currentCustomPeerGroups[peerGroupKey];
          newUserDetails.customPeerGroups = currentCustomPeerGroups;
          const { status, data } = await UserService.updateUser(newUserDetails);
          if (status == 200) {
            await dispatch(updateUser(newUserDetails));
            toast.success(`${peerGroupKey} deleted`);
            await UserService.getUserInfo(localStorage.getItem("ACCESS_TOKEN"));
            setCohortUpdateCount((prev) => prev + 1);
            setCohortTableSelector("");
            setAboutToDelete(false);
          } else {
            toast.error(
              "Cohorts cannot be updated right now. Please try again later."
            );
            return;
          }
        }
      } catch (err) {
        // console.log(err);
        console.log(err?.response);
        toast.error(
          "Cohorts cannot be updated right now. Please try again later."
        );
        return;
      }
    },
    [user, dispatch]
  );

  const [showPeerGroup, setShowPeerGroup] = useState(false);

  // CSV export
  const formatDataForCSVexport = useCallback(() => {
    const allTSRoutputData = [];
    if (TSRtableData?.length) {
      allTSRoutputData.push(
        ...TSRtableData?.map((item) => {
          return { ...item };
        })
      );
    }
    const allTSRheaders = [];
    TSRtableColumns.forEach((item) => {
      if (!allTSRheaders.includes(item.dataIndex)) {
        allTSRheaders.push(item.dataIndex);
      }
    });
    const formattedBoardPayData = allTSRoutputData.map((item) => {
      const formattedItem = {};
      allTSRheaders.forEach((key, index) => {
        if (key) {
          formattedItem[
            index === 1 ? `"${companyInfo.Company} TSR"` : `"${key}"`
          ] =
            item?.[key] && (item?.[key].length > 3 || item?.[key] >= 0)
              ? item[key]
              : "";
        }
      });
      return formattedItem;
    });
    return formattedBoardPayData;
  }, [companyInfo, TSRtableData, TSRtableColumns]);

  return (
    <>
      {!isTier2 ? (
        <div
          className="comp-ai-section col-lg-9 executives "
          style={{ height: "100%", padding: 50 }}
        >
          <h3
            style={{
              textAlign: "center",
              fontWeight: "normal",
              fontSize: 22,
            }}
          >
            <p style={{ fontWeight: "bold" }}>
              This page is for C-Suite Comp&reg; Enterprise users only.
            </p>
            <br />
            <p>
              Contact your account manager to access Company Calculators (TSR,
              Pay versus Performance, Metric Prevalence Data, and more).
            </p>
          </h3>
        </div>
      ) : (
        <>
          <div className="row" id="main-component">
            {companyInfoComponent}
            <div className="company-calculator-section col-lg-9 executives p-0">
              {PDFprocessing && (
                <h3 style={{ width: "100%" }}>TSR Line Graph</h3>
              )}
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  alignItems: "center",
                  justifyContent: "center",
                  marginBottom: "20px",
                  marginLeft: "20px",
                  width: "100%",
                }}
              >
                <RadioSelector
                  options={["Annual", "Quarterly", "Monthly"]}
                  state={periodicitySelected}
                  setState={setPeriodicitySelected}
                  heading="Data Periodicity:"
                />
                <RadioSelector
                  options={TSRperiodsAvailable}
                  state={TSRperiod}
                  setState={setTSRperiod}
                  heading="TSR Period:"
                />
                <RadioSelector
                  options={MONTHS}
                  state={monthSelected}
                  setState={setMonthSelected}
                  heading="TSR Period End Month:"
                />
                <RadioSelector
                  options={yearsAvailable}
                  state={yearSelected}
                  setState={setYearSelected}
                  heading="TSR Period End Year:"
                />
                {CalculatorCohortSelectorComponent}
                <RadioSelector
                  options={["Include", "Exclude"]}
                  state={includeCompanyInPeerGroup}
                  setState={setIncludeCompanyInPeerGroup}
                  heading={`Include ${companyInfo.Ticker} in Comparison Group:`}
                  style={{ marginBottom: 15 }}
                />
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                  }}
                >
                  <div className="title">
                    <h3
                      style={{ fontSize: 20, marginRight: 10, marginBottom: 0 }}
                    >
                      {"Comparison Group Weighting Strategy:"
                        .split(" ")
                        .map((head, index) => (
                          <span key={index}>{head}&nbsp;</span>
                        ))}
                    </h3>
                  </div>

                  <CheckboxGroup
                    value={weightingsCheckList}
                    onChange={onWeightingsCheckListChange}
                  >
                    {plainOptions.map((item, index) => (
                      <span key={index}>
                        {index === 0 ? (
                          <Row key={index}>
                            <Checkbox value={item} key={index}>
                              {item}
                            </Checkbox>
                          </Row>
                        ) : (
                          <Checkbox value={item} key={index}>
                            {item}
                          </Checkbox>
                        )}
                      </span>
                    ))}
                  </CheckboxGroup>
                </div>
                <div
                  style={{ width: "100%", display: "flex", marginTop: 20 }}
                  className="hasHoverText"
                >
                  <Tooltip title={indexComparisonMessage}>
                    {hasHoverText(
                      <h3
                        style={{
                          fontSize: 20,
                          marginRight: -5,
                          marginBottom: 5,
                          marginTop: 5,
                        }}
                      >
                        {"Compare to Index:".split(" ").map((head, index) => (
                          <span key={index}>{head}&nbsp;</span>
                        ))}
                      </h3>
                    )}
                  </Tooltip>
                  <Select
                    mode="multiple"
                    allowClear
                    style={{ width: "100%" }}
                    placeholder="Select Indexes to Compare"
                    onChange={handleIndexSelectorChange}
                    options={indexSelectorOptions}
                  />
                </div>
                <div
                  style={{ width: "100%", display: "flex", marginTop: 20 }}
                  className="hasHoverText"
                >
                  <h3
                    style={{
                      fontSize: 20,
                      marginRight: 5,
                      marginBottom: 5,
                      marginTop: 5,
                    }}
                  >
                    Add&nbsp;Comparison&nbsp;Companies:
                  </h3>
                  <Select
                    mode="multiple"
                    style={{ width: "100%", marginBottom: 10 }}
                    allowClear
                    value={addedComparisonCompanies}
                    filterOption={false}
                    onSearch={(val) => {
                      setSearchVal(val);
                    }}
                    onBlur={() => {
                      setSearchVal("");
                    }}
                    loading={companies?.length < 8}
                    onChange={(value) => {
                      setSearchVal("");
                      setAddedComparisonCompanies(value);
                    }}
                  >
                    {companiesChildren}
                  </Select>
                </div>

                <TSRLineGraph
                  companyInfo={companyInfo}
                  weightingsCheckList={weightingsCheckList}
                  cohortTableSelector={cohortTableSelector}
                  TSRtableData={TSRtableData}
                  indexesSelected={indexesSelected}
                  addedComparisonCompanies={addedComparisonCompanies}
                  currentOptions={{ TSRperiod, periodicitySelected }}
                />
              </div>
            </div>
          </div>
          <PeerGroupTable
            companyInfo={companyInfo}
            cohortTableTickers={cohortTableTickers}
            peerGroupTSRs={peerGroupTSRs}
            peerGroupMarketcaps={peerGroupMarketcaps}
            customCohortCompanies={customCohortCompanies}
            setCustomCohortCompanies={setCustomCohortCompanies}
            monthSelected={monthSelected}
            yearSelected={yearSelected}
            TSRperiod={TSRperiod}
            cohortTableSelector={cohortTableSelector}
            peerGroupTableRef={peerGroupTableRef}
            scrollToPeerGroup={scrollToPeerGroup}
            setScrollToPeerGroup={setScrollToPeerGroup}
            includeCompanyInPeerGroup={includeCompanyInPeerGroup}
            allowRemoveCompany={true}
            setIncludeCompanyInPeerGroup={setIncludeCompanyInPeerGroup}
            showPeerGroup={showPeerGroup}
            setShowPeerGroup={setShowPeerGroup}
          />
          {showPeerGroup && (
            <div style={{ paddingLeft: 40, paddingRight: 10 }}>
              {isCustomPeerGroup(peerGroupKey) &&
              (updatedTableTickers.some(
                (item) => !customCohortCompanies.includes(item)
              ) ||
                customCohortCompanies?.some(
                  (item) => !updatedTableTickers?.includes(item)
                )) ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    marginBottom: 10,
                  }}
                >
                  <span style={{ paddingRight: 10 }}>
                    Update {peerGroupKey}:
                  </span>
                  <Button
                    onClick={(e) => handleSaveCohortSubmit(e, peerGroupKey)}
                  >
                    Update Peer Group
                  </Button>
                </div>
              ) : (
                <span></span>
              )}
              {isCustomPeerGroup(peerGroupKey) ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    marginBottom: 10,
                  }}
                >
                  <span style={{ marginRight: 10 }}>
                    Delete {peerGroupKey}:
                  </span>
                  <Button
                    onClick={() =>
                      isAboutToDelete
                        ? handleDeleteCohort(peerGroupKey)
                        : setAboutToDelete(true)
                    }
                    style={{
                      backgroundColor: isAboutToDelete ? "var(--red)" : "",
                    }}
                  >
                    {isAboutToDelete ? (
                      <span
                        style={{
                          color: "var(--secondary)",
                          fontWeight: "bold",
                        }}
                      >
                        Confirm Delete Peer Group?
                      </span>
                    ) : (
                      "Delete Peer Group"
                    )}
                  </Button>
                  {isAboutToDelete && (
                    <div
                      style={{ color: "var(--link-blue)", cursor: "pointer" }}
                      onClick={() => setAboutToDelete(false)}
                    >
                      &nbsp;Don't delete peer group
                    </div>
                  )}
                </div>
              ) : (
                <span></span>
              )}
              {(currentCompanyPeerGroup.some(
                (item) => !customCohortCompanies.includes(item)
              ) ||
                customCohortCompanies?.some(
                  (item) => !currentCompanyPeerGroup?.includes(item)
                )) && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    marginBottom: 10,
                  }}
                >
                  <span style={{ paddingRight: 10 }}>
                    Add as New Custom Peer Group:
                  </span>
                  <Input
                    style={{ width: 200, marginRight: 10 }}
                    onChange={handleCustomPeerGroupNameChange}
                    placeholder="Custom Peer Group 1"
                    value={customPeerGroupName}
                  />
                  {disabled ? (
                    <Tooltip title={disabledReason}>
                      <div>
                        <Button
                          disabled={disabled}
                          onClick={(e) => handleSaveCohortSubmit(e)}
                        >
                          Save as New Peer Group
                        </Button>
                      </div>
                    </Tooltip>
                  ) : (
                    <Button
                      disabled={disabled}
                      onClick={(e) => handleSaveCohortSubmit(e)}
                    >
                      Save as New Peer Group
                    </Button>
                  )}
                </div>
              )}
            </div>
          )}
          <div id="peer-group-comparison">
            {PDFprocessing && (
              <h3 style={{ width: "100%" }}>TSR Peer Group Comparison Table</h3>
            )}
            <Table
              dataSource={TSRtableData}
              columns={TSRtableColumns}
              // pagination={{ hideOnSinglePage: true }}
              pagination={false}
              bordered={true}
            />
            <TSRperiodMessage
              TSRperiod={TSRperiod}
              monthSelected={monthSelected}
              yearSelected={yearSelected}
              includeHoverText={true}
            />
            <div className="col-md d-flex justify-content-start align-items-end mt-3">
              <h3 style={{ fontSize: 20, marginRight: 10, marginBottom: 0 }}>
                CSV&nbsp;Export:&nbsp;
              </h3>

              <Button
                onClick={() =>
                  exportToCSV(
                    formatDataForCSVexport(),
                    `${companyInfo?.Ticker} TSR Comparison Table ${
                      new Date().toString().split("GMT-")[0]
                    }.csv`
                  )
                }
              >
                Export Table to CSV
              </Button>
            </div>
          </div>
          <div style={{ marginTop: 50 }} id="pvp-section">
            <PvPTable
              companyInfo={companyInfo}
              PvPTableYearSelected={PvPTableYearSelected}
              setPvPTableYearSelected={setPvPTableYearSelected}
              companyExecutives={companyExecutives}
              PvPTableTSRdata={PvPTableTSRdata}
              calculating={calculating}
              weightingsAvalable={defaultWeightingsCheckList}
              PvPTableMonthSelected={PvPTableMonthSelected}
              setPvPTableMonthSelected={setPvPTableMonthSelected}
              PvPPeriodicitySelected={periodicitySelected}
              setPvPPeriodicitySelected={setPeriodicitySelected}
              indexSelectorOptions={indexSelectorOptions}
              PvPIndexesSelected={PvPIndexesSelected}
              setPvPIndexesSelected={setPvPIndexesSelected}
              CalculatorCohortSelectorComponent={
                CalculatorCohortSelectorComponent
              }
              customCohortCompanies={customCohortCompanies}
              includeCompanyInPeerGroup={includeCompanyInPeerGroup}
              setIncludeCompanyInPeerGroup={setIncludeCompanyInPeerGroup}
              PvPRangeSelected={PvPRangeSelected}
              setPvPRangeSelected={setPvPRangeSelected}
              PDFprocessing={PDFprocessing}
            />
          </div>
        </>
      )}
    </>
  );
}
